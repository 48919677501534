import { eRateAPI } from '../../webapi/eRate';
import { subscriptionsAPI } from '../../webapi/subscriptions';

const initialState = {
  lastUpdated: null,
  mobile: [],
  subscriptions: [],
  subscription: {},
  invoices: [],
  sync: false,
  error: false,
  isOwner: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case eRateAPI.events.eRateSubscriptions.actionFail:
      console.log('eRate: ', action.error);
      return Object.assign({}, state, {
        subscriptions: Object.assign([], state.subscriptions, []),
        sync: true,
        error: true,
      });
    case subscriptionsAPI.events.subscriptionsList.actionSuccess:
      const mobile = action.data
        .filter((e) => e.product.kind === 'MOBILE')
        .map((p) => ({
          id: p.id,
          subscriptionId: null,
          phoneNumber: p.asset.foreignId,
          description: p.description,
          subscriptionName: p.product.name,
          created: p.created,
          contract: p.contract.id,
          customer: {
            id: p.contract.entity.id,
            name: p.contract.entity.name,
          },
          address: {
            country: p.country,
          },
          provider: 'eRate',
          usage: [],
          currency: '-',
          balance: '-',
          transferUsed: 0,
          transferTotal: 0,
          costsForCurrentMonth: 0,
        }));

      // let contracts = action.data.filter(e => e.phoneNumber !== null)
      return Object.assign({}, state, {
        mobile: Object.assign([], state.mobile, [...mobile]),
      });

    case eRateAPI.events.eRateSubscriptions.actionSuccess:
      const subscriptions = [];
      let lastUpdated = null;
      let isOwner = false;
      if (action.data && action.data.result && action.data.result.length) {
        lastUpdated = action.data.lastUpdated;
        isOwner = action.data.isOwner;
        action.data.result.forEach((p) => {
          subscriptions.push({
            ...p,
            currency: p.costsCurrency,
            transferUsed: p.transferUsed,
            transferTotal: p.transferTotal,
            balance: p.costsForCurrentMonth,
            subscriptionId: p.subscriptionId,
            subscriptionName: p.ratePlanName,
            id: p.subscriptionId,
            status: p.serviceStatus,
          });
        });
      }

      return Object.assign({}, state, {
        subscriptions: Object.assign([], state.subscriptions, [
          ...subscriptions,
        ]),
        sync: true,
        lastUpdated,
        isOwner,
      });
    case eRateAPI.events.eRateSubscription.actionSuccess:
      let subscription = {};
      if (action.data) {
        subscription = state.subscriptions.find(
          (e) => e.subscriptionId === action.data.subscriptionId,
        );
        const pn = action.data;
        subscription = {
          ...subscription,
          currency: pn.costsCurrency,
          transferUsed: pn.transferUsed,
          transferTotal: pn.transferTotal,
          balance: pn.costsForCurrentMonth,
          status: pn.serviceStatus,
        };
      }
      return Object.assign({}, state, {
        subscription: Object.assign({}, state.subscription, {
          ...subscription,
        }),
        sync: true,
      });

    case eRateAPI.events.eRateInvoices.actionSuccess:
      let invoices = [];
      if (action.data) {
        invoices = action.data.result.map((i) => ({
          ...i,
          totalAmount: i.totalExclVat + i.totalVat,
          createdAt: i.dueDate,
        }));
      }
      return Object.assign({}, state, {
        invoices: Object.assign([], state.invoices, [...invoices]),
      });

    default:
      return state;
  }
}
