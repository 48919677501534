import {createClient} from 'contentful';

export const CONFIGURE_CONTENTFUL_CLIENT = 'stuart:global:contentful:client/INIT_CONTENTFUL_CLIENT';
const initialState = {
  client: undefined
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIGURE_CONTENTFUL_CLIENT:
      const client = createClient({
        accessToken: action.payload.accessToken,
        space: action.payload.spaceId
      });

      return {...client};
    default:
      return state;
  }
}

export const configureClient = () => {

  const configureClientAction = (payload) => ({type: CONFIGURE_CONTENTFUL_CLIENT, payload});

  return (dispatch, getState) => {
    const {config} = getState().global.contentful;
    return Promise.resolve(
      dispatch(configureClientAction({accessToken: config.accessToken, spaceId: config.spaceId}))
    );
  };
};
