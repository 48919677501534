import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import styles from './styles.module.scss';

const RatingStars = ({ rating, count }) => {
  const [stars] = useState(() => {
    let filled = Math.ceil(rating);
    const s = [];
    for (let i = 0; i < count; i++) {
      if (filled > 0) {
        s.push({ filled: true, index: i });
        filled--;
      } else {
        s.push({ filled: false, index: i });
      }
    }
    return s;
  });

  return (
    <div className={styles.ratingContainer}>
      {stars.map((s) => (
        <div
          className={`${styles.rating} ${s && s.filled ? styles.filled : ''}`}
          key={s.index}
        >
          <Icon bordered name="star" />
        </div>
      ))}
    </div>
  );
};

RatingStars.propTypes = {
  rating: PropTypes.number,
  count: PropTypes.number,
};

RatingStars.defaultProps = {
  rating: 0,
  count: 5,
};

export default RatingStars;
