import React from 'react';
import { Message, Tab, Table } from 'semantic-ui-react';

import contentStyles from '../../../../../../Content/content.module.scss';

import data from './data';

const panesNoFlexBrand = [
  {
    menuItem: 'Tilleggstjenester',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tjeneste</Table.HeaderCell>
              <Table.HeaderCell>Pris pr mnd</Table.HeaderCell>
              <Table.HeaderCell>Etableringspris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.tilleggstjenester.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.prisPrMnd}</Table.Cell>
                <Table.Cell> {item.engangspris}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Mobilt Bredbånd',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pris</Table.HeaderCell>
              <Table.HeaderCell>Opp til MB</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.brebrand.map((item) => (
              <Table.Row>
                <Table.Cell> {item.price}</Table.Cell>
                <Table.Cell> {item.mb}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Message success>
          <p>
            Alle nye abonnement med over 500MB data vil fra 01.10.2019 aktiveres
            automatisk med Datakontroll som forhindrer overforbruk av data. Hvis
            du ikke har datakontroll koster det 0,99 pr MB, Makspris data 399,-
            pr måned og datahastighet reduseres til 40kbps (strupes) etter 1GB.
            Abonnementene Basis og Medium har ikke mulighet for Datakontroll. Vi
            sender SMS varsel når pakken nesten er brukt opp + et varsel når
            inkludert data for måneden er oppbrukt. Merk: denne SMS’en er ment
            som en kostnadfri tjeneste og er ingen garanti, da forsinkelser kan
            oppstå i nettverket til Telenor.
          </p>
        </Message>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Fra Norge til utland',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Message success>
          <p>
            Ring Utland pakken Hvis du ringer mye til utlandet anbefaler vi
            "Ring utland" pakken. Pakken gir deg 500 min ringetid hver måned til
            totalt 83 land. For å se hvilke land du kan ringe til se soner 1, 2
            og 3 nederst på denne siden. Pakken koster 249,- pr måned og
            bestilles på MinSide.
          </p>
        </Message>
        <Message success>
          <p>
            Soner: Vi har ellers delt opp utlandsprisene våre i soner. Er du
            usikker på hvilken sone landet du skal ringe til tilhører, er det en
            oversikt under pristabellen.
          </p>
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sone</Table.HeaderCell>
              <Table.HeaderCell>Pris pr min</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.sone.map((item) => (
              <Table.Row>
                <Table.Cell> {item.sone}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Message success>
          <p>
            Alle priser gjelder til fastnett og mobil. Taksering skjer pr.
            sekund. Startpris pr. samtale er 2,99. SMS og MMS til utlandet går
            ikke under det inkluderte i ditt abonnement.
          </p>
        </Message>
        <div
          className={`${contentStyles.contentContainer} ${contentStyles.darkContainer} ${contentStyles.liContainer}`}
          style={{ marginTop: 0, paddingTop: 20 }}
        >
          <ul>
            <h4>Sone 1</h4>
            <li>Danmark, Finland, Færøyene, Island, Sverige</li>
            <h4>Sone 2</h4>
            <li>
              Albania, Andorra, Belgia, Bosnia-Hercegovina, Bulgaria, Estland,
              Frankrike, Gibraltar, Hellas, Hviterussland, Irland, Italia,
              Kroatia, Kypros (Nord og Syd) Latvia, Liechtenstein, Litauen,
              Luxembourg, Makedonia, Malta, Monaco, Montenegro, Nederland,
              Polen, Portugal, Romania, San Marino, Slovakia Slovenia, Spania,
              Storbritannia, Sveits, Tsjekkia, Tyskland, Ukraina, Ungarn,
              Østerrike
            </li>
            <h4>Sone 3</h4>
            <li>
              Argentina, Australia, Bahamas, Bangladesh, Brasil, Canada,
              Colombia, Costa Rica, Filippinene, Guam, Hong Kong, India,
              Indonesia, Irak, Iran, Israel, Japan, Jordan, Kambodia, Kina,
              Kosovo, Martinique, Mexico, Moldova, New Zealand, Pakistan, Puerto
              Rico, Russland, Serbia, Singapore, Sri Lanka, Syria, Sør-Afrika,
              Sør-Korea, Taiwan, Thailand, Trinidad & Tobago, Tyrkia, USA,
              Venezuela, Vietnam
            </li>
            <h4>Sone 4</h4>
            <li>
              Afghanistan, Anguilla, Antigua & Barbuda (Leeward-øyene), Armenia,
              Bermuda, Bhutan, Bolivia, Brunei, Burkina Faso, De Forente
              Arabiske Emirater, Ecuador, Egypt, Ekvatorial-Guinea, El Salvador,
              Eritrea, Etiopia, Fiji, Fransk Guyana, Fransk Polynesia, Georgia,
              Grenada, Grønland, Guadeloupe, Guatemala, Guyana, Haiti, Honduras,
              Jamaica, Jemen, Jomfruøyene (am), Jomfruøyene (brit), Kamerun,
              Kasakhstan, Kenya, Kina, Kirgisistan, Kuwait, Laos, Lesotho,
              Libanon, Libya, Macau, Malawi, Malaysia, Marokko, Mongolia,
              Mosambik, Myanmar, Namibia, Nepal, Nigeria, Oman, Palestina,
              Panama, Paraguay, Peru, Saudi-Arabia, Swaziland, Tadsjikistan,
              Turkmenistan, Uruguay, Usbekistan, Zambia
            </li>
            <h4>Sone 5</h4>
            <li>
              Algerie, Angola, Aruba, Aserbajdsjan, Bahrain, Barbados, Belize,
              Benin, Botswana, Burundi, Cayman-øyene, Chile, Cuba, De
              Nederlandske Antiller, Den Dominikanske Republikk, Den
              Sentralafrikanske Republikk, Djibouti, Dominica, Elfenbenskysten,
              Gabon, Gambia, Ghana, Guinea, Guinea Bissau, Mali, Marshall-øyene,
              Mauritius, Mikronesia, Montserrat, Nicaragua, Niger, Ny Caledonia,
              Palau, Qatar, Reunion/Mayotte, Rwanda, Senegal, Sierra Leone,
              Somalia, Sudan, Surinam, Tanzania, Togo, Tonga, Tsjad, Tunisia,
              Uganda, Zimbabwe
            </li>
            <h4>Sone 6</h4>
            <li>
              Ascension, Cookøyene, Diego Garcia, Falklandsøyene, Kapp Verde,
              Kiribati, Komorene, Kongo, Liberia, Madagaskar, Maldivene,
              Mauritania, Nauru, Niue, Nord-Korea, Norfolk-øyene, Papua
              Ny-Guinea, Saipan Øst Timor, Salomonøyene, Samoa, Sao Tome &
              Principe, Seychellene, Sint Maarten, St. Helena, St. Kitts &
              Nevis, St. Lucia, St. Pierre & Miquelon, St. Vincent og
              Grenadinene, Tokelaue, Turcs- & Caicos-øyene, Tuvalu, Vanuatu,
              Wallis & Futuna
            </li>
          </ul>
        </div>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Bruk i utlandet',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sone</Table.HeaderCell>
              <Table.HeaderCell>Pr minutt</Table.HeaderCell>
              <Table.HeaderCell>Pr minutt (mottak)</Table.HeaderCell>
              <Table.HeaderCell>Data pr MB)</Table.HeaderCell>
              <Table.HeaderCell>Pr SMS</Table.HeaderCell>
              <Table.HeaderCell>Pr SMS (mottak)</Table.HeaderCell>
              <Table.HeaderCell>Pr MMS (mottak)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.utland.map((item) => (
              <Table.Row>
                <Table.Cell> {item.sone}</Table.Cell>
                <Table.Cell> {item.prMinutt}</Table.Cell>
                <Table.Cell> {item.prMinuttMottak}</Table.Cell>
                <Table.Cell> {item.dataPrMb}</Table.Cell>
                <Table.Cell> {item.prSms}</Table.Cell>
                <Table.Cell> {item.prSmsMottak}</Table.Cell>
                <Table.Cell> {item.prMms}</Table.Cell>
                <Table.Cell> {item.prMmsmottak}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Message success>
          <p>
            Land i Sone 1 (inkl. EU/EØS): Prisene er for inkludert bruk på ditt
            abonnement. Bruk utenfor pakken kommer i tillegg, og er samme pris
            som i Norge. Belgia Bulgaria Danmark Estland Finland Frankrike
            Gibraltar Hellas Irland Island Italia Kroatia Kypros Latvia
            Liechtenstein Litauen Luxembourg Malta Nederland Polen Portugal
            Romania San Marino Slovakia Slovenia Spania Sverige Tsjekkia
            Tyskland Ungarn Østerrike Andre destinasjoner som også har EU-priser
            (EU outermost regions): Antilles Martinique Guadeloupe French Guiana
            Desirade Les Saintes Marie-Galante Saint Barthelemy St.Martin (FR)
            Storbritannia La Reunion og Mayotte.
          </p>
        </Message>
        <Message success>
          <p>
            Land i Sone 2: Argentina Australia Baharin Bangladesh Bosnia Brunei
            Canada Dominikanske republikk Ecuador Filippinene Færøyene Grønland
            Guatemala Honduras Hong Kong Hviterussland Israel Jersey Jomfruøyene
            (US) Kambodsja Kina Kuwait Laos Macao Montenegro Myanmar New Zealand
            Nicaragua Oman Pakistan Paraguay Puerto Rico Russland Serbia
            Singapore Sveits Taiwan Thailand Tonga Ukraina Uruguay USA Vietnam
          </p>
        </Message>{' '}
        <Message success>
          <p>
            Land i Sone 3: Albania Andorra Armenia Aserbajdsjan Brasil Chile
            Colombia Costa Rica Egypt El Salvador Fiji Forente Arabiske Emirater
            Gabon Georgia Indonesia Japan Kapp Verde Kenya Republikken Kongo
            Lesotho Malawi Marokko Mexico Monaco Niger Nigeria Panama Papua New
            Guynea Peru Quatar Rwanda Saudi Arabia Seychellene Sri Lanka Sudan
            Sør Korea Sør-Afrika Tanzania Tsjad Tyrkia Uganda Zambia
          </p>
        </Message>{' '}
        <Message success>
          <p>Land i Sone 4: Alle andre land</p>
        </Message>
        <Message success>
          <p>
            Samtaler utenfor Sone 1 takseres pr. påbegynte minutt.
            Oppstartsavgift på alle samtaler i sone 2-4: 0,99. Samtaler, SMS og
            MMS fra et land i en sone til et land i en annen sone vil alltid bli
            taksert etter den dyreste sonen. Samtaler til spesialnummer og
            overtakserte nummer blir prissatt som sone 4 uansett hvilken sone du
            er i.
          </p>
        </Message>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Spesialnummer og satellitt',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>SMS til spesialnummer</Table.HeaderCell>
              <Table.HeaderCell>pris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.specialNumbers.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>3-sifrede numre</Table.HeaderCell>
              <Table.HeaderCell>Oppkobling</Table.HeaderCell>
              <Table.HeaderCell>Per minutt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.threeSiffer.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.oppkobling}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>4-sifrede numre</Table.HeaderCell>
              <Table.HeaderCell>Oppkobling</Table.HeaderCell>
              <Table.HeaderCell>Per minutt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.fourSiffer.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.oppkobling}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>5-sifrede numre</Table.HeaderCell>
              <Table.HeaderCell>Oppkobling</Table.HeaderCell>
              <Table.HeaderCell>Per minutt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Alle 5-sifrede nummer</Table.Cell>
              <Table.Cell> 0,00 - Prises som normale norske nummer</Table.Cell>
              <Table.Cell> 0,00 - Prises som normale norske nummer</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>8xx/999 nummer</Table.HeaderCell>
              <Table.HeaderCell>Oppkobling</Table.HeaderCell>
              <Table.HeaderCell>Per minutt</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.eightHundred.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.oppkobling}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Priser til satellittelefon</Table.HeaderCell>
              <Table.HeaderCell>Oppkobling</Table.HeaderCell>
              <Table.HeaderCell>Per minutt</Table.HeaderCell>
              <Table.HeaderCell>Per minutt mottak</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.satelite.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>
                <Table.Cell> {item.oppkobling}</Table.Cell>
                <Table.Cell> {item.price}</Table.Cell>
                <Table.Cell> {item.priceIn}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Priser på Skip og Fly</Table.HeaderCell>

              <Table.HeaderCell>Pris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.medium.mcp.map((item) => (
              <Table.Row>
                <Table.Cell> {item.name}</Table.Cell>

                <Table.Cell> {item.price}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Ekstra data og ringepakker',
    render: () => (
      <Tab.Pane style={{ padding: 0, border: 0 }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Datapakker i Norge og EU/EØS</Table.HeaderCell>
              <Table.HeaderCell>Pris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {' '}
                1GB Norge og EU – Bestill ved å sende 1GB til nummer 41716202
              </Table.Cell>
              <Table.Cell> 149,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                5GB Norge og EU – Bestill ved å sende 5GB til nummer 41716202
              </Table.Cell>
              <Table.Cell> 299,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                15GB Norge og EU – Bestill ved å sende 15GB til nummer 41716202
              </Table.Cell>
              <Table.Cell> 399,-</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Message success>
          <p>
            Land i Sone 1 (inkl. EU/EØS): Prisene er for inkludert bruk på ditt
            abonnement. Bruk utenfor pakken kommer i tillegg, og er samme pris
            som i Norge. Belgia Bulgaria Danmark Estland Finland Frankrike
            Gibraltar Hellas Irland Island Italia Kroatia Kypros Latvia
            Liechtenstein Litauen Luxembourg Malta Nederland Polen Portugal
            Romania San Marino Slovakia Slovenia Spania Sverige Tsjekkia
            Tyskland Ungarn Østerrike Andre destinasjoner som også har EU-priser
            (EU outermost regions): Antilles Martinique Guadeloupe French Guiana
            Desirade Les Saintes Marie-Galante Saint Barthelemy St.Martin (FR)
            Storbritannia La Reunion og Mayotte.
          </p>
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Ekstrapakker Sone 2 (USA, Thailand m.m)
              </Table.HeaderCell>
              <Table.HeaderCell>Pris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {' '}
                Sone 2 Datapakke 400MB - bestilles på MinSide eller ring 418 00
                000
              </Table.Cell>
              <Table.Cell> 49,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                Sone 2 Datapakke 1GB - bestilles på MinSide eller ring 418 00
                000
              </Table.Cell>
              <Table.Cell> 99,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                Sone 2 Datapakke 6GB - bestilles på MinSide eller ring 418 00
                000
              </Table.Cell>
              <Table.Cell> 399,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {' '}
                Sone 2 Ringepakke 200 minutter/SMS/MMS - bestilles på MinSide
                eller ring 418 00 000
              </Table.Cell>
              <Table.Cell> 299,-</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Message success>
          <p>
            Land i Sone 2: Argentina Australia Baharin Bangladesh Bosnia Brunei
            Canada Dominikanske republikk Ecuador Filippinene Færøyene Grønland
            Guatemala Honduras Hong Kong Hviterussland Israel Jersey Jomfruøyene
            (US) Kambodsja Kina Kuwait Laos Macao Montenegro Myanmar New Zealand
            Nicaragua Oman Pakistan Paraguay Puerto Rico Russland Serbia
            Singapore Sveits Taiwan Thailand Tonga Ukraina Uruguay USA Vietnam
          </p>
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Ekstrapakker – Ring fra Norge til utlandet
              </Table.HeaderCell>
              <Table.HeaderCell>Pris</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Ring Utland (Norden, Europa, Verden) 500 ringeminutter -
                bestilles på MinSide eller ring 418 00 000
              </Table.Cell>
              <Table.Cell> 249,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Message success>
                <p>
                  Danmark, Finland, Færøyene, Island, Sverige, Albania, Andorra,
                  Belgia, Bosnia-Hercegovina, Bulgaria, Estland, Frankrike,
                  Gibraltar, Hellas, Hviterussland, Irland, Italia, Kroatia,
                  Kypros (Nord og Syd) Latvia, Liechtenstein, Litauen,
                  Luxembourg, Makedonia, Malta, Monaco, Montenegro, Nederland,
                  Polen, Portugal, Romania, San Marino, Slovakia Slovenia,
                  Spania, Storbritannia, Sveits, Tsjekkia, Tyskland, Ukraina,
                  Ungarn, Østerrike Argentina, Australia, Bahamas, Bangladesh,
                  Brasil, Canada, Colombia, Costa Rica, Filippinene, Guam, Hong
                  Kong, India, Indonesia, Irak, Iran, Israel, Japan, Jordan,
                  Kambodia, Kina, Kosovo, Martinique, Mexico, Moldova, New
                  Zealand, Pakistan, Puerto Rico, Russland, Serbia, Singapore,
                  Sri Lanka, Syria, Sør-Afrika, Sør-Korea, Taiwan, Thailand,
                  Trinidad & Tobago, Tyrkia, USA, Venezuela, Vietnam
                </p>
              </Message>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Ring EU (Norden, EU/EØS land) 600 ringeminutter - bestilles på
                MinSide eller ring 418 00 000
              </Table.Cell>
              <Table.Cell> 99,-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Message success>
                <p>Danmark, Finland, Færøyene, Island, Sverige</p>
              </Message>
            </Table.Row>
          </Table.Body>
        </Table>
      </Tab.Pane>
    ),
  },
];
export default panesNoFlexBrand;
