import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';

import { fm as _ } from '../../utils/string';
import config from '../../config';

import styles from './footer.module.scss';

export default class Footer extends React.Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    actions: PropTypes.shape({ setDefaultLocale: PropTypes.func.isRequired })
      .isRequired,
    config: PropTypes.shape({
      defaultLocale: PropTypes.string.isRequired,
      activeProducts: PropTypes.object.isRequired,
    }).isRequired,
    credentials: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.handleSelectLanguage = this.handleSelectLanguage.bind(this);
  }

  handleSelectLanguage(event, data) {
    const { setDefaultLocale } = this.props.actions;

    setDefaultLocale(data.value);
  }

  render() {
    const { gettext, xgettext } = this.context;
    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config;
    const wpSiteUrl = config.WP_SITE_URL[defaultLocale];

    return (
      <div className={styles.bg}>
        {/* <div className="ui vertical segment inverted center aligned" /> */}
        <div className="ui vertical footer segment">
          <div className={styles.bg}>
            <div className={styles.shadow} />
          </div>
          <div className={`color-bg ${styles.container}`}>
            <div className="ui container">
              <div className="ui doubling stackable grid">
                <div className="row">
                  <div className={`three wide column ${styles.socialFooter}`}>
                    <h3 className={`ui header ${styles.title}`}>
                      {gettext('Pages')}
                    </h3>
                    <div
                      className={`ui link list ${styles.linkList}`}
                      role="list"
                    >
                      <a className="item" href="/" role="listitem">
                        {xgettext(
                          'Home',
                          'Text for the link to the main Hudya page.',
                        )}
                      </a>
                      {/* {activeProducts.corporate[defaultLocale] && ( */}
                      {/*  <a */}
                      {/*    className="item" */}
                      {/*    href={`${wpSiteUrl}/bedrift`} */}
                      {/*    role="listitem" */}
                      {/*  > */}
                      {/*    {xgettext( */}
                      {/*      'Corporate', */}
                      {/*      'Text for the link to Hudya corporate page.', */}
                      {/*    )} */}
                      {/*  </a> */}
                      {/* )} */}
                      {activeProducts.customerService[defaultLocale] && (
                        <a
                          className="item"
                          // href={`${wpSiteUrl}${urlLocaleMap.customerService[defaultLocale]}`}
                          href="/kundeservice"
                          role="listitem"
                        >
                          {xgettext(
                            'Customer service',
                            'Text for the link to Hudya customer service.',
                          )}
                        </a>
                      )}
                      {activeProducts.blog[defaultLocale] && (
                        <a
                          className="item"
                          href={`${wpSiteUrl}${urlLocaleMap.blog[defaultLocale]}`}
                          role="listitem"
                        >
                          {xgettext(
                            'Blog',
                            'Text for the link to the Hudya blog.',
                          )}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={`three wide column ${styles.socialFooter}`}>
                    <h3 className={`ui header ${styles.title}`}>
                      {gettext('Products')}
                    </h3>
                    <div
                      className={`ui link list ${styles.linkList}`}
                      role="list"
                    >
                      <a
                        style={{
                          pointerEvents: activeProducts.mobile[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.mobile[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/mobile'
                            : '/mobilabonnement'
                        }
                        role="listitem"
                      >
                        {xgettext(
                          'Mobile',
                          'Text for the link to Hudya Mobile.',
                        )}
                      </a>

                      <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/insurance'
                            : '/forsikring'
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.insurance[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.insurance[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext(
                          'Insurance',
                          'Text for the link to Hudya Insurance.',
                        )}
                      </a>
                      {/* <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/products/power'
                            : urlLocaleMap.power[defaultLocale]
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.power[defaultLocale]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.power[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext('Power', 'Text for the link to Hudya Power.')}
                      </a> */}
                      <a
                        className="item"
                        href={
                          this.props.credentials.isAuthenticated
                            ? '/refinansiering'
                            : '/refinansiering'
                        }
                        role="listitem"
                        style={{
                          pointerEvents: activeProducts.refinancing[
                            defaultLocale
                          ]
                            ? 'auto'
                            : 'none',
                          opacity: activeProducts.refinancing[defaultLocale]
                            ? 1
                            : 0.5,
                        }}
                      >
                        {xgettext(
                          'Refinance',
                          'Text for the link to Hudya Refinance.',
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="four wide column center aligned">
                    <h3 className={`ui header ${styles.title}`}>
                      {gettext('Need help? Get in touch!')}
                    </h3>
                    <div
                      className={`ui list ${styles.contactContainer}`}
                      role="list"
                    >
                      <p style={{ fontSize: 12 }}>
                        {renderHTML(
                          gettext(
                            'Åpent på hverdager fra <br /> <strong>08.00 - 16.00</strong>',
                          ),
                        )}
                      </p>
                      <a
                        className={`item ${styles.phone}`}
                        href={`tel:${gettext('004721415600')}`}
                        role="listitem"
                      >
                        <strong>{gettext('21 41 56 00')}</strong>
                      </a>
                      <a
                        className={`item ${styles.email}`}
                        href={gettext('mailto:kundeservice@hudya.no')}
                        role="listitem"
                      >
                        <strong>{gettext('kundeservice@hudya.no')}</strong>
                      </a>
                    </div>
                  </div>
                  <div className="three wide column" />
                  <div className="three wide column">
                    <div className="ui grid">
                      {/* <div className="row">
                        <div className="column center aligned">
                          <Dropdown
                            compact
                            selection
                            options={selectLanguageOptions}
                            onChange={this.handleSelectLanguage}
                            value={selectLanguageDefaultValue.value}
                          />
                        </div>
                      </div> */}

                      <div className="row">
                        <div
                          className={`column right aligned ${styles.socialFooter}`}
                        >
                          <img src="/assets/logo-black-new.svg" alt="logo" />
                          <div>
                            <a
                              className="ui button icon circular large basic secondary"
                              href={gettext(
                                'https://www.facebook.com/Scoopr-103103178189677/',
                              )}
                            >
                              <i className="facebook icon" />
                            </a>
                            {/* <a */}
                            {/*  className="ui button icon circular large basic secondary" */}
                            {/*  href="https://www.linkedin.com/company/hudya-group-as" */}
                            {/* > */}
                            {/*  <i className="linkedin icon" /> */}
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <div className="ui segment center aligned">
                      <p className={`copyright ${styles.copy}`}>
                        Scoopr Refinansiering AS - Org.nr 952 660 225 - Scoopr
                        Forsikring AS - Org.nr 989 979 167 - Scoopr Mobil AS -
                        Org.nr 917 864 152 - Henrik Ibsens gate 100, 0255 Oslo{' '}
                        <br />
                        All rights reserved.
                        <br />
                        <a href="/brukervilkar">Brukervilkår</a> -{' '}
                        <a href="/personvernerklaering">Personvernerklæring</a>{' '}
                        - <a href="/cookies">Cookies</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
