import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Form, Label } from 'semantic-ui-react'

import CustInput from '../../../components/CustInput'
import { accountAPI } from '../../../store/webapi/account'
import isValid from '../../../utils/validators'

// Hack to avoid eslint error:
const CardContent = Card.Content
const CardHeader = Card.Header
const CardMeta = Card.Meta
const FormGroup = Form.Group
const FormField = Form.Field

const IS_ZERO = 0

export default class AddressesEdit extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }

  static propTypes = {
    account: PropTypes.object.isRequired,
    accountAPIState: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      dispatch: PropTypes.func,
      setUser: PropTypes.func,
      toggleAddressEditState: PropTypes.func
    }).isRequired
  }
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: -1,
      cityIsPristine: true,
      formIsPristine: true,
      postalCodeIsPristine: true,
      streetNameIsPristine: true
    }

    this.setUser = this.props.actions.setUser.bind(this)
    this.toggleEditMode = this.props.actions.toggleAddressEditState.bind(this)

    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAccordionClick = this.handleAccordionClick.bind(this)
  }
  componentWillMount() {
    const USER = this.props.account

    if (Object.keys(USER).length === IS_ZERO) return

    this.setUser({ name: 'addresses', value: USER.contactInfo.addresses })
  }
  handleAccordionClick = (event, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleSaveClick() {
    const { xgettext } = this.context
    const { account } = this.props
    const { dispatch } = this.props.actions

    const primaryAddress =
      this.props.account.contactInfo.addresses.find(
        address => address.kind === 'primary'
      ) || {}
    const postFormat = primaryAddress.postFormat

    window.flashMessages.addMessage({
      id: 'id',
      loadingtext: xgettext(
        'Updating...',
        'Loading text save action on global flash message.'
      ),
      text: xgettext(
        'Your changes were saved!',
        'Success message for save action on global flash message.'
      ),
      type: 'success'
    })
    dispatch(
      accountAPI.actions.account.patch(account.id, {
        apartment: postFormat.apartment,
        city: postFormat.city,
        entrance: postFormat.entrance,
        floor: postFormat.floor,
        postalCode: postFormat.postalCode,
        streetLetter: postFormat.streetLetter,
        streetName: postFormat.streetName,
        streetNumber: postFormat.streetNumber
      })
    )

    this.toggleEditMode()
  }
  handleInputChange(event, value) {
    const name = `${value.name}IsPristine`
    const stateCopy = this.state

    stateCopy.formIsPristine = false
    stateCopy[name] = false
    this.setState(stateCopy)

    const primaryAddress =
      this.props.account.contactInfo.addresses.find(
        address => address.kind === 'primary'
      ) || {}

    if (value.name === 'streetNumber') {
      const str = value.value
      const regexStr = str.match(/[a-z]+|[^a-z]+/gi) || []

      primaryAddress.postFormat.streetNumber = regexStr[0] || ''

      primaryAddress.postFormat.streetLetter = regexStr[1] || ''
      this.props.actions.setUser({ name: 'addresses', value: primaryAddress })
      this.forceUpdate()

      return
    }
    primaryAddress.postFormat[value.name] = value.value

    primaryAddress.postFormat[`${value.name}IsValid`] = isValid(
      'required',
      value.value
    )
    this.props.actions.setUser({ name: 'addresses', value: primaryAddress })
    this.forceUpdate()
  }

  render() {
    const { gettext, xgettext } = this.context
    const { account } = this.props
    const { loading } = this.props.accountAPIState.account

    const primaryAddress =
      account.contactInfo.addresses.find(
        address => address.kind === 'primary'
      ) || {}
    const postFormat = primaryAddress.postFormat
    const validAddress =
      isValid('required', postFormat.streetName) &&
      isValid('required', postFormat.city) &&
      isValid('required', postFormat.city) &&
      !this.state.formIsPristine

    return (
      <div>
        <Card fluid>
          <CardContent extra>
            <CardHeader>{gettext('Billing information')}</CardHeader>
            <CardMeta>
              {xgettext(
                'This is your main invoicing address. ' +
                  'Make sure it matches your officially registered address.',
                'Text displayed underneath the card header on the Account page.'
              )}
            </CardMeta>
          </CardContent>
          <CardContent>
            <p style={{ fontWeight: 'bold' }}>{gettext('Billing address')}</p>
            <Form size="large">
              <FormGroup>
                <FormField width={12}>
                  <CustInput
                    required
                    error={
                      !this.state.streetNameIsPristine &&
                      !isValid('required', postFormat.streetName)
                    }
                    hintText={xgettext(
                      'Street name, or just type Postbox if that is the case.',
                      'Hint message on Street name field for billing address.'
                    )}
                    labelText={gettext('Street')}
                    name="streetName"
                    placeholder={gettext('Street')}
                    value={postFormat.streetName}
                    onChange={this.handleInputChange}
                  />
                  <span
                    hidden={
                      this.state.streetNameIsPristine
                        ? true
                        : isValid('required', postFormat.streetName)
                    }
                  >
                    <Label basic pointing color="red">
                      {xgettext(
                        'Please type your street name or postbox',
                        'Validation error message on Street name field for billing address.'
                      )}
                    </Label>
                  </span>
                </FormField>
                <FormField width={4}>
                  <CustInput
                    hintText={xgettext(
                      'This can be your building or postbox number.',
                      'Validation error message on street number field for billing address.'
                    )}
                    labelText={gettext('Number')}
                    name="streetNumber"
                    placeholder={xgettext(
                      '22B',
                      'Placeholder value for street number input form.'
                    )}
                    value={postFormat.streetNumber + postFormat.streetLetter}
                    onChange={this.handleInputChange}
                  />
                </FormField>
              </FormGroup>
              {/* <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleAccordionClick}>
                  <Icon name="add" />
                  Add more details
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <FormField width={4}>
                    <CustInput
                      hintText="Go grab a banana!"
                      labelText="Apartment"
                      name="apartment"
                      placeholder="apartment"
                      value={postFormat.apartment}
                      onChange={this.handleInputChange} />
                  </FormField>
                  <FormField width={4}>
                    <CustInput
                      hintText="Go grab a banana!"
                      labelText="Floor"
                      name="floor"
                      placeholder="floor"
                      value={postFormat.floor}
                      onChange={this.handleInputChange} />
                  </FormField>
                  <FormField width={4}>
                    <CustInput
                      hintText="Go grab a banana!"
                      labelText="Entrance"
                      name="entrance"
                      placeholder="entrance"
                      value={postFormat.entrance}
                      onChange={this.handleInputChange} />
                  </FormField>
                  <br />
                </Accordion.Content>
              </Accordion> */}

              <FormGroup widths="equal">
                <FormField>
                  <CustInput
                    required
                    error={
                      !this.state.postalCodeIsPristine &&
                      !isValid('postCode', postFormat.postalCode)
                    }
                    hintText={xgettext(
                      'Your postal, zip or area code.',
                      'Hint text on postalCode for billing address.'
                    )}
                    labelText={gettext('Postal code')}
                    name="postalCode"
                    placeholder={xgettext(
                      '0661',
                      'Placeholder value for zip code input form.'
                    )}
                    value={postFormat.postalCode}
                    onChange={this.handleInputChange}
                  />
                  <span
                    hidden={
                      this.state.postalCodeIsPristine
                        ? true
                        : isValid('postCode', postFormat.postalCode)
                    }
                  >
                    <Label basic pointing color="red">
                      {xgettext(
                        'This does not seem to be a valid post code',
                        'Validation error message on zip code input form.'
                      )}
                    </Label>
                  </span>
                </FormField>
                <FormField>
                  <CustInput
                    required
                    error={
                      !this.state.cityIsPristine &&
                      !isValid('required', postFormat.city)
                    }
                    hintText={xgettext(
                      'Your city or postal goegrafical identifier',
                      'Hint text on City for billing address.'
                    )}
                    labelText={gettext('City')}
                    name="city"
                    placeholder={xgettext(
                      'Oslo',
                      'Placeholder value for city input form.'
                    )}
                    value={postFormat.city}
                    onChange={this.handleInputChange}
                  />
                  <span
                    hidden={
                      this.state.cityIsPristine
                        ? true
                        : isValid('required', postFormat.city)
                    }
                  >
                    <Label basic pointing color="red">
                      {xgettext(
                        'Please type your city',
                        'Validation error message on City field in billing address edit form.'
                      )}
                    </Label>
                  </span>
                </FormField>
                <FormField />
              </FormGroup>
            </Form>
          </CardContent>
          <CardContent extra>
            <div>
              <Button
                primary
                disabled={!validAddress}
                loading={loading}
                onClick={this.handleSaveClick}
              >
                {gettext('Update billing information')}
              </Button>
              <Button basic primary onClick={this.toggleEditMode}>
                {gettext('Cancel')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}
