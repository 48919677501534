import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ChatContainer from '../../../components/ChatContainer';
import productstyles from '../Home/home.module.scss';
import ProductCard from '../../../components/ProductCard/productCard';
import FaqHelper from '../../../components/FaqHelper/faqHelper';
import Page from '../../../components/Page/Page';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import useGetInsurancePageQuery from '../../../hooks/gql/landings/insurance/GetInsurancePage.query';
import PageLoader from '../../../components/PageLoader/pageLoader';
import useGetProductTypes from '../../../hooks/gql/GetProductTypes.query';

import styles from './styles.module.scss';

const HiddenInsurance = () => {
  const history = useHistory();
  const { data, loading } = useGetInsurancePageQuery();
  const {
    data: productTypesData,
    loading: productTypesLoading,
  } = useGetProductTypes();
  const { flow, step } = useParams();
  return loading || productTypesLoading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title="Forsikring" loading={false}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.contentContainer}>
        <Container>
          <Grid textAlign="left">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <ChatContainer
                  flow={flow || data.flow}
                  step={step || data.step || ''}
                  chatH1={data.title}
                  firstMessage={data.firstMessage || ''}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <div className={styles.content}>
            {HtmlParse({
              data: data.content || '',
            })}
          </div>
        </Container>
      </div>
      <div>
        <div>
          <FaqHelper type="forsikring" />
        </div>
        <div className={productstyles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductCard
                  title={productTypesData.mobileProduct.title || ''}
                  text={productTypesData.mobileProduct.text || ''}
                  buttonText={productTypesData.mobileProduct.buttonText || ''}
                  buttonUrl={productTypesData.mobileProduct.buttonUrl || ''}
                  moreText={productTypesData.mobileProduct.moreText || ''}
                  moreAction={() => {
                    history.push(productTypesData.mobileProduct.moreAction);
                  }}
                  image="/assets/home/mobile.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title={productTypesData.refinancingProduct.title || ''}
                  text={productTypesData.refinancingProduct.text || ''}
                  buttonText={
                    productTypesData.refinancingProduct.buttonText || ''
                  }
                  buttonUrl={
                    productTypesData.refinancingProduct.buttonUrl || ''
                  }
                  moreText={productTypesData.refinancingProduct.moreText || ''}
                  moreAction={() => {
                    history.push(
                      productTypesData.refinancingProduct.moreAction,
                    );
                  }}
                  image="/assets/home/bank.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
      </div>
    </Page>
  );
};

export default HiddenInsurance;
