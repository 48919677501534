import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Tab } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown/with-html';

import { setCredentials } from '../../../store/global/credentials';
import Page from '../../../components/Page/Page';

import styles from './mobile.module.scss';
import panesNoFlex from './components/MoreInfo/components/DataTables/noFlex';

class MobilePrices extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {};

  constructor(props, context) {
    super(props, context);

    document.title = context.xgettext(
      'Mobilabonnement - Øvrige priser',
      'Mobile price page title',
    );
  }

  render() {
    const { xgettext } = this.context;

    return (
      <Page
        title={xgettext(
          'Mobilabonnement - Øvrige priser',
          'Mobile price page title',
        )}
        loading={false}
      >
        <div className={styles['public-mobile']}>
          <Container>
            <div
              className={styles['public-mobile-header']}
              style={{ position: 'relative' }}
            >
              <h1>
                <ReactMarkdown
                  source={xgettext(
                    'Hudya <strong>Mobil</strong> øvrige priser',
                    'mobile price h1',
                  )}
                  escapeHtml={false}
                />
              </h1>
            </div>
          </Container>
          <div className={styles['mobile-prices']}>
            <Container>
              {
                <Tab
                  menu={{
                    attached: false,
                    tabular: false,
                    stackable: true,
                  }}
                  panes={panesNoFlex}
                />
              }
            </Container>
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch),
    ),
  }),
)(MobilePrices);
