export const pushEventToTagManager = (action, category, event) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    action,
    category,
    event
  })
}
export const pushUseridToTagManager = userId => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ userId })
}
export const getGaClientId = () => {
  if (!window.ga) {
    return ''
  }
  let clientId = ''
  window.ga(function() {
    clientId = window.ga.getAll()[0].get('clientId')
  })
  return clientId
}
