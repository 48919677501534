import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetMobileCommonQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getMobile {
        res: pageBy(uri: "mobile-product") {
          status
          content
          contentData: mobileLandingPage {
            rating
            testimonials
            whyWeTitle: whywetitle
            whyWeLeftList: whyweleftlist {
              id
              text
            }
            whyWeRightList: whywerightlist {
              id
              text
            }
            whyWeContent: whywecontent
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    rating: 0,
    testimonials: '',
    whyWeTitle: '',
    whyWeLeftList: [],
    whyWeRightList: [],
    whyWeContent: null,
    content: '',
  };

  return { loading, error, data };
};

export default useGetMobileCommonQuery;
