import React from 'react';
import PropTypes from 'prop-types';
import {Message} from 'semantic-ui-react';

const ErrorMessage = ({field, error}) => {
  if (!error) {
    return null;
  }

  return <Message className="red" content={field.errorMessage} />;
};

ErrorMessage.propTypes = {
  error: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired
};
export default ErrorMessage;
