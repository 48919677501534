/**
 * WebAPI reducer for billing/invoice.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'
import { discardPaginationTransformer } from '../transformers'

const webapi = reduxApi({
  invoice: {
    helpers: {
      get() {
        return [{}, {}]
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.core.baseURI}/billing/invoices/`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as invoiceAPI }
export default combineReducers(webapi.reducers, {})
