import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Header } from 'semantic-ui-react';

import store from '../../../../../store/configureStore';
import Chat from '../../../../../components/Chat';
import MoreInfo from '../MoreInfo';
import HtmlParse from '../../../../../components/HtmlParser/htmlParser';

import styles from './subscriptionCard.module.scss';

const SubscriptionCard = ({
  title,
  subtitle,
  leftBoxLabel,
  leftBoxValue,
  rightBoxLabel,
  rightBoxValue,
  price,
  priceText,
  orderButtonText,
  moreButtonText,
  moreLink,
  goalFlow,
  step,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.logo}>
        <img src="/assets/logo-blue.svg" alt="logo" />
      </div>
      <div className={styles.header}>
        <h1>{HtmlParse({ data: title || '' })}</h1>
        <h3>{HtmlParse({ data: subtitle || '' })}</h3>
      </div>
      <div className={styles.boxCorners}>
        <div className={styles.left} />
        <div className={styles.right} />
      </div>
      <div className={styles.boxes}>
        <div className={styles.leftBox}>
          <p className={styles.value}>
            {HtmlParse({ data: leftBoxValue || '' })}
          </p>
          <p className={styles.label}>
            {HtmlParse({ data: leftBoxLabel || '' })}
          </p>
        </div>
        <div className={styles.rightBox}>
          <p className={styles.value}>
            {HtmlParse({ data: rightBoxValue || '' })}
          </p>
          <p className={styles.label}>
            {HtmlParse({ data: rightBoxLabel || '' })}
          </p>
        </div>
      </div>
      <div className={styles.priceBox}>
        <p className={styles.price}>{HtmlParse({ data: price || '' })}</p>
        <p className={styles.priceText}>
          {HtmlParse({ data: priceText || '' })}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <Modal
          trigger={
            <Button size="big">
              {HtmlParse({ data: orderButtonText || '' })}
            </Button>
          }
          basic
          size="tiny"
          closeIcon
          defaultOpen={false}
        >
          <Header icon="chat" content="Snakk med oss!" />
          <Modal.Content style={{ minHeight: 1500 }}>
            <p>
              Her kan du lære mer om våre produkter og bestille våre tjenester
            </p>
            <div style={{ minHeight: 900 }}>
              <Chat goalFlow={goalFlow} step={step} store={store} />
            </div>
          </Modal.Content>
        </Modal>
      </div>
      <div className={styles.moreContainer}>
        <MoreInfo name={title} link={moreLink} moreText={moreButtonText} />
      </div>
    </div>
  );
};

SubscriptionCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  leftBoxLabel: PropTypes.string,
  leftBoxValue: PropTypes.string,
  rightBoxLabel: PropTypes.string,
  rightBoxValue: PropTypes.string,
  price: PropTypes.string,
  priceText: PropTypes.string,
  orderButtonText: PropTypes.string,
  moreButtonText: PropTypes.string,
  moreLink: PropTypes.string,
  goalFlow: PropTypes.string,
  step: PropTypes.string,
};
SubscriptionCard.defaultProps = {
  title: '',
  subtitle: '',
  leftBoxLabel: '',
  leftBoxValue: '',
  rightBoxLabel: '',
  rightBoxValue: '',
  price: '',
  priceText: '',
  orderButtonText: '',
  moreButtonText: '',
  moreLink: '',
  goalFlow: '',
  step: '',
};

export default SubscriptionCard;
