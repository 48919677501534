import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

import styles from './styles.module.scss';

const InvoiceCard = ({ data, type, loading }) => {
  const [showAll, setShowAll] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const handleShowAll = () => {
    setShowAll(true);
  };

  useEffect(() => {
    const inv = data.invoices || [];
    if (!showAll) {
      setInvoices(inv.slice(0, 5));
    } else {
      setInvoices(inv);
    }
  }, [data]);

  return (
    <div className={`ui card ${styles.cardContainer} ${styles[type]}`}>
      <div className={`content ${styles.cardContent}`}>
        {loading ? (
          <div className={styles.loaderContainer}>
            <Loader
              className={styles.loader}
              active
              size="medium"
              color="white"
            />
          </div>
        ) : (
          <>
            <div className={styles.cardHeader}>Faktura</div>
            <div className={styles.contentSeparator} />
            <div
              className={`${styles.cardDetails} ${
                showAll ? styles.showAllOn : ''
              }`}
            >
              {invoices.map((f, i) => (
                <div key={f.id}>
                  <div className="ui grid">
                    <div className="three wide column">
                      <div className={styles.value}>
                        <div className={styles.icon}>
                          <i
                            aria-hidden="true"
                            className="file alternate outline icon"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="seven wide column">
                      <div className={styles.value}>
                        <div className={styles.label}>
                          {moment(new Date(f.createdAt)).format('MMMM YYYY')}
                        </div>
                        <div className={styles.desc}>
                          Due date:{' '}
                          {moment(new Date(f.dueDate)).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="six wide column">
                      <div className={styles.balanceValue}>
                        <div className={styles.balance}>
                          {f.totalAmount.toFixed(2)}{' '}
                          <span className={styles.currency}>{f.currency}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {invoices.length !== i + 1 ? (
                    <div className={styles.contentSeparator} />
                  ) : null}
                </div>
              ))}
            </div>
            {!showAll && invoices.length > 5 ? (
              <>
                <div className={styles.contentSeparator} />
                <div className={styles.showAll} onClick={() => handleShowAll()}>
                  Se alle faktura
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

InvoiceCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

InvoiceCard.defaultProps = {
  loading: false,
};

export default InvoiceCard;
