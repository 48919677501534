/**
 * WebAPI reducer for mobile.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import { combineReducers } from 'redux';

import config from '../../../config';

const webapi = reduxApi({
  esnSubscriptions: {
    helpers: {
      get(id) {
        return [{ id }, {}];
      },
    },
    url: `${config.webapi.esn.baseURI}/v2/consumer/metering-points/monthly`,
  },
  esnSubscription: {
    helpers: {
      get(accountId, subscriptionId) {
        return [{ accountId, subscriptionId }, {}];
      },
    },
    url: `${config.webapi.esn.baseURI}/v2/consumer/:accountId/metering-point/:subscriptionId/monthly`,
  },
  esnInvoices: {
    helpers: {
      get() {
        return [{}, {}];
      },
    },
    url: `${config.webapi.esn.baseURI}/v2/consumer/invoices`,
  },
})
  .use('options', (url, params, getState) => {
    const { accessToken } = getState().global.credentials;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      return {
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
      };
    }

    return headers;
  })
  .use('fetch', adapterFetch(fetch));

export { webapi as esnAPI };
export default combineReducers(webapi.reducers, {});
