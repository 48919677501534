import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetHomeQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query GetHome {
        res: pageBy(uri: "home-landing-page") {
          status
          content
          contentData: homeLandingPage {
            title
            header
            chatH1: chath1
            flow
            step
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    chatH1: '',
    header: '',
    flow: '',
    step: '',
    content: '',
    title: 'Hjem',
  };

  return { loading, error, data };
};

export default useGetHomeQuery;
