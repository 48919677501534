import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import PageLoader from '../../../components/PageLoader/pageLoader';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import styles from '../../Content/content.module.scss';
import ChatContainer from '../../../components/ChatContainer';
import Page from '../../../components/Page/Page';
import useGetCompetitionPageQuery from '../../../hooks/gql/landings/competitionPage/GetCompetitionPage.query';

const CompetitionPage = () => {
  const { data, loading } = useGetCompetitionPageQuery();

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title || null} loading={loading}>
      <div className={`${styles.contentContainer} ${styles.darkContainer}`}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <a href="/mobilabonnement" className={styles.link}>
                    Mobilabonnement
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>Konkurranse</span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={`${styles.header} ${styles.centered}`}>
                  <div className={styles.title}>{data.title || ''}</div>
                  <div className={styles.subtitle}>{data.subtitle || ''}</div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.text}>
                  {HtmlParse({
                    data: data.description || '',
                  })}
                </div> </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container className={styles.removeMargin}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <ChatContainer
                  flow={data.flow || ''}
                  step={data.step || ''}
                  chatH2=""
                  chatText="Gratis abonnement i 12 mnd. inkl fri bruk av minutter/sms/MMS til normale norske nummer
                  * Ringer du spesialnummer, utlandssamtaler eller bruker overtakserte sms (betalingstjenester) gjelder ordinære priser for dette.
                  * Fri bruk er definert som maksimalt 10000/minutter/sms og MMS.
                  * Hver bank har sin prismodell. Prisene varierer mellom bankene og fra kunde til kunde. Priseksempel 205000 over 5 år. Effektiv rente 12,83%. Kostnad 67040. Total kostnad 272040"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default CompetitionPage;
