import moment from 'moment';
import _ from 'lodash';

import { esnAPI } from '../../webapi/esn';
import { subscriptionsAPI } from '../../webapi/subscriptions';

const initialState = {
  lastUpdated: null,
  power: [],
  subscriptions: [],
  subscription: {},
  invoices: [],
  sync: false,
  error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case esnAPI.events.esnSubscriptions.actionFail:
      console.log('esn: ', action.error);
      return Object.assign({}, state, {
        subscriptions: Object.assign([], state.subscriptions, []),
        sync: true,
        error: true,
      });
    case subscriptionsAPI.events.subscriptionsList.actionSuccess:
      const power = action.data
        .filter((e) => e.product.kind === 'POWER' && e.state === 'ACTIVE')
        .map((p) => ({
          id: p.id,
          subscriptionId: p.asset.foreignId,
          description: p.description,
          subscriptionName: p.product.name,
          created: p.created,
          contract: p.contract.id,
          customer: {
            id: p.contract.entity.id,
            name: p.contract.entity.name,
          },
          address: {
            country: p.country,
            place: p.asset.address.place,
            postalCode: p.asset.address.code,
            street:
              p.asset.address.addressLines.toString().replace(',', ' ') || '',
          },
          provider: p.country === 'NO' ? 'esn' : 'cubs',
          usage: [],
          currency: '-',
          balance: '-',
          rate: '-',
          per: '-',
        }));

      return Object.assign({}, state, {
        power: Object.assign([], state.power, [...power]),
      });
    case esnAPI.events.esnSubscriptions.actionSuccess:
      const subscriptions = [];
      let lastUpdated = null;
      if (action.data) {
        lastUpdated = action.data.lastUpdated;
        state.power.forEach((p) => {
          const mp =
            action.data.result.find(
              (e) => e.meteringPointId === p.subscriptionId,
            ) || null;
          if (!mp) {
            return false;
          }

          const lastMonthId = moment().subtract(1, 'months').format('M');
          let m =
            _.find(mp.usage, (u) => u.month.toString() === lastMonthId) || null;
          if (!m) {
            m = {
              balance: 0,
              rate: 0,
            };
          }
          subscriptions.push({
            ...p,
            currency: mp.currency,
            per: `${mp.currency}/kWh`,
            balance: (m.balance / 100).toFixed(2),
            // rate: m.rate.toFixed(2),
            usage: mp.usage,
            monthUsage: m.usage,
          });
          return true;
        });
      }
      return Object.assign({}, state, {
        subscriptions: Object.assign([], state.subscriptions, [
          ...subscriptions,
        ]),
        sync: true,
        lastUpdated,
      });
    case esnAPI.events.esnSubscription.actionSuccess:
      let subscription = {};
      if (action.data) {
        subscription = state.subscriptions.find(
          (e) => e.subscriptionId === action.data.meteringPointId,
        );
        if (subscription) {
          const usage = action.data.usage || [];
          const lastMonthId = moment().subtract(1, 'months').format('M');
          const m = _.find(usage, (u) => u.month.toString() === lastMonthId);
          const invoices = state.invoices.map((i) => ({
            id: i.id,
            createdAt: i.created,
            dueDate: i.dueDate,
            currency: i.currencyCode,
            totalAmount: i.totalAmount,
            subTotalAmount: i.totalAmount,
            taxes: i.taxes,
            paid: i.paid,
            number: i.number,
          }));
          subscription = {
            ...subscription,
            currency: action.data.currency,
            per: `${subscription.currency}/kWh`,
            balance: m ? (m.balance / 100).toFixed(2) : 0,
            rate: m ? m.rate.toFixed(2) : 0,
            invoices,
          };
        }
      }
      return Object.assign({}, state, {
        subscription: Object.assign({}, state.subscription, {
          ...subscription,
        }),
      });
    case esnAPI.events.esnInvoices.actionSuccess:
      let invoices = [];
      if (action.data) {
        invoices = action.data.result.map((i) => ({
          ...i,
          totalAmount: i.totalExclVat + i.totalVat,
          createdAt: i.createdAt,
        }));
      }
      return Object.assign({}, state, {
        invoices: Object.assign([], state.invoices, [...invoices]),
      });

    default:
      return state;
  }
}
