import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetProductTypes = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getProductTypes {
        res: pageBy(uri: "scoopr-product-types") {
          status
          content
          contentData: scooprProductTypes {
            mobileProduct: mobileproduct {
              buttonText: buttontext
              buttonUrl: buttonurl
              moreAction: moreaction
              moreText: moretext
              text
              title
            }
            insuranceProduct: insuranceproduct {
              buttonText: buttontext
              buttonUrl: buttonurl
              moreAction: moreaction
              moreText: moretext
              text
              title
            }
            refinancingProduct: refinancingproduct {
              buttonText: buttontext
              buttonUrl: buttonurl
              moreAction: moreaction
              moreText: moretext
              text
              title
            }
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    mobileProduct: {
      buttonText: '',
      buttonUrl: '',
      moreAction: '',
      moreText: '',
      text: '',
      title: '',
    },
    insuranceProduct: {
      buttonText: '',
      buttonUrl: '',
      moreAction: '',
      moreText: '',
      text: '',
      title: '',
    },
    refinancingProduct: {
      buttonText: '',
      buttonUrl: '',
      moreAction: '',
      moreText: '',
      text: '',
      title: '',
    },
    content: null,
  };

  return { loading, error, data };
};

export default useGetProductTypes;
