import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ChatContainer from '../../components/ChatContainer';

import styles from './styles.module.scss';

const FlowSandBox = () => {
  const { step, flow, product, productValue } = useParams();

  const getClientKnowledge = () => {
    if (product && productValue) {
      return {
        [product]: {
          product: productValue,
        },
      };
    }
    return null;
  };

  return (
    <div className={styles.contentContainer}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Grid textAlign="left">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={11} computer={11}>
              {getClientKnowledge() ? (
                <ChatContainer
                  flow={flow}
                  step={step}
                  chatH1="Flow Sandbox"
                  columns={16}
                  clientKnowledge={getClientKnowledge()}
                  disc={null}
                />
              ) : (
                <ChatContainer
                  flow={flow}
                  step={step}
                  chatH1="Flow Sandbox"
                  columns={16}
                  disc={null}
                />
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <div className={styles.sideContainer}>
                <div className={styles.rating} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default FlowSandBox;
