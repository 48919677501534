import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';

import ChatContainer from '../../../components/ChatContainer';
import ProductDescriptionContent from '../../../components/ProductDescriptionContent/productDescriptionContent';
import productstyles from '../Home/home.module.scss';
import ProductCard from '../../../components/ProductCard/productCard';
import FaqHelper from '../../../components/FaqHelper/faqHelper';
import Page from '../../../components/Page/Page';

import styles from './insurance.module.scss';

const PublicInsurance = () => {
  const history = useHistory();
  const { flow } = useParams();
  return (
    <Page title="Forsikring" loading={false}>
      <div className={styles['insurance-mobile']}>
        <ChatContainer
          flow={flow || 'insurance_leads'}
          step="init_with_fixed_start"
          chatH1="Forsikring"
          // firstMessage="Hei! her kan du snart sammenligne forsikringsavtalen du har i dag med tilbud fra oss. Med et par tastetrykk får du enkelt svar på hvor mye du kan spare på forsikring årlig."
        />

        <div className={productstyles.descriptionContainer}>
          <ProductDescriptionContent page="insurance" />
        </div>
        <div>
          <FaqHelper type="forsikring" />
        </div>
        <div className={productstyles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductCard
                  title="Mobil"
                  text="Vi har full Telenor-dekning, Data Rollover, gratis BankID ogingen bindingstid."
                  buttonText="Bestill nå"
                  buttonUrl="/mobilabonnement"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/mobilabonnement/produkter');
                  }}
                  image="/assets/home/mobile.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title="Refinansiering"
                  text="Logg inn med BankID på mobil og se hva du kan spare på lån og kredittkort."
                  buttonText="Bestill nå"
                  buttonUrl="/refinansiering"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/refinansiering');
                  }}
                  image="/assets/home/bank.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
      </div>
    </Page>
  );
};

export default PublicInsurance;
