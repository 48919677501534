import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Grid, Container } from 'semantic-ui-react';
import jwtDecode from 'jwt-decode';

import { legalEntityAPI } from '../../../store/webapi/legalEntity';
import { setCredentials } from '../../../store/global/credentials';
// import SvgDivider from '../../../components/SvgDivider';

import Page from '../../../components/Page/Page';

import TopSection from './components/TopSection';
// import Features from './components/Features';
// import StepCard from './components/StepCard';
// import HelpCard from './components/HelpCard';
import SubscriptionSection from './components/SubscriptionSection';

class MobileOverview extends React.Component {
  static contextTypes = { gettext: PropTypes.func.isRequired };

  static propTypes = {};

  constructor(props, context) {
    super(props, context);
    this.logUserIn = this.logUserIn.bind(this);
    this.state = { activeIndex: -1 };
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  logUserIn(jwt) {
    if (!jwt) {
      return;
    }
    this.props.actions.setCredentials(jwt);

    const decodedJWT = jwtDecode(jwt);

    this.props.actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub }),
    );
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    const { gettext } = this.context;
    const flow = params.get('flow');
    const jwt = localStorage.getItem('brainSessionJwt');
    const productIncludesStatements = [
      gettext('Telenor 4G'),
      gettext('No binding'),
      gettext('Use in EU'),
      gettext('Free BankID'),
      gettext('Free speed'),
    ];

    return (
      <Page title="Produkter" loading={false}>
        <div className="mobile-overview">
          {/* PLANS CARDS */}

          <section className="mobile-information">
            {/* INCLUDE */}
            {/* <div className="mobile-includes  mobile hidden"> */}
            {/*  <Container textAlign="center"> */}
            {/*    <p className="line-back-title"> */}
            {/*      <span>{gettext('Our products include')}</span> */}
            {/*    </p> */}
            {/*    <div className="ui stackable doubling five column grid"> */}
            {/*      {productIncludesStatements.map((item, index) => ( */}
            {/*        <ProductincludesElements statement={item} key={index} /> */}
            {/*      ))} */}
            {/*    </div> */}
            {/*  </Container> */}
            {/* </div> */}
            <SubscriptionSection />
            {/* <div className="mobile-includes  mobile only"> */}
            {/*  <Container textAlign="center"> */}
            {/*    <p className="line-back-title"> */}
            {/*      <span>{gettext('Our products include')}</span> */}
            {/*    </p> */}
            {/*    <div className="ui stackable doubling five column grid"> */}
            {/*      {productIncludesStatements.map((item, index) => ( */}
            {/*        <ProductincludesElements statement={item} key={index} /> */}
            {/*      ))} */}
            {/*    </div> */}
            {/*  </Container> */}
            {/* </div> */}
            {/* <Features /> */}

            {/* <StepCard /> */}
            {/* <HelpCard /> */}
          </section>
        </div>
      </Page>
    );
  }
}
// const ProductincludesElements = (props) => (
//   <Grid.Column>
//     <p>
//       <img src={`${process.env.PUBLIC_URL}/assets/icon-check-mark.svg`} />{' '}
//       <b>{props.statement}</b>
//     </p>
//   </Grid.Column>
// );
export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch),
    ),
  }),
)(MobileOverview);

// function Header(props) {
//   return (
//     <div className="main-heading">
//       <h1>
//         Betal for strømmen du bruker, og <strong>ikke noe mer</strong>
//       </h1>
//     </div>
//   )
// }
