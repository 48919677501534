const TOGGLE_EDIT_STATE = 'myHudya:gui:account/TOGGLE_EDIT_STATE';
const TOGGLE_EDIT_ADDRESS_STATE = 'myHudya:gui:account/TOGGLE_EDIT_ADDRESS_STATE';
const SET_USER = 'myHudya:gui:account/SET_USER';
const SET_BIRTHDATE = 'myHudya:gui:account/SET_BIRTHDATE';
const RESET = 'myHudya:gui:account/RESET';

const inititalState = {
  editAddressState: false,
  editState: false,
  phonePrefix: '+47',
  phonePrefixes: [
    {key: '+45', text: '+45', value: '+45'},
    {key: '+46', text: '+46', value: '+46'},
    {key: '+47', text: '+47', value: '+47'}
  ],
  user: {addresses: {}, birthDate: '', email: '', name: '', phone: '', phonePrefix: ''}
};

export default function reducer(state = inititalState, action) {
  switch (action.type) {
    case RESET:
      return inititalState;
    case TOGGLE_EDIT_STATE:
      return Object.assign({}, state, {
        ...state,
        editState: !state.editState
      });
    case TOGGLE_EDIT_ADDRESS_STATE:
      return Object.assign({}, state, {
        ...state,
        editAddressState: !state.editAddressState
      });
    case SET_USER: {
      const obj = state.user;

      obj[action.payload.name] = action.payload.value;

      return Object.assign({}, state, {
        ...state,
        user: obj
      });
    }
    case SET_BIRTHDATE: {
      const obj = state.user;

      if (typeof action.payload === 'string') {
        return state;
      }
      obj.birthDate = action.payload.format('DD/MM/YYYY');

      return Object.assign({}, state, {
        ...state,
        user: obj
      });
    }
    default:
      return state;
  }
}
export const resetAccountState = () => ({type: RESET});
export const toggleEditState = () => ({type: TOGGLE_EDIT_STATE});
export const toggleAddressEditState = () => ({type: TOGGLE_EDIT_ADDRESS_STATE});
export const setUser = (payload) => ({payload, type: SET_USER});
export const setBirthDate = (payload) => ({payload, type: SET_BIRTHDATE});
