import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'semantic-ui-react';
import MarkdownRenderer from 'react-markdown-renderer';
import {withRouter} from 'react-router-dom';

import TextInput from './components/TextInput';
import ErrorMessage from './components/ErrorMessage';
import ExtraContent from './components/ExtraContent';

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChoiceButtonClick = this.handleChoiceButtonClick.bind(this);
    this.handleHelpButtonClick = this.handleHelpButtonClick.bind(this);
    this.state = {error: false, fuzzyValue: '', showTips: false};
  }
  handleHelpButtonClick() {
    this.setState({showTips: !this.state.showTips});
  }
  handleFieldChange(event, data) {
    this.setState({fuzzyValue: data.value});
    this.setState({error: false});
    this.props.updateErrorState(false);
  }
  handleButtonClick() {
    const field = this.props.fields.find((item) => !item.value);

    if (field.validator && !field.validator(this.state.fuzzyValue)) {
      this.setState({error: true});
      this.props.updateErrorState(true);

      return;
    }

    this.props.handleUpdate(this.state.fuzzyValue);
    if (field.buttonCallback) {
      field.buttonCallback(this.state.fuzzyValue);
    }
    this.setState({fuzzyValue: ''});
  }
  handleChoiceButtonClick(event, button) {
    this.props.handleUpdate(button.value);

    this.setState({fuzzyValue: ''});
  }

  render() {
    const fieldId = this.props.match.params.id;

    const field = this.props.fields.find((item) => String(item.id) === String(fieldId));

    if (!field) return null;

    if (this.state.showTips) {
      return <ExtraContent field={field} handleHelpButtonClick={this.handleHelpButtonClick} />;
    }

    return (
      <div className={this.props.active ? 'active' : null}>
        <MarkdownRenderer markdown={field.question} />

        <br />
        {field.tips && (
          <div style={{'text-align': 'right'}}>
            <Button
              circular
              inverted
              content={field.tips}
              type="button"
              onClick={this.handleHelpButtonClick} />
            <br />
            <br />
            <br />
          </div>
        )}
        <TextInput values={field} onChange={this.handleFieldChange} fuzzyValue={this.state.fuzzyValue} />
        <ErrorMessage error={this.state.error} field={field} />

        {field.type === 'input' && (
          <div style={{'text-align': 'right'}}>
            <br />

            <Button
              circular
              inverted
              content={field.buttonText || 'Tilbake'}
              onClick={field.buttonText ? this.handleButtonClick : () => this.props.history.goBack()} />
            <br />
          </div>
        )}
        {field.alternatives
          && field.alternatives.map((button) => (
            <div style={{'text-align': 'right'}} key={field.id}>
              <Button
                circular
                inverted
                content={button.text}
                value={button.value}
                onClick={this.handleChoiceButtonClick} />
              <br />
              <br />
            </div>
          ))}
      </div>
    );
  }
}
Conversation.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};
Conversation.propTypes = {fields: PropTypes.array.isRequired};
export default withRouter(Conversation);
