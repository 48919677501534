import React from 'react';
import PropTypes from 'prop-types';
import {Accordion, Form, Icon} from 'semantic-ui-react';

// import './style.css';

const FormInput = Form.Input;

const CustInput = ({className = '', ...props}) => {
  const hintText = props.hintText;
  const labelText = props.labelText;
  const showHelp = false;

  Reflect.deleteProperty(props, 'hintText');
  Reflect.deleteProperty(props, 'labelText');

  return (
    <FormInput
      basic
      className={['', className].join(' ')}
      {...props}
      label={<Label2 hintText={hintText} labelText={labelText} required={props.required} showHelp={showHelp} />} />
  );
};

CustInput.propTypes = {
  className: PropTypes.string,
  hintText: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool
};

export default CustInput;

class Label2 extends React.Component {
  static contextTypes = {xgettext: PropTypes.func.isRequired};
  static propTypes = {
    hintText: PropTypes.string,
    labelText: PropTypes.string,
    required: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.state = {showHelp: props.showHelp};
    this.handleHelpClick = this.handleHelpClick.bind(this);
  }
  handleHelpClick() {
    this.setState({showHelp: !this.state.showHelp});
  }
  render() {
    const {xgettext} = this.context;

    return (
      <div>
        <div hidden={this.props.hintText} style={{marginBottom: '0.5em', paddingTop: '0.5em'}}>
          <label style={{fontWeight: 'bold', marginBottom: '0'}}>{this.props.labelText}</label>
        </div>

        <span hidden={this.props.hintText === '' || typeof this.props.hintText === 'undefined'}>
          <Accordion>
            <Accordion.Title active={this.state.showHelp} index={0} onClick={this.handleHelpClick}>
              <label style={{fontWeight: 'bold'}}>{this.props.labelText}</label>
              <label hidden={this.props.required}>
                {xgettext('(optional)', 'identifies an optional input field in a form')}
              </label>
              <Icon inverted className="circle" color="grey" name="info" />
            </Accordion.Title>
            <Accordion.Content active={this.state.showHelp}>
              <div className="ui message small" style={{marginBottom: '1em'}}>
                {this.props.hintText}
              </div>
            </Accordion.Content>
          </Accordion>
        </span>
      </div>
    );
  }
}
