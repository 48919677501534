import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Loader, Table, Pagination } from 'semantic-ui-react';
import axios from 'axios';
import { toast } from 'react-toastify';

import 'react-circular-progressbar/dist/styles.css';

import styles from './styles.module.scss';

class InvoicesTable extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      invoiceId: null,
      activePage: 1,
      rowsPerPage: 5,
    };
  }

  getInvoice = (invoiceUrl, invoiceId) => {
    const { credentials } = this.props;
    const { gettext } = this.context;
    this.setState({ invoiceId });
    axios({
      url: invoiceUrl,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: `Bearer ${credentials.accessToken}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
        this.setState({ invoiceId: null });
      })
      .catch((e) => {
        console.log('error', e);
        let errMsg = gettext('Noe gikk galt. Prøv igjen senere.');
        if (e && e.code && e.code === 404) {
          errMsg = gettext('Faktura ikke funnet.');
        }
        toast(errMsg, { type: 'error' });
        this.setState({ invoiceId: null });
      });
  };

  getTotalPages = () => {
    const { invoices } = this.props;
    const { rowsPerPage } = this.state;
    if (invoices && invoices.length) {
      return Math.ceil(invoices.length / rowsPerPage);
    }
    return 0;
  };

  getItems = (items) => {
    const { activePage, rowsPerPage } = this.state;
    if (items && items.length) {
      return items
        .map((i, index) => ({ index: index + 1, ...i }))
        .slice(
          (activePage - 1) * rowsPerPage,
          (activePage - 1) * rowsPerPage + rowsPerPage,
        );
    }
    return [];
  };

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage });

  render() {
    const { invoices } = this.props;
    const { invoiceId, activePage } = this.state;
    const { gettext } = this.context;
    return (
      <Card fluid className={styles.invoicesTable}>
        <Card.Content className={styles.invoicesTable}>
          <h2>{gettext('Latest invoices')}</h2>
          <div style={{ maxHeight: 315, overflow: 'auto', width: 'auto' }}>
            <Table singleLine unstackable basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{gettext('#')}</Table.HeaderCell>
                  <Table.HeaderCell>{gettext('Invoice Date')}</Table.HeaderCell>
                  <Table.HeaderCell>{gettext('Amount')}</Table.HeaderCell>
                  <Table.HeaderCell>{gettext('Status')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              {invoices && invoices.length > 0 ? (
                <Table.Body>
                  {this.getItems(invoices)
                    .sort((a, b) => {
                      return new Date(a.createdAt) < new Date(b.createdAt);
                    })
                    .filter((i) => i.totalAmount > 0)
                    .map((invoice) => {
                      return (
                        <Table.Row key={invoice.index}>
                          <Table.Cell>{invoice.index}</Table.Cell>
                          <Table.Cell>
                            {moment(invoice.createdAt).format('DD/MM/YYYY')}
                          </Table.Cell>

                          <Table.Cell>
                            {invoice.totalAmount.toFixed(2)} {gettext('NOK')}
                          </Table.Cell>

                          <Table.Cell color="red">
                            {invoice.paid === 'PAID' ? (
                              <span style={{ color: 'green' }}>
                                {gettext('Paid')}
                              </span>
                            ) : (
                              <span style={{ color: 'red' }}>
                                {gettext('Unpaid')}
                              </span>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {invoiceId !== invoice.id &&
                            invoice.invoiceURL !== '' ? (
                              <span
                                className={styles.link}
                                onClick={() =>
                                  this.getInvoice(
                                    invoice.invoiceURL,
                                    invoice.id,
                                  )
                                }
                              >
                                {gettext('View invoice')}
                              </span>
                            ) : null}
                            {invoiceId === invoice.id ? (
                              <div className={styles.loaderContainer}>
                                <Loader active className={styles.loader} />
                              </div>
                            ) : null}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="4" className={styles.empty}>
                      {gettext('Ingen fakturaer')}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          </div>
          {this.getTotalPages() > 1 ? (
            <div className={styles.pagination}>
              <Pagination
                activePage={activePage}
                totalPages={this.getTotalPages()}
                onPageChange={this.handlePaginationChange}
                style={{ minHeight: 30 }}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
              />
            </div>
          ) : null}
        </Card.Content>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
      credentials: state.global.credentials,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(InvoicesTable),
);

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  credentials: PropTypes.object.isRequired,
};
InvoicesTable.defaultProps = {};
