import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';

import MobileChart from '../../../components/MobileChart/MobileChart';
import PowerChart from '../../../components/PowerChart/PowerChart';

import styles from './styles.module.scss';

const ChartCard = ({ data, type, loading }) => {
  return (
    <div className={`ui card ${styles.cardContainer} ${styles[type]}`}>
      <div className={`content ${styles.cardContent}`}>
        {loading ? (
          <div className={styles.loaderContainer}>
            <Loader
              className={styles.loader}
              active
              size="medium"
              color="white"
            />
          </div>
        ) : (
          <>
            <div className={styles.cardHeader}>
              <div className={styles.left}>Forbruk</div>{' '}
              <div className={styles.right}>
                Totalt i {moment().subtract(1, 'months').format('MMMM')}{' '}
                <span className={styles.balance}>
                  {data.balance}
                  {data.currency}
                </span>
              </div>
            </div>
            <div className={styles.cardGraph}>
              {type === 'strøm' ? <PowerChart data={data.usage || []} /> : null}
              {type === 'mobil' ? (
                <MobileChart
                  data={data.usage || []}
                  transferTotal={data.transferTotal || 0}
                />
              ) : null}
            </div>
            <div className={styles.cardDetails}>
              <div className={styles.contentSeparator} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ChartCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

ChartCard.defaultProps = {
  loading: false,
};

export default ChartCard;
