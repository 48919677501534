import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { legalEntityAPI } from '../../../store/webapi/legalEntity';
import { setCredentials } from '../../../store/global/credentials';

import Home from './index';

class HomeContainer extends React.PureComponent {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  logUserIn(jwt) {
    const { actions } = this.props;
    if (!jwt) {
      return;
    }
    actions.setCredentials(jwt);

    const decodedJWT = jwtDecode(jwt);

    actions.dispatch(
      legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub }),
    );
  }

  render() {
    const { config, credentials } = this.props;
    const jwt = localStorage.getItem('brainSessionJwt');

    const { defaultLocale } = config;
    const location = document.location.pathname;
    const step = 'init_with_fixed_start';

    let flow = '';
    if (defaultLocale === 'sv_SE') {
      flow = location === '/login' ? 'generic_signup' : 'se_landing_page';
    }
    if (defaultLocale === 'nb_NO') {
      flow = location === '/login' ? 'generic_signup' : 'mobile_from_external';
    }
    if (jwt && !credentials.isAuthenticated) {
      this.logUserIn(jwt);
    }
    if (credentials.isAuthenticated && location === '/login') {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { from: '/' },
          }}
        />
      );
    }
    return <Home flow={flow} step={step} />;
  }
}

HomeContainer.propTypes = {
  credentials: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
HomeContainer.defaultProps = {};

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
    chat: state.global.bob.chatElements,
    config: state.global.config,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch),
    ),
  }),
)(HomeContainer);
