import React from 'react';
import PropTypes from 'prop-types';

import {Message, Icon} from 'semantic-ui-react';

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loader: true};
    this.setLoader = this.setLoader.bind(this);
    this.statusIcons = {
      success: 'checkmark',
      warning: 'warning',
      error: 'cancel'
    }
  }
  componentDidMount() {
    this.timer = setTimeout(this.props.onClose, this.props.timeout);
    this.loader = setTimeout(this.setLoader, this.props.timeout / 3);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  setLoader() {
    this.setState({loader: false});
  }
  alertClass(type) {
    const classes = {
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info',
      success: 'alert-success'
    };

    return classes[type] || classes.success;
  }

  render() {
    const message = this.props.message;
    const alertClassName = `alert ${this.alertClass(message.type)} fade in`;

    if (this.state.loader) {
      return (
        <Message icon info size="tiny">
          <Icon name="circle notched" />
          <Message.Content>
            <Message.Header> {message.loadingtext}</Message.Header>
          </Message.Content>
        </Message>
      );
    }

    return (
      <Message icon className={message.type} size="tiny">
        <Icon name={this.statusIcons[message.type] || 'checkmark'} />
        <Message.Content>
          <Message.Header> {message.text}</Message.Header>
        </Message.Content>
      </Message>
    );
  }
}

Alert.propTypes = {
  message: PropTypes.object.isRequired,
  timeout: PropTypes.number,
  onClose: PropTypes.func
};

Alert.defaultProps = {timeout: 6000};
