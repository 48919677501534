/**
 * WebAPI reducer for mobile.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import {combineReducers} from 'redux';

import config from '../../../config';
import {discardPaginationTransformer, responseDataTransformer} from '../transformers';

const webapi = reduxApi({
  contracts: {
    helpers: {
      get(id) {
        return [{id}, {}];
      },
      post(id, payload) {
        return [{id}, {body: JSON.stringify(payload), method: 'POST'}];
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.mobile.baseURI}/fenix/subscribers/:id/contracts/`
  },
  mobileContracts: {
    helpers: {
      get(id) {
        return [{id}, {}];
      },
      post(id, payload) {
        return [{}, {body: JSON.stringify(payload), method: 'POST'}];
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.mobile.baseURI}/contracts`
  },
  creditCheck: {
    helpers: {
      get(id, payload) {
        return [{id}, {payload}];
      }
    },
    transformer: responseDataTransformer,
    url: `${config.webapi.mobile.baseURI}/perform-credit-check`
  },
  invoices: {
    helpers: {
      get(id) {
        return [{id}, {}];
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.mobile.baseURI}/fenix/subscribers/:id/invoices/`
  },
  portationStatus: {
    helpers: {
      list() {
        return [{}, {}];
      },
      get(number) {
        return [{number}, {}];
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.mobile.baseURI}/fenix/portation-status/:number/`
  }
})
  .use('options', (url, params, getState) => {
    const {tokenType, accessToken} = getState().global.credentials;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (accessToken) {
      return {headers: {...headers, Authorization: `${tokenType || 'Bearer'} ${accessToken}`}};
    }

    return headers;
  })
  .use('fetch', adapterFetch(fetch));

export {webapi as mobileAPI};
export default combineReducers(webapi.reducers, {});
