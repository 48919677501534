import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import PageLoader from '../../../components/PageLoader/pageLoader';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import styles from '../content.module.scss';
import useWPContent from '../../../services/wpService/useWPContent';
import Page from '../../../components/Page/Page';

const MobilUserAgreement = () => {
  const { data, loading } = useWPContent('mobilUserAgreement');

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.custom_page_title || null} loading={loading}>
      <div className={styles.contentContainer}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>
                    {HtmlParse({
                      data: data.custom_page_title || '',
                    })}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.header}>
                  <div className={styles.title}>
                    {HtmlParse({
                      data: data.custom_page_title || '',
                    })}
                  </div>
                  <div className={styles.subtitle}>
                    {HtmlParse({
                      data: data.dataVersion || '',
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                {data.paragraphs ? (
                  <div>
                    {data.paragraphs.map((p) => (
                      <div key={p.title}>
                        <h2>
                          {HtmlParse({
                            data: p.title || '',
                          })}
                        </h2>
                        {p.text && p.text !== '' ? (
                          <div className={styles.text}>
                            {HtmlParse({
                              data: p.text || '',
                            })}
                          </div>
                        ) : null}
                        {p.subParagraphs && p.subParagraphs.length ? (
                          <div>
                            {p.subParagraphs.map((sp) => (
                              <div key={sp.title}>
                                <h3>
                                  {HtmlParse({
                                    data: sp.title || '',
                                  })}
                                </h3>
                                <div className={styles.text}>
                                  {HtmlParse({
                                    data: sp.text || '',
                                  })}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    ))}
                    <div className={styles.description}>
                      <p>
                        {HtmlParse({
                          data: data.companyName || '',
                        })}
                      </p>
                      <p>
                        {HtmlParse({
                          data: data.org || '',
                        })}
                      </p>
                      <p>
                        {HtmlParse({
                          data: data.address || '',
                        })}
                      </p>
                      <p>
                        {HtmlParse({
                          data: data.postalCode || '',
                        })}
                      </p>
                      <p className={styles.contactAnchor}>
                        <a href={`mailto:+ ${data.email}`}>
                          {HtmlParse({
                            data: data.email || '',
                          })}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default MobilUserAgreement;
