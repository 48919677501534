const SET = 'myHudya:gui:auth/SET'
const RESET = 'myHudya:gui:auth/RESET'

const inititalState = {
  otp: '',
  phoneNumber: '',
  phonePrefix: '+47',
  redirectURL: '/welcome'
}

export default function reducer(state = inititalState, action) {
  switch (action.type) {
    case RESET:
      return inititalState

    case SET: {
      const obj = state

      obj[action.payload.name] = action.payload.value

      return Object.assign({}, state, { ...obj })
    }
    default:
      return state
  }
}
export const resetAuthState = () => ({ type: RESET })
export const setAuthState = payload => ({ payload, type: SET })
