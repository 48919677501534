import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  Container,
  Dropdown,
  Form,
  Grid,
  Label
} from 'semantic-ui-react'
import Datetime from 'react-datetime'
import moment from 'moment'

import CustInput from '../../../components/CustInput'
import { accountAPI } from '../../../store/webapi/account'
import isValid from '../../../utils/validators'

// Hack to avoid eslint error:
const CardContent = Card.Content
const CardHeader = Card.Header
const CardMeta = Card.Meta
const FormField = Form.Field

const IS_ZERO = 0
const MINUS_ONE = 1

export default class PersonalInfo extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }
  static propTypes = {
    account: PropTypes.object.isRequired,
    accountAPIState: PropTypes.object.isRequired,
    accountState: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      dispatch: PropTypes.func,
      setBirthDate: PropTypes.func,
      setUser: PropTypes.func,
      toggleEditState: PropTypes.func
    }).isRequired
  }
  constructor(props, context) {
    super(props, context)
    this.state = {
      emailIsPristine: true,
      formIsPristine: true,
      nameIsPristine: true,
      phoneIsPristine: true
    }

    this.toggleEditMode = this.props.actions.toggleEditState.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }
  componentWillMount() {
    const USER = this.props.account

    if (Object.keys(USER).length === IS_ZERO) return

    this.props.actions.setUser({
      name: 'birthDate',
      value: USER.birthDate || ''
    })
    this.props.actions.setUser({
      name: 'email',
      value: USER.contactInfo.emails.find(email => email.kind === 'primary')
        .email
    })
    this.props.actions.setUser({ name: 'name', value: USER.fullName })
    this.props.actions.setUser({
      name: 'phone',
      value: USER.contactInfo.phoneNumbers.find(
        number => number.kind === 'primary'
      ).number
    })
    this.props.actions.setUser({
      name: 'phonePrefix',
      value: USER.contactInfo.phoneNumbers.find(
        number => number.kind === 'primary'
      ).prefix
    })
  }

  handleSaveClick() {
    const { xgettext } = this.context
    const { account } = this.props
    const { dispatch } = this.props.actions

    window.flashMessages.addMessage({
      id: 'id',
      loadingtext: xgettext(
        'Updating...',
        'Loading text save action on global flash message.'
      ),
      text: xgettext(
        'Your changes were saved!',
        'Success message for save action on global flash message.'
      ),
      type: 'success'
    })
    const names = this.props.accountState.user.name.split(/[\s,]+/)
    const lname = names.pop()
    const fname = names.shift()
    const mname = names.join(' ')
    const payload = {
      emailPrimary: this.props.accountState.user.email,
      firstName: fname,
      lastName: lname,
      middleName: mname,
      phoneNumber:
        this.props.accountState.user.phonePrefix +
        this.props.accountState.user.phone
    }

    // this.props.accountState.user.birthDate === '' ? null : (payload.birthDate = this.props.accountState.user.birthDate);

    if (this.props.accountState.user.birthDate !== '') {
      payload.birthDate = this.props.accountState.user.birthDate
    }
    dispatch(accountAPI.actions.account.patch(account.id, payload))
    this.toggleEditMode()
  }
  handleInputChange(event, value) {
    // check if input is from Birthdatefield
    if (moment.isMoment(event)) {
      this.props.actions.setBirthDate(event)
      this.setState({ formIsPristine: false })

      return
    }

    // guard against illeagal input
    if (typeof value === 'undefined') {
      return
    }

    const name = `${value.name}IsPristine`
    const stateCopy = this.state

    // update form pristine state
    stateCopy[name] = false
    stateCopy.formIsPristine = false
    this.setState(stateCopy)

    const primaryAddress =
      this.props.account.contactInfo.addresses.find(
        address => address.kind === 'primary'
      ) || {}

    this.props.actions.setUser({ name: value.name, value: value.value })
    this.forceUpdate()
  }

  render() {
    const { gettext, xgettext } = this.context
    const { loading } = this.props.accountAPIState.account
    const account = this.props.accountState.user
    const dateParts = account.birthDate.split('/')

    const birthDateFormated = new Date(
      dateParts[2],
      dateParts[1] - MINUS_ONE,
      dateParts[0]
    )
    const formIsValid =
      isValid('required', account.name) &&
      isValid('phoneNumber', account.phone) &&
      isValid('email', account.email) &&
      !this.state.formIsPristine

    return (
      <Container>
        <Card fluid>
          <CardContent extra>
            <CardHeader>{gettext('Personal information')}</CardHeader>
            <CardMeta>
              {xgettext(
                'We suggest that you also set your birth date, since this will make setting up some products easier.',
                'Text displayed underneath the personal info editing card in the Account page'
              )}
            </CardMeta>
          </CardContent>
          <CardContent>
            <Form error={formIsValid} size="large">
              <Grid stackable columns="two" verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column>
                    <FormField>
                      <CustInput
                        fluid
                        required
                        error={
                          !this.state.nameIsPristine &&
                          !isValid('required', account.name)
                        }
                        hintText={xgettext(
                          'Well, we can not call you Mr Anonymous, right?',
                          'Hint text for the Full name field in the Edit Personal info card in the Account page'
                        )}
                        labelText={gettext('Full name')}
                        name="name"
                        placeholder={gettext('First, middle and last name')}
                        value={account.name}
                        onChange={this.handleInputChange}
                      />
                      <span
                        hidden={
                          this.state.nameIsPristine
                            ? true
                            : isValid('required', account.name)
                        }
                      >
                        <Label basic pointing color="red">
                          {gettext('Please type your full name')}
                        </Label>
                      </span>
                    </FormField>
                  </Grid.Column>
                  <Grid.Column>
                    {account.birthDate === '' ? (
                      <FormField style={{ marginBottom: '1em' }}>
                        <CustInput
                          closeOnSelect
                          control={Datetime}
                          dateFormat="DD/MM/YYYY"
                          hintText={xgettext(
                            'Your birth date helps us improve the process of setting up some products.',
                            'Hint text for the Birth date field in the Edit Personal info card in the Account page'
                          )}
                          labelText={gettext('Birth date')}
                          timeFormat={false}
                          viewMode="years"
                          onChange={this.handleInputChange}
                        />
                        <span hidden={account.birthDateFormated !== ''}>
                          <Label basic pointing color="red">
                            {gettext('Please select your birth date')}
                          </Label>
                        </span>
                      </FormField>
                    ) : (
                      <FormField style={{ marginBottom: '1em' }}>
                        <CustInput
                          closeOnSelect
                          control={Datetime}
                          dateFormat="DD/MM/YYYY"
                          hintText={xgettext(
                            'Your birth date helps us improve the process of setting up some products.',
                            'Hint text for the Birth date field in the Edit Personal info card in the Account page'
                          )}
                          labelText={gettext('Birth date')}
                          timeFormat={false}
                          value={new Date(birthDateFormated)}
                          viewMode="years"
                          onChange={this.handleInputChange}
                        />
                      </FormField>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {/* <Grid.Column>
                    <FormField>
                      <CustInput
                        disabled
                        required
                        action={
                          <Dropdown
                            basic
                            button
                            name="phonePrefix"
                            onChange={this.handleInputChange}
                            options={this.props.accountState.phonePrefixes}
                            value={account.phonePrefix} />
                        }
                        actionPosition="left"
                        error={!this.state.phoneIsPristine && !isValid('phoneNumber', account.phone)}
                        hintText={xgettext(
                          'We ask for a mobile number so we can verify your identity by sending you codes by SMS.',
                          'Hint text for the Mobile number field in the Edit Personal info card in the Account page'
                        )}
                        labelText={gettext('Mobile number')}
                        name="phone"
                        placeholder="99999999"
                        value={account.phone}
                        onChange={this.handleInputChange} />
                      <span hidden={this.state.phoneIsPristine ? true : isValid('phoneNumber', account.phone)}>
                        <Label basic pointing color="red">
                          {gettext('This does not seem to be a valid mobile number')}
                        </Label>
                      </span>
                    </FormField>
                    <div className="ui message small">
                      {xgettext(
                        'Editing your mobile number is temporarily disabled. Please contact us if you need to change it.',
                        'Text explaining why customer can not edit phone or mobile number in the Personal info edit page'
                      )}
                    </div>
                  </Grid.Column> */}
                  <Grid.Column>
                    <FormField>
                      <CustInput
                        required
                        error={
                          !this.state.emailIsPristine &&
                          !isValid('email', account.email)
                        }
                        hintText={xgettext(
                          'Your e-mail address is kept secure and is never shared to other companies. We hate spam!',
                          'Hint text for the e-mail field in the Edit Personal info card in the Account page'
                        )}
                        labelText={gettext('E-mail address')}
                        name="email"
                        placeholder="john@doe.com"
                        value={account.email}
                        onChange={this.handleInputChange}
                      />
                      <span
                        hidden={
                          this.state.emailIsPristine
                            ? true
                            : isValid('email', account.email)
                        }
                      >
                        <Label basic pointing color="red">
                          {gettext(
                            'This does not seem to be a valid e-mail address.'
                          )}
                        </Label>
                      </span>
                    </FormField>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </CardContent>
          <CardContent extra>
            <div>
              <Button
                primary
                disabled={!formIsValid}
                loading={loading}
                onClick={this.handleSaveClick}
              >
                {gettext('Update personal information')}
              </Button>
              <Button basic onClick={this.toggleEditMode}>
                {gettext('Cancel')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    )
  }
}
