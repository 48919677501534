import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import {withRouter} from 'react-router-dom';
import MarkdownRenderer from 'react-markdown-renderer';

class ExtraContent extends React.Component {
  static contextTypes = {gettext: PropTypes.func.isRequired};

  render() {
    if (!this.props.field) {
      return null;
    }
    const {gettext} = this.context;

    return (
      <div className="chat-help">
        <div style={{overflow: 'auto', height: '80%'}}>
          <MarkdownRenderer markdown={this.props.field.tipsText} />
        </div>
        <br />
        <Button circular inverted onClick={this.props.handleHelpButtonClick}>
          {gettext('Ok, jeg forstår')}
        </Button>
      </div>
    );
  }
}
ExtraContent.propTypes = {
  field: PropTypes.object.isRequired,
  handleHelpButtonClick: PropTypes.func.isRequired
};
export default withRouter(ExtraContent);
