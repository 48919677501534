import pofile from 'pofile';

/**
 * Transform the downloaded PO file to a format
 * `react-gettext` understands.
 */
export const po2jsonTransformer = (response) => {
  if (typeof response === 'undefined') {
    return {};
  }

  const messages = {};
  const parsedFile = pofile.parse(response.content);

  parsedFile.items.forEach((item) => {
    const translationKey = item.msgctxt ? `${item.msgctxt}\u0004${item.msgid}` : item.msgid;
    let value = [item.msgid_plural ? item.msgid_plural : null].concat(item.msgstr).filter(x => (x !== null));
    if (value.length === 1) {
      value = value[0]
    }
    messages[translationKey] = value;
  });

  return messages;
};
