import React, { useState } from 'react';
import { Container, Grid, Icon, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import contentStyles from '../../scenes/Content/content.module.scss';
import PageLoader from '../PageLoader/pageLoader';
import useGetPostByCategory from '../../hooks/gql/GetPostByCategory.query';

import styles from './styles.module.scss';

const FaqHelper = ({ type }) => {
  const { data, loading } = useGetPostByCategory(type);
  const [search, setSearch] = useState('');

  const prepareData = () => {
    if (data && data.length && search !== '' && search.length > 2) {
      return data.filter((a) =>
        a.title.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return data;
  };

  const changeTitleToUrl = (post) => {
    return `/${type}/${post.slug}`;
  };
  return loading ? (
    <div className={contentStyles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <div
      className={`${contentStyles.contentContainer} ${contentStyles.darkContainer} ${styles.faqHelper}`}
    >
      {data.length ? (
        <div>
          <Container>
            <Grid centered>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={12}
                  style={{ padding: '0px' }}
                >
                  <div
                    className={`${contentStyles.header} ${contentStyles.centered}`}
                    style={{ marginBottom: 0 }}
                  >
                    <h2 className={contentStyles.faqTitle}>
                      Ofte stilte spørsmål
                    </h2>
                    {/* <div className={contentStyles.subtitle}>Spør spørsmål</div> */}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container>
            <Grid centered>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  style={{ padding: '0px' }}
                >
                  <div
                    className={`${contentStyles.header} ${contentStyles.centered}`}
                  >
                    <div className="chat-input-group chat-input-appear-done chat-input-enter-done">
                      <div className="chat-input">
                        <div
                          inputMode="text"
                          className={`${styles.input} ui large labeled input`}
                        >
                          <button
                            type="submit"
                            className="ui button label"
                            style={{ width: 48 }}
                          >
                            <Icon name="search" />
                          </button>
                          <Input
                            pattern="*"
                            type="text"
                            placeholder="Søk..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container>
            {prepareData().map((a) => (
              <a href={changeTitleToUrl(a)} key={a.id}>
                <div className={styles.card}>
                  <h3>{a.title}</h3>
                </div>
              </a>
            ))}
          </Container>
        </div>
      ) : null}
    </div>
  );
};

FaqHelper.propTypes = {
  type: PropTypes.string,
};

FaqHelper.defaultProps = {
  type: 'mobile',
};

export default FaqHelper;
