import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetMobileQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getMobile {
        res: pageBy(uri: "mobile-landing-page") {
          status
          content
          contentData: mobileLandingPage {
            chatH2: chath2
            rating
            testimonials
            title
            whyWeTitle: whywetitle
            whyWeLeftList: whyweleftlist {
              id
              text
            }
            whyWeRightList: whywerightlist {
              id
              text
            }
            whyWeContent: whywecontent
            chatMessage: chatmessage
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    chatH2: '',
    rating: 0,
    testimonials: '',
    title: 'Mobil',
    whyWeTitle: '',
    whyWeLeftList: [],
    whyWeRightList: [],
    chatMessage: '',
    whyWeContent: null,
    content: '',
  };

  return { loading, error, data };
};

export default useGetMobileQuery;
