import React from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const PageLoader = ({ fill, loading }) => {
  const getLoader = () => <Loader active className={styles.loader} />;

  return loading ? (
    fill ? (
      <div className={styles.fill}>{getLoader()}</div>
    ) : (
      getLoader()
    )
  ) : null;
};

PageLoader.propTypes = {
  fill: PropTypes.bool,
  loading: PropTypes.bool,
};

PageLoader.defaultProps = {
  fill: false,
  loading: false,
};

export default PageLoader;
