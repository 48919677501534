import { useContext } from 'react';

import { WPContext } from './wpService';

export default function useWPService() {
  const context = useContext(WPContext);

  if (context === undefined) {
    throw new Error('useWPService must be used within an WPProvider');
  }

  return context;
}
