export default function isValid(kind, value) {
  switch (kind) {
    case 'email':
      return validateEmail(value);
    case 'phoneNumber':
      return validatePhoneNumber(value);
    case 'required':
      return required(value);
    case 'postCode':
      return validatePostCode(value);
    default:
      return false;
  }
}
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

function validatePhoneNumber(number) {
  const MIN_PHONE_NUMBER_LENGTH = 8;

  return number.length >= MIN_PHONE_NUMBER_LENGTH && (/^\d*$/).test(number);
}
function required(value) {
  return value !== '';
}
function validatePostCode(value) {
  const POSATAL_CODE_LENGTH = 4;

  return value.length === POSATAL_CODE_LENGTH && (/^\d*$/).test(value);
}
