import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import 'react-circular-progressbar/dist/styles.css';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import styles from './styles.module.scss';

class PowerDetailsCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { gettext } = this.context;
    const { data } = this.props;
    return (
      <Card fluid className={styles.mobileCard}>
        <div className={`content ${styles.content}`}>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Målepunkt ID')}</div>
                <div className={`${styles.value} ${styles.colored}`}>
                  {data.subscriptionId}
                </div>
              </div>
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>
                  {gettext('Forbruk i')}{' '}
                  <span className={styles.capitalize}>
                    {moment().subtract(1, 'months').format('MMMM')}
                  </span>
                </div>
              </div>
              {/* <div className={`${styles.valueContainer} ${styles.valueRight}`}> */}
              {/*  <div className={styles.label}> */}
              {/*    {data.rate} {data.per} */}
              {/*  </div> */}
              {/* </div> */}
            </div>
          </div>
          <div className={styles.subscription}>
            <div className={styles.progress}>
              <div style={{ width: 120, height: 120 }}>
                <CircularProgressbarWithChildren
                  value={0}
                  strokeWidth={2}
                  styles={buildStyles({
                    pathColor: '#ff6900',
                    trailColor: 'rgba(255,105,0,0.61)',
                  })}
                >
                  <div className={styles.used}>{data.monthUsage} kWh</div>
                  <div className={styles.usedDesc}>{gettext('brukt')} </div>
                  <img
                    src="/assets/icons/power.svg"
                    alt="power icon"
                    className={styles.icon}
                  />
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Avtaler')}</div>
                <div className={`${styles.value}`}>{data.subscriptionName}</div>
              </div>
              {/* <div className={styles.valueContainer}> */}
              {/*  <div className={styles.label}>Forbruk</div> */}
              {/*  <div className={styles.value}> */}
              {/*    <b> */}
              {/*      {data.balance}{' '} */}
              {/*      <span className={styles.currency}>{data.currency}</span> */}
              {/*    </b> */}
              {/*  </div> */}
              {/* </div> */}
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Adresse')}</div>
                <div className={styles.value}>{data.address.street}</div>
              </div>
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Adresse')}</div>
                <div className={styles.value}>
                  {data.address.postalCode} - {data.address.place}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(PowerDetailsCard),
);

PowerDetailsCard.propTypes = {};
PowerDetailsCard.defaultProps = {};
