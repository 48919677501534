import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './styles.module.scss';

class TitleSection extends React.Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    type: PropTypes.string.isRequired,
  };

  render() {
    const { type } = this.props;
    return (
      <div className={`${styles.titleSection}`}>
        <p className={styles.title}>
          Velkommen til <span className={styles.type}>{type}</span>
        </p>
        <p className={styles.description}>
          Find everything you need in one place
        </p>
      </div>
    );
  }
}
export default withRouter(TitleSection);
