import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, Modal, Header, Popup, Icon } from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import Chat from '../../../../components/Chat';
import store from '../../../../store/configureStore';

import styles from './styles.module.scss';

class PowerCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  render() {
    const { gettext } = this.context;
    const { subscriptions, flow, flowStep, history, lastUpdated } = this.props;
    return (
      <Card fluid className={styles.mobileCard}>
        <div className={`content ${styles.content}`}>
          <div className={styles.header}>
            {gettext('Strøm')}
            {lastUpdated ? (
              <span className={styles.infoIcon}>
                <Popup
                  content={`${gettext('Sist oppdatert:')} ${moment(lastUpdated)
                    .format('MMMM DD YYYY')
                    .capitalize()}`}
                  trigger={<Icon circular name="info" size="mini" />}
                />
              </span>
            ) : null}
          </div>
          <Scrollbars style={{ width: '100%', height: 320 }}>
            <div className={styles.subscriptionsContainer}>
              {subscriptions.map((s) => (
                <div className={styles.subscription} key={s.id}>
                  <div className={styles.postalCode}>
                    <span
                      className={`${styles.trim} ${styles.code}`}
                      title={s.subscriptionName}
                    >
                      {s.subscriptionName}
                    </span>
                  </div>
                  <div className={`${styles.trim} ${styles.detailsContainer}`}>
                    <div
                      className={`${styles.trim} ${styles.address}`}
                      title={`${s.address.street.toLowerCase()} ${
                        s.address.postalCode
                      } ${s.address.place}`}
                    >
                      <span className={styles.colored}>
                        {s.address.street
                          ? s.address.street.toLowerCase()
                          : '-'}
                      </span>{' '}
                      <span className={styles.address2}>
                        {s.address.postalCode} {s.address.place}
                      </span>
                    </div>
                  </div>
                  {/* <div className={styles.spend}> */}
                  {/*  <span className={`${styles.val} ${styles.trim}`}> */}
                  {/*    <span className={styles.fullText}> */}
                  {/*      {gettext('Målepunkt ID:')}{' '} */}
                  {/*    </span> */}
                  {/*    <span className={styles.shortText}> */}
                  {/*      {gettext('MID:')}{' '} */}
                  {/*    </span> */}
                  {/*    <b> {s.subscriptionId}</b> */}
                  {/*  </span> */}
                  {/* </div> */}
                </div>
              ))}
            </div>
          </Scrollbars>
          <div className={styles.footer}>
            <Button
              size="big"
              style={{
                color: '#ffffff',
                fontWeight: 600,
                borderRadius: 30,
                background: 'linear-gradient(225deg, #fb637e 0%, #ff6900 100%)',
              }}
              onClick={() => history.push('/products/details/power')}
            >
              {gettext('Se dine avtaler')}
            </Button>
            <Modal
              trigger={
                <Button
                  size="big"
                  style={{
                    color: '#ffffff',
                    fontWeight: 600,
                    borderRadius: 30,
                    background: '#3f2a56',
                  }}
                >
                  {gettext('Bestill')}
                </Button>
              }
              basic
              size="tiny"
              closeIcon
              // defaultOpen
            >
              <Header />
              <Modal.Content style={{ minHeight: 1500 }}>
                <div style={{ minHeight: 900 }}>
                  <Chat goalFlow={flow} step={flowStep} store={store} />
                </div>
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(PowerCard),
);

PowerCard.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  flow: PropTypes.string.isRequired,
  flowStep: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  lastUpdated: PropTypes.any,
};
PowerCard.defaultProps = {
  subscriptions: [],
  lastUpdated: null,
};
