import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import PageLoader from '../../../components/PageLoader/pageLoader';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import styles from '../content.module.scss';
import Page from '../../../components/Page/Page';
import useGetInsuranceDataPolicyPageQuery from '../../../hooks/gql/landings/insuranceDataPolicy/GetInsuranceDataPolicyPage.query';

const InsuranceDataPolicy = () => {
  const { data, loading } = useGetInsuranceDataPolicyPageQuery();

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title || null} loading={loading}>
      <div className={styles.contentContainer}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <a href="/forsikring" className={styles.link}>
                    Forsikring
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>{data.title || ''}</span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.header}>
                  <div className={styles.title}>{data.title || ''}</div>
                  <div className={styles.subtitle} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                {HtmlParse({
                  data: data.content || '',
                })}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default InsuranceDataPolicy;
