/**
 * WebAPI reducer for Transifex services.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import {combineReducers} from 'redux';

import config from '../../../config';
import {po2jsonTransformer} from './transformers';


const webapi = reduxApi({
  translation: {
    transformer: po2jsonTransformer,
    url: `${config.webapi.transifex.baseURI}/project/${config.webapi.transifex.projectSlug}`
         + `/resource/${config.webapi.transifex.resourceSlug}/translation/:locale/`
         + `?mode=${config.webapi.transifex.mode}`
  }
})
  .use('options', (url, params, getState) => {
    const headers = {
      Accept: 'text/po',
      Authorization: `Basic ${btoa(`api:${config.webapi.transifex.accessToken}`)}`,
      'Content-Type': 'text/po'
    };

    return {headers};
  })
  .use('fetch', adapterFetch(fetch));

export {webapi as transifexAPI};
export default combineReducers(webapi.reducers, {});
