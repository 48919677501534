import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Alert from './Alert';

export default class GlobalMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {messages: props.messages};

    window.flashMessages = this;
  }

  addMessage(message) {
    const messages = update(this.state.messages, {$push: [message]});

    this.setState({messages});
  }

  removeMessage(message) {
    const index = this.state.messages.indexOf(message);
    const messages = update(this.state.messages, {$splice: [[index, 1]]});

    this.setState({messages});
  }

  render() {
    if (this.state.messages.length === 0) return null;

    return (
      <div className="fixed-message">
        <div className="ui container center aligned">
          {this.state.messages.map((message) => (
            <Alert key={message.id} message={message} onClose={() => this.removeMessage(message)} />
          ))}
        </div>
      </div>
    );
  }
}

GlobalMessage.propTypes = {messages: PropTypes.array.isRequired};
