import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import {combineReducers} from 'redux';

import config from '../../../config';

const legalEntity = reduxApi({
  get: {
    options(url, params, getState) {
      const TOKEN = getState().core.auth.login.data.token;

      return {
        headers: {
          Accept: 'application/json',
          Authorization: `$Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
        }
      };
    },
    url: `${config.coreApi.baseURI}${config.coreApi.legalEntityURI}:id/`
  },
  patch: {
    options(url, params, getState) {
      const TOKEN = getState().core.auth.login.data.token;

      return {
        headers: {
          Accept: 'application/json',
          Authorization: `$Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
        },
        method: 'patch'
      };
    },
    url: `${config.coreApi.baseURI}${config.coreApi.legalEntityURI}:id/`
  }
}).use('fetch', adapterFetch(fetch));

export {legalEntity as legalEntityAPI};

export function resetLegalEntityState() {
  return () =>
    Promise.all([

      //  dispatch(webapi.actions.phoneLookup.reset()),
    ]);
}

export default combineReducers(legalEntity.reducers, {});
