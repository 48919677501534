import moment from 'moment'
import { transifexAPI } from '../../../store/webapi/transifex'
//import { accountAPI } from '../../../store/webapi/account'
import { featuretogglesAPI } from '../../webapi/featuretoggles'

const RESET = 'stuart:global:config/RESET'
const SET_DEFAULT_LOCALE = 'stuart:global:config/SET_DEFAULT_LOCALE'
const TOGGLE_DISPLAY_SPLASH = 'stuart:global:config/TOGGLE_DISPLAY_SPLASH'
const SPLASH_DONE = 'stuart:global:config/SPLASH_DONE'

const inititalState = {
  defaultLocale: 'nb_NO',
  defaultPrefix: '+47',
  featuretoggles: [],
  phonePrefixes: [
    { key: '+45', text: '+45', value: '+45' },
    { key: '+46', text: '+46', value: '+46' },
    { key: '+47', text: '+47', value: '+47' },
  ],
  displaySplash: false,
  splashDone: false,
  activeProducts: {
    mobile: {
      nb_NO: true,
      sv_SE: false,
      da_DK: false,
    },
    power: {
      nb_NO: false,
      sv_SE: true,
      da_DK: true,
    },
    refinancing: {
      nb_NO: true,
      sv_SE: false,
      da_DK: false,
    },
    insurance: {
      nb_NO: true,
      sv_SE: false,
      da_DK: false,
    },
    customerService: {
      nb_NO: true,
      sv_SE: true,
      da_DK: true,
    },
    corporate: {
      nb_NO: true,
      sv_SE: false,
      da_DK: false,
    },
    blog: {
      nb_NO: false,
      sv_SE: false,
      da_DK: true,
    },
  },

  urlLocaleMap: {
    power: {
      nb_NO: '/strom',
      sv_SE: '/el',
      da_DK: '/el',
    },
    customerService: {
      nb_NO: '/kundeservice/',
      sv_SE: '/kundservice',
      da_DK: '/kundeservice',
    },
    blog: {
      nb_NO: '/blogg/',
      sv_SE: '/',
      da_DK: '/hjem-2018/blog/',
    },
  },
}

export default function reducer(state = inititalState, action) {
  switch (action.type) {
    case RESET:
      return inititalState
    case SET_DEFAULT_LOCALE:
      if (['da_DK', 'en_US', 'nb_NO', 'sv_SE'].includes(action.payload)) {
        return Object.assign({}, state, { defaultLocale: action.payload })
      }

      return state
    case featuretogglesAPI.events.featuretoggles.actionSuccess:
      state.featuretoggles = action.data

      return Object.assign({}, state)
    case TOGGLE_DISPLAY_SPLASH:
      return Object.assign({}, state, { displaySplash: !state.displaySplash })
    case SPLASH_DONE:
      return Object.assign({}, state, { splashDone: true })

    default:
      return state
  }
}

export const setDefaultLocale = (payload, saveSettings = true) => {
  // convert dashes to underscore if any
  if (typeof payload === 'string') {
    payload = payload.replace(/-/g, '_')
  }
  const setDefaultLocaleAction = {
    payload,
    type: SET_DEFAULT_LOCALE,
  }

  return (dispatch, getState) => {
    Promise.resolve(dispatch(setDefaultLocaleAction))
      .then(
        dispatch(
          transifexAPI.actions.translation.sync({
            locale: getState().global.config.defaultLocale,
          })
        )
      )
      .then(() => {
        if (
          saveSettings &&
          getState().global.credentials.isAuthenticated &&
          payload
        ) {
          const locale = payload.replace(/_/g, '-')
          moment.locale(locale)

          //replace with call to legal entity???
          // dispatch(
          //   accountAPI.actions.account.patch(
          //     getState().global.entities.account.id,
          //     { locale: locale }
          //   )
          // )
        }
      })
  }
}
export const toggleDisplaySplash = () => {
  const toggle = { type: TOGGLE_DISPLAY_SPLASH }
  const splashDone = { type: SPLASH_DONE }

  return (dispatch, getState) => {
    if (getState().global.config.splashDone) {
      return
    }
    dispatch(toggle)

    setTimeout(() => {
      dispatch(toggle)
      dispatch(splashDone)
    }, 1000)
  }
}
