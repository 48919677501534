import React from 'react';
import PropTypes from 'prop-types';
import {fm as _} from '../utils/string';
import Conversation from './Conversation';

export default class KitchenSink extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    ngettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
    nxgettext: PropTypes.func.isRequired
  };

  render() {
    const {gettext, xgettext, ngettext, nxgettext} = this.context;
    const num = 1;

    return (
      <div style={{minHeight: 500}}>
        <Conversation />
      </div>
    );
  }
}
