export function checkSuccess(statePath) {
  /**
   * Returns a boolean indicating if the given `statePath` has
   * returned a successful response or not.
   * @param statePath: Dotted path to the `redux-api` state to check.
   *                   For example: 'webapi.auth.login'.
   * @returns boolean.
   */
  const store = require('../configureStore').default;
  const state = statePath.split('.').reduce((obj, index) => obj[index], store.getState());

  if (typeof state === 'undefined') {
    return false;
  }

  return state.sync && state.error === null;
}
