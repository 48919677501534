import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import PageLoader from '../PageLoader/pageLoader';
import HtmlParse from '../HtmlParser/htmlParser';
import useWPContent from '../../services/wpService/useWPContent';

import styles from './styles.module.scss';

const ProductDescriptionContent = ({ logo, page }) => {
  const { data, loading } = useWPContent(page);

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Container>
      <div className={styles.contentContainer}>
        {logo ? (
          <img
            src="assets/Logo_White_Blue.svg"
            alt="logo"
            className={styles.logo}
          />
        ) : null}
        <h2>
          {HtmlParse({
            data: data.contentTitle || '',
          })}
        </h2>
        <div className={styles.description}>
          {HtmlParse({
            data: data.description || '',
          })}
        </div>
        <div className={styles.content}>
          {HtmlParse({
            data: data.content || '',
          })}
        </div>
      </div>
    </Container>
  );
};

ProductDescriptionContent.propTypes = {
  page: PropTypes.string.isRequired,
  logo: PropTypes.bool,
};
ProductDescriptionContent.defaultProps = {
  logo: false,
};

export default ProductDescriptionContent;
