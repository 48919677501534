/**
 * WebAPI reducer for vipps Login.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

//import { accountAPI } from '../account'
import { legalEntityAPI } from '../legalEntity'

import { checkSuccess } from '../utils'
import config from '../../../config'
import { responseDataTransformer } from '../transformers'
import { setDefaultLocale } from '../../global/config'

const originMap = {
  local: 'localhost',
  sandbox: 'myhudya-sandbox-no',
  dev: 'myhudya-dev-no',
  staging: 'myhudya-staging-no',
  prod: 'myhudya-prod-no'
}
const webapi = reduxApi({
  login: {
    helpers: {
      get(key) {
        return [{ key }, {}]
      },
      patch(id, payload) {
        return [{ id }, { body: JSON.stringify(payload), method: 'PATCH' }]
      }
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        if (!checkSuccess('webapi.account.account')) {
          const accountId = getState().global.credentials.id

          Promise.resolve(
            // dispatch(accountAPI.actions.account.sync({ id: accountId }))
            dispatch(legalEntityAPI.actions.entity.sync({ id: accountId }))
          ).then(response => {
            dispatch(setDefaultLocale(response.locale))
          })
        }
      }
    ],
    transformer: responseDataTransformer,
    url: `${config.webapi.auth.baseURI}/vipps-login/?key`
  },
  loginUrl: {
    helpers: {
      get(id) {
        return [{ id }, {}]
      }
    },

    transformer: responseDataTransformer,
    url: `${config.webapi.auth.baseURI}/vipps-login/?origin=${
      originMap[config.RUNTIME_ENV]
    }:id`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as vippsAuthAPI }
export default combineReducers(webapi.reducers, {})
