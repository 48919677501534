import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Grid} from 'semantic-ui-react';

import PersonalInfoEdit from './PersonalInfoEdit';

// Hack to avoid eslint error:
const CardContent = Card.Content;
const CardHeader = Card.Header;
const CardMeta = Card.Meta;

const Column = Grid.Column;
const Row = Grid.Row;

export default class PersonalInfo extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired
  };

  static propTypes = {
    account: PropTypes.object.isRequired,
    accountAPIState: PropTypes.object.isRequired,
    actions: PropTypes.shape({toggleEditState: PropTypes.func}).isRequired,
    isEditMode: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.toggleEditMode = this.props.actions.toggleEditState.bind(this);
  }

  render() {
    const {gettext} = this.context;
    const {account, isEditMode} = this.props;
    const primaryPhone
      = account.contactInfo.phoneNumbers.find((number) => number.kind === 'primary') || {};
    const primaryEmail
      = (account.contactInfo
        && account.contactInfo.emails.find((email) => email.kind === 'primary'))
      || {};

    if (isEditMode) {
      return <PersonalInfoEdit {...this.props} />;
    }

    return (
      <div>
        <Card fluid>
          <CardContent extra>
            <CardHeader>{gettext('Personal information')}</CardHeader>
            <CardMeta />
          </CardContent>
          <CardContent>
            <Grid stackable columns="2">
              <Row>
                <Column>
                  <label style={{fontWeight: 'bold'}}>{gettext('Full name')}</label>
                  <p>{account.fullName}</p>
                </Column>
                <Column>
                  <label style={{fontWeight: 'bold'}}>{gettext('Mobile number')}</label>
                  <p> {primaryPhone.number}</p>
                </Column>
              </Row>
              <Row>
                <Column>
                  <label style={{fontWeight: 'bold'}}>{gettext('E-mail address')}</label>
                  <p> {primaryEmail.email}</p>
                </Column>
                <Column>
                  <span hidden={!account.birthDate}>
                    <label style={{fontWeight: 'bold'}}>{gettext('Birth date')}</label>
                    <p> {account.birthDate}</p>
                  </span>
                </Column>
              </Row>
            </Grid>
          </CardContent>
          <CardContent extra>
            <div>
              <Button basic primary onClick={this.toggleEditMode}>
                {gettext('Edit personal information')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
