import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import styles from './styles.module.scss';
import PowerDetails from './components/PowerDetails/PowerDetails';
import MobileDetails from './components/MobileDetails/MobileDetails';

const DetailsCard = ({ data, type, loading }) => {
  return (
    <div className={`ui card ${styles.cardContainer} ${styles[type]}`}>
      {loading || !data ? (
        <div className={styles.cardContent}>
          <div className={styles.loaderContainer}>
            <Loader
              className={styles.loader}
              active
              size="medium"
              color="white"
            />
          </div>
        </div>
      ) : (
        <div>
          {type === 'strøm' ? <PowerDetails data={data || {}} /> : null}
          {type === 'mobil' ? (
            <MobileDetails data={data || {}} type={type} />
          ) : null}
        </div>
      )}
    </div>
  );
};

DetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

DetailsCard.defaultProps = {
  loading: false,
};

export default DetailsCard;
