import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Tab } from 'semantic-ui-react';

import styles from './styles.module.scss';
import TopSaleSection from './components/TopSaleSection';
import panesNoFlex from './components/DataTables/noFlex';
import panesNoFlexBrand from './components/DataTables/noFlexBrand';

const MoreInfo = ({ name, moreText }) => {
  const pane = name === 'Mobilt bredbånd' ? panesNoFlexBrand : panesNoFlex;
  return (
    <Modal
      trigger={
        <button type="button" className={styles.moreButton}>
          {moreText}
        </button>
      }
      size="large"
      closeIcon
    >
      <Header icon="info circle" content={name} color="violet" />
      <Modal.Content>{TopSaleSection[name]} </Modal.Content>
      <Modal.Content style={{ minHeight: 1500 }}>
        <Tab
          style={{ minHeight: 900 }}
          menu={{
            attached: false,
            tabular: false,
            stackable: true,
          }}
          panes={pane}
        />
      </Modal.Content>
    </Modal>
  );
};

MoreInfo.propTypes = {
  moreText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
MoreInfo.defaultProps = {};

export default MoreInfo;
