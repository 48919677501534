import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown/with-html';

import Chat from '../Chat';

import styles from './chatContainer.module.scss';

const ChatContainer = ({
  columns,
  chatH1,
  chatH2,
  clientKnowledge,
  flow,
  step,
  firstMessage,
  chatText,
  centered,
}) => {
  return (
    <header className={styles.header}>
      <Container>
        <Grid stackable centered={centered}>
          <Grid.Row>
            {columns ? null : (
              <Grid.Column width="2" style={{ marginTop: 15 }} />
            )}
            <Grid.Column width={columns || 12} style={{ marginTop: 15 }}>
              {chatH1 && (
                <h1 style={{ marginTop: -30 }} className={styles.head}>
                  <ReactMarkdown source={chatH1} escapeHtml={false} />
                </h1>
              )}
              {chatH2 && (
                <h2 style={{ marginTop: -30 }} className={styles.head2}>
                  <ReactMarkdown source={chatH2} escapeHtml={false} />
                </h2>
              )}
              <Chat
                clientKnowledge={clientKnowledge}
                goalFlow={flow}
                step={step}
                firstMessage={firstMessage}
              />
              {flow === 'debt_check' && (
                <div className={styles.disc}>
                  {chatText && chatText !== ''
                    ? chatText
                    : 'Hver bank har sin prismodell. Prisene varierer mellom bankene og fra kunde til kunde. Priseksempel: 65.000,- over 5 år, effektiv rente 14 %, kost. 24.093,-, tot.kost. 89.093,-. Laveste nominelle rente 5,95 %. Høyeste nom. rente 22 %.'}
                </div>
              )}
              {flow !== 'debt_check' && chatText ? (
                <div className={styles.disc}>{chatText}</div>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </header>
  );
};
ChatContainer.propTypes = {
  columns: PropTypes.number,
  chatH1: PropTypes.string,
  chatH2: PropTypes.string,
  clientKnowledge: PropTypes.any,
  flow: PropTypes.any,
  step: PropTypes.any,
  firstMessage: PropTypes.any,
  chatText: PropTypes.any,
  centered: PropTypes.any,
};

ChatContainer.defaultProps = {
  columns: null,
  chatH1: null,
  chatH2: null,
  clientKnowledge: null,
  flow: '',
  step: '',
  firstMessage: null,
  chatText: null,
  centered: false,
};
export default ChatContainer;
