import { BobApi } from '../../../store/webapi/bobBrain'
import Resocket from 'resocket'
import {
  pushEventToTagManager,
  pushUseridToTagManager,
  getGaClientId
} from '../../../utils/analytics'
const RESET = 'stuart:global:bob/RESET'
const UPDATE_MESSAGE = 'stuart:global:bob/UPDATE_MESSAGE'

function getBrainToken() {
  return JSON.parse(localStorage.getItem('brainSessionId'))
}

const initialState = {
  bobs: [],
  brainSessionId: getBrainToken(),
  chatElements: [],
  gaClientId: getGaClientId(),
  userId: ''
}

export default function reducer(state = initialState, action) {
  const newBobs = []
  let incomingChatElements = []

  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState, { userId: state.userId })

    case BobApi.events.talkToBob.actionSuccess:
      incomingChatElements = state.chatElements.slice(0)

      action.data.content.forEach(element => {
        if (
          element.content_type === 'text' ||
          element.input_type === 'redirect'
        ) {
          incomingChatElements.push(element)
        }
      })

      newBobs.push(action.data)

      //push brainSession Id to localstorage
      localStorage.setItem(
        'brainSessionId',
        JSON.stringify(action.data.vertex.id)
      )
      if (action.data.vertex.id.jwt) {
        localStorage.setItem('brainSessionJwt', action.data.vertex.id.jwt)
      }
      return Object.assign({}, state, {
        bobs: newBobs,
        brainSessionId: getBrainToken(),
        chatElements: incomingChatElements,
        gaClientId: getGaClientId()
      })

    case BobApi.events.initBob.actionSuccess:
      // push user id to google tag manager:
      pushEventToTagManager('hd.userid_set', 'auth', 'hd.userid_set')
      pushUseridToTagManager(action.data.vertex.user_id)
      // console.log(2)

      // window.dataLayer = window.dataLayer || []
      // window.dataLayer.push({ userId: action.data.vertex.user_id })
      // window.dataLayer.push({
      //   action: 'hd.userid_set',
      //   category: 'auth',
      //   event: 'hd.userid_set'
      // })

      //push brainSession Id to localstorage
      localStorage.setItem(
        'brainSessionId',
        JSON.stringify(action.data.vertex.id)
      )

      return Object.assign({}, state, {
        userId: action.data.vertex.user_id,
        brainSessionId: getBrainToken(),
        uid: action.data.vertex.id.uid
      })
    case UPDATE_MESSAGE:
      const newBobs2 = []

      const newChatElements2 = state.chatElements.slice(0)

      newChatElements2.push(action.payload)

      return Object.assign({}, state, {
        chatElements: newChatElements2
      })

    default:
      return state
  }
}
export const resetBob = () => ({ type: RESET })

export function alertNewMessage() {
  Resocket.emit('init', {
    foreignId: getBrainToken().id
  })
  return dispatch => {
    Resocket.on('data message', message => {
      dispatch({ type: UPDATE_MESSAGE, payload: message })
    })
    Resocket.on('connect', message => {
      Resocket.emit('init', {
        foreignId: getBrainToken().id
      })
    })
  }
}

export function initSoccet() {
  return dispatch => {
    // Resocket.on('connect', message => {
    // Resocket.emit('init', {
    //   foreignId: getBrainToken().id
    // })
    //})
  }
}

// curl -X "POST" "https://ws.hudya.io/external_msg" \
//      -H 'Content-Type: application/json; charset=utf-8' \
//      -d $'{
//   "foreignId": "123456",
//   "message":{
//     "content_type": "text",
//     "utterance": "M\u00e5nedsavgift kr 0,- Ingen bindingstid. Kun markedspris + 7.5 \u00f8re/kWh",
//     "voice": "https://s3.eu-central-1.amazonaws.com/hudya-voice/goals/hudya_intro/step-123-hei_hei_alle_sammen.mp3",
//     "suggested_responses": [],
//     "parameters": {},
//     "output_contexts": [],
//     "next": null,
//     "input_type": "text",
//     "force_suggested_responses": false,
//     "extra_parameters": {},
//     "attachment": [],
//     "insert_delay": 1000,
//     "resulting_state": "STEPPING",
//     "analytics": null,
//     "step": {
//       "id": "29",
//       "name": "power intro - faste lave priser",
//       "resulting_state": "STEPPING",
//       "step_type": "Utterance",
//       "initial_step": false,
//       "goal_flow_terminator": false,
//       "goal_flow": "power_onboarding"
//     }
//   }
// }'
