import React from 'react';
import { Container } from 'semantic-ui-react';

import PageLoader from '../../components/PageLoader/pageLoader';
import HtmlParse from '../../components/HtmlParser/htmlParser';
import useWPContent from '../../services/wpService/useWPContent';
import Page from '../../components/Page/Page';

import styles from './styles.module.scss';

const CustomerService = () => {
  const { data, loading } = useWPContent('customerService');
  return loading ? (
    <div className={styles.container}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title || null} loading={loading}>
      <div className={styles.customerService}>
        <Container className={styles.container}>
          <div className="ui grid stackable">
            <div className={`ten wide column centered ${styles.leftColumn}`}>
              <div className={styles.content}>
                <img
                  className="ui image"
                  src="/assets/customerService/logo.svg"
                  alt="logo"
                />
                <h1 className="mobile hidden">
                  {HtmlParse({ data: data.title || '' })}
                </h1>
              </div>
            </div>
            <div className="six wide column">
              <h2>{HtmlParse({ data: data.welcomeText || '' })}</h2>
              <h1>{HtmlParse({ data: data.title || '' })}</h1>
              <div className={styles.hoursContainer}>
                <p className={styles.text}>
                  {HtmlParse({ data: data.workingHoursText || '' })}
                </p>
                <div className={styles.hoursTable}>
                  {data.workingHoursTable && data.workingHoursTable.length
                    ? data.workingHoursTable.map((i) => (
                        <div className={styles.hoursRow} key={i.day}>
                          <span className={styles.day}>{i.day}</span>
                          <span className={styles.hours}>{i.hours}</span>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div className={styles.contactContainer}>
                {data.contactData && data.contactData.length
                  ? data.contactData.map((i) => (
                      <div className={styles.contactBox} key={i.text}>
                        <div className={styles.text}>{i.text}</div>
                        <div className={styles.contact}>
                          {i.type === 'email' ? (
                            <a href={`mailto:${i.contact}`}>
                              {HtmlParse({ data: i.contact || '' })}
                            </a>
                          ) : (
                            <a href={`tel:+47${i.contact}`}>
                              {HtmlParse({ data: i.contact || '' })}
                            </a>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Page>
  );
};

export default CustomerService;
