import { transformers } from 'redux-api';

/**
 * If response contains a `response.data` object this will
 * be returned.
 */
export const responseDataTransformer = (response, prevData, action) => {
  if (response === null) {
    return response;
  }
  if (typeof response === 'undefined') {
    return {};
  }

  if (typeof response.data === 'object') {
    return response.data;
  }

  return response;
};

/**
 * Discards the paginated meta data and returns the
 * `response.data.results` as an array.
 */
export const discardPaginationTransformer = (response, prevData, action) => {
  response = responseDataTransformer(response, prevData, action);
  if (response.results && response.results instanceof Array) {
    return response.results;
  }

  if (Object.keys(response).length === 0) {
    return [];
  }

  return transformers.array(response);
};
