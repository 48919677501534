import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toggleDisplaySplash } from '../../../store/global/config'
import { Button } from 'semantic-ui-react'

class SplashScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="splash-screen">
        <div>
          <p className="welcome">{this.props.splashMessages}</p>
          {/* <Button
            secondary
            onClick={() => this.props.actions.toggleDisplaySplash()}
          >
            Close
          </Button> */}
        </div>
      </div>
    )
  }
}
export default connect(
  state => ({
    config: state.global.config
  }),
  dispatch => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators(
        {
          toggleDisplaySplash
        },
        dispatch
      )
    )
  })
)(SplashScreen)
SplashScreen.propTypes = { splashMessages: PropTypes.string.isRequired }
