import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Container,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

// import { accountAPI } from '../../../store/webapi/account'
import ReactMarkdown from 'react-markdown/with-html';

import { setCredentials } from '../../../store/global/credentials';
import Chat from '../../../components/Chat';
import ProductLinks from '../components/ProductLinks';
import Page from '../../../components/Page/Page';

import styles from './intro.module.css';

class Intro extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {};

  constructor(props, context) {
    super(props, context);
    document.title = context.xgettext('Intro - Hudya', 'Intro page title');
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    const { gettext, xgettext } = this.context;

    const flow = params.get('flow');

    if (this.props.credentials.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { from: '/' },
          }}
        />
      );
    }
    return (
      <Page
        title={xgettext('Intro - Hudya', 'Intro page title')}
        loading={false}
      >
        <div className={styles['public-intro']}>
          <Container>
            <div
              className={styles['public-intro-header']}
              style={{ position: 'relative' }}
            >
              <p>
                <img
                  className={styles['intro-logo']}
                  src="https://3w0ze1378d2118joo61k25p1-wpengine.netdna-ssl.com/wp-content/themes/hudya-norway/assets/img/hudya-logo-light.svg"
                />{' '}
                <i> {gettext('Life simplified')}</i>
              </p>
              <Grid stackable style={{ paddingBottom: 100 }}>
                <Grid.Row textAlign="left">
                  <Grid.Column width="11">
                    <h1>
                      <ReactMarkdown
                        source={xgettext(
                          'Welcome to <strong> My Hudya</strong>',
                          'login main header',
                        )}
                        escapeHtml={false}
                      />
                    </h1>
                    <Chat
                      goalFlow={flow || 'hudya_intro'}
                      step="init_no_refin"
                    />
                  </Grid.Column>
                  <Grid.Column width="5">
                    <h2>
                      {gettext('My Hudya is your space in the hudya domain.')}
                    </h2>
                    <div className={styles['intro-disclaimer']}>
                      <ReactMarkdown
                        source={gettext('Manage you life in an easier way')}
                        escapeHtml={false}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {/* <img
          className="aconto-cards-image"
          src='{process.env.PUBLIC_URL + '/assets/cards.png'}'
        /> */}
            </div>
          </Container>

          <div className={styles['intro-faq']}>
            <Container>
              <div className={styles['svg-curve']}>
                <svg
                  version="1.1"
                  fill="#FFFFF7"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 1080 65"
                  preserveAspectRatio="none"
                >
                  <path d="M0,45.069C671.161,45.478,1080,0,1080,0v65H0V45.069z" />
                </svg>
              </div>
              <ProductLinks />
            </Container>
          </div>
        </div>
      </Page>
      // <Container>
      //   <div className="public-power">
      //     <img
      //       className="lamp"
      //       style={{ left: '52%' }}
      //       src="https://3w0ze1378d2118joo61k25p1-wpengine.netdna-ssl.com/wp-content/uploads/2018/06/section-help-table.png"
      //     />
      //     <div>
      //       <Grid stackable>
      //         <Grid.Row textAlign="left">
      //           <Grid.Column width="8">
      //             <CustomHeader />
      //           </Grid.Column>
      //         </Grid.Row>
      //         <Grid.Row textAlign="left">
      //           <Grid.Column width="8">
      //             <Chat goalFlow={flow || 'hudya_intro'} step="init_no_refin" />

      //           </Grid.Column>
      //         </Grid.Row>
      //         <Grid.Row textAlign="left">
      //           <Grid.Column width="12" />
      //         </Grid.Row>
      //       </Grid>

      //   </div>
      // </Container>
    );
  }
}

export default connect(
  (state) => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
  }),
  (dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch),
    ),
  }),
)(Intro);

// function Header(props) {
//   return (
//     <div className="main-heading">
//       <h1>
//         Betal for strømmen du bruker, og <strong>ikke noe mer</strong>
//       </h1>
//     </div>
//   )
// }
const CustomHeader = () => {
  return (
    <div className="main-heading">
      <h1>
        Velkommen til <strong>Hudya!</strong>
      </h1>
    </div>
  );
};
