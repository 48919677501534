const I18TRANS = 'stuart:global:contentful:translations/I18TRANS';
const I18TRANS_ALL = 'stuart:global:contentful:translations/I18TRANS_ALL';

const initialState = {};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case I18TRANS:
      return Object.assign({}, state, {
        [action.payload.fields.i18id]: {
          ...action.payload.fields,
          sys: {...action.payload.sys}
        }
      });
    case I18TRANS_ALL:
      return action.payload.map((item) => {
        return Object.assign({}, {
          [item.fields.i18id]: {
            ...state.i18id,
            ...item.fields,
            sys: {...item.sys}
          }
        });
      }).reduce((a, b) => {
        return {...a, ...b};
      });
    default:
      return state;
  }
}

/**
 * Fetch a single UI Translation from Contentful by i18id.
 * @param i18id: The i18id used to identify the translation string.
 * @returns {function(*, *)}
 */
export const i18Trans = (i18id) => {
  const i18TransAction = (payload) => ({type: I18TRANS, payload});

  return (dispatch, getState) => {
    const {client} = getState().global.contentful;
    return Promise.resolve(
      client.getEntries({content_type: 'uiTranslations', 'fields.i18id': i18id})
    ).then((response) => {
      const item = response.items.filter((obj) => obj.fields.i18id === i18id);
      if (item.length === 1) {
        dispatch(i18TransAction({...item[0]}));
      }
    });
  };
};

/**
 * Fetch all UI translations from Contentful.
 * @param limit: Max number of items to retrieve per request.
 * @returns {function(*, *)}
 */
export const i18TransFetchAll = (limit = 1000) => {
  const i18TransFetchAllAction = (payload) => ({type: I18TRANS_ALL, payload});

  const fetchNext = (n) => {

  };

  return (dispatch, getState) => {
    const {client} = getState().global.contentful;
    return Promise.resolve(
      client.getEntries({content_type: 'uiTranslations', limit: limit})
        .then((response) => {
          // If limit is less than total amount of items,
          // put the current response on state and fetch the next batch.
          dispatch(i18TransFetchAllAction(response.items));
        })
    );
  };
};
