import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageLoader from '../../../components/PageLoader/pageLoader';
import ChatContainer from '../../../components/ChatContainer';
import FaqHelper from '../../../components/FaqHelper/faqHelper';
import productstyles from '../Home/home.module.scss';
import ProductCard from '../../../components/ProductCard/productCard';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import RatingStars from '../../../components/RatingStars';
import Page from '../../../components/Page/Page';
import useGetDynamicRefinancingQuery from '../../../hooks/gql/landings/refinancing/GetDynamicRefinancing.query';

import styles from './styles.module.scss';
import TrustPilot from "../../../components/TrustPilot/TrustPilot";

const COMPANIES = [
  'avida.png',
  'collector_bank.svg',
  'easy_bank.svg',
  'ekspress_bank.svg',
  'ikano_bank.svg',
  'instabank.svg',
  'komplett_bank.svg',
  'remember.svg',
  'santander.svg',
  'svea_finans.png',
  'yalogo.png',
  'resurs_bank_logo.png',
  'bb_finans.svg',
];

const DynamicRefinancing = () => {
  const params = new URLSearchParams(window.location.search);
  const flow = params.get('flow') || 'debt_check';
  const step = params.get('step') || 'skip_start';
  const { dynamic } = useParams();
  const history = useHistory();

  const { data, loading } = useGetDynamicRefinancingQuery(dynamic);

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title} loading={loading}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.contentContainer}>
        <Container>
          <Grid textAlign="left">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={11} computer={11}>
                <ChatContainer
                  flow={flow}
                  step={step}
                  chatH1={data.title}
                  chatH2=""
                  firstMessage={data.chatMessage}
                  columns={16}
                  chatText={data.chatText}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={5} computer={5}>
                <div className={styles.trustContainer}>
                  <TrustPilot />
                  <div className={styles.testimonials}>
                    {HtmlParse({
                      data: data.testimonials || '',
                    })}
                  </div>
                  <div className={styles.trustList}>
                    <h3>{data.trustTitle}</h3>
                    {data.trustList.map((t) => (
                      <div key={t.id} className={styles.item}>
                        <img
                          src="/assets/landings/refinancing/check.svg"
                          alt="check"
                        />
                        <span>{t.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* <Container> */}
        {/*  <div className={styles.contentContainer}> */}
        {/*    <div className={styles.content}> */}
        {/*      {HtmlParse({ */}
        {/*        data: data.content || '', */}
        {/*      })} */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </Container> */}

        <div className={styles.companiesLogos}>
          <Container>
            <h2>{data.logoTitle}</h2>
            <div className={styles.logoBox}>
              <Grid textAlign="center">
                {COMPANIES.map((c) => (
                  <Grid.Column mobile={8} tablet={4} computer={4} key={c}>
                    <img
                      src={`/assets/companiesLogos/${c}`}
                      className={styles.logo}
                      alt={c}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            </div>
          </Container>
        </div>
        <div>
          <FaqHelper type="refinansiering" />
        </div>
        <div className={productstyles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductCard
                  title="Mobil"
                  text="Vi har full Telenor-dekning, Data Rollover, gratis BankID ogingen bindingstid."
                  buttonText="Bestill nå"
                  buttonUrl="/mobilabonnement"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/mobilabonnement/produkter');
                  }}
                  image="/assets/home/mobile.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title="Forsikring"
                  text="De som velger å bytte til oss sparer i snitt over 10% på sin forsikringspremie"
                  buttonText="Bestill nå"
                  buttonUrl="/forsikring"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/forsikring');
                  }}
                  image="/assets/home/insurance.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
      </div>
    </Page>
  );
};

export default DynamicRefinancing;
