/**
 * WebAPI reducer for mobile.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import {combineReducers} from 'redux';

import config from '../../../config';
import {responseDataTransformer} from '../transformers';

const webapi = reduxApi({
  featuretoggles: {
    helpers: {
      get() {
        return [{}, {}];
      }
    },
    transformer: responseDataTransformer,
    url: `${config.webapi.featuretoggles.baseURI}/`
  }
})
  .use('options', (url, params, getState) => {
    const {tokenType, accessToken} = getState().global.credentials;
    const headers = {Accept: 'application/json'};

    if (accessToken) {
      return {headers: {...headers, Authorization: `${tokenType || 'Bearer'} ${accessToken}`}};
    }

    return headers;
  })
  .use('fetch', adapterFetch(fetch));

export {webapi as featuretogglesAPI};
export default combineReducers(webapi.reducers, {});
