import { combineReducers } from 'redux';

import appConfig from '../../config';

import bob from './bob';
import config from './config';
import contentful from './contentful';
import credentials from './credentials';
import entities from './entities';
import gui from './gui';
import providers from './providers';

// Keep track of what runtime environment we're on
const runtimeEnv = () => appConfig.RUNTIME_ENV;

export default combineReducers({
  bob,
  config,
  contentful,
  credentials,
  entities,
  gui,
  providers,
  runtimeEnv,
});
