/**
 * WebAPI reducer for authentication.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

//import { accountAPI } from '../account'
import { legalEntityAPI } from '../legalEntity'

import config from '../../../config'
import { checkSuccess } from '../utils'
import { responseDataTransformer } from '../transformers'
import { setDefaultLocale } from '../../global/config'
import { RESET_CREDENTIALS } from '../../global/credentials'

const webapi = reduxApi({
  createAndSendtOTP: {
    transformer: responseDataTransformer,
    url: `${config.webapi.auth.baseURI}/register/`,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        dispatch(
          actions.sendOTP(
            {},
            {
              body: JSON.stringify({
                phoneNumber: getState().webapi.auth.createAndSendtOTP.data
                  .phoneNumber.number
              })
            }
          )
        )
      }
    ]
  },

  accountCreate: {
    transformer: responseDataTransformer,
    url: `${config.webapi.auth.baseURI}/register/`,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  },
  login: {
    options: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'post'
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        if (!checkSuccess('webapi.account.account')) {
          const accountId = getState().global.credentials.id

          Promise.resolve(
            // dispatch(accountAPI.actions.account.sync({ id: accountId }))
            dispatch(legalEntityAPI.actions.entity.sync({ id: accountId }))
          ).then(response => {
            dispatch(setDefaultLocale(response.locale))
          })
        }
      }
    ],
    transformer: responseDataTransformer,
    url: `${config.webapi.auth.baseURI}/login/`
  },
  sendOTP: {
    options() {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }

      // guard for staging and prod

      if (config.RUNTIME_ENV === 'staging' || config.RUNTIME_ENV === 'prod') {
        return {
          headers,
          method: 'POST'
        }
      }
      headers['X-Hudya-Return-Otp'] = true
      headers['X-Hudya-Skip-SMS-Send'] = true

      return {
        headers,
        method: 'POST'
      }
    },
    transformer: responseDataTransformer,
    reducer(state, action) {
      switch (action.type) {
        case RESET_CREDENTIALS:
          return Object.assign(
            {},
            {
              data: {},
              loading: false,
              request: null,
              sync: false,
              syncing: false
            }
          )
        default:
          return state
      }
    },
    url: `${config.webapi.auth.baseURI}/send-user-otp/`
  },
  verifyToken: {
    options: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        if (!checkSuccess('webapi.account.account')) {
          const accountId = getState().global.credentials.id
          dispatch(legalEntityAPI.actions.entity.sync({ id: accountId }))

          //dispatch(accountAPI.actions.account.sync({ id: accountId }))
        }
      }
    ],
    url: `${config.webapi.auth.baseURI}/verify-token/`
  }
}).use('fetch', adapterFetch(fetch))

export { webapi as authAPI }
export function resetAuthState() {
  return dispatch =>
    Promise.all([
      dispatch(webapi.actions.login.reset()),
      dispatch(webapi.actions.sendOTP.reset())
    ])
}
export default combineReducers(webapi.reducers, {})
