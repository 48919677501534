const MIN_PHONE_NUMBER_LENGTH = 8;
const SSN_LENGTH = 11;

export function emailValidator(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function phoneNumberValidator(phoneNumber) {
  return phoneNumber && phoneNumber.length === MIN_PHONE_NUMBER_LENGTH && (/^\d*$/).test(phoneNumber);
}
export function ssnValidator(ssn) {
  return ssn && ssn.length === SSN_LENGTH && (/^\d*$/).test(ssn);
}

// minimum firstname and lastname required
export function nameValidator(input) {
  const names = input.split(' ');
  const lastname = names.pop();

  return (
    typeof lastname !== 'undefined' && lastname.length > 1 && typeof names[0] !== 'undefined' && names[0].length > 1
  );
}
