import { combineReducers } from 'redux'

import account from '../scenes/Account/reducer'
import auth from '../scenes/Auth/reducer'

import core from './coreapi'
import global from './global'
import webapi from './webapi'

const appReducer = combineReducers({
  account,
  auth,
  core,
  global,
  webapi
})

export default (state, action) => {
  if (action.type === 'LOGOUT') {
    // Preserve parts of state on logout!
    const { transifex } = state.webapi
    const { config } = state.global
    localStorage.removeItem('brainSessionJwt')
    state = {
      webapi: { transifex: { ...transifex } },
      global: { config: { ...config } }
    }
  }

  return appReducer(state, action)
}
