import React, { useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import PageLoader from '../../../components/PageLoader/pageLoader';
import ChatContainer from '../../../components/ChatContainer';
import FaqHelper from '../../../components/FaqHelper/faqHelper';
import productstyles from '../Home/home.module.scss';
import ProductCard from '../../../components/ProductCard/productCard';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import RatingStars from '../../../components/RatingStars';
import useGetMobileCommonQuery from '../../../hooks/gql/mobile/GetMobileCommon.query';
import useGetMobileProductQuery from '../../../hooks/gql/mobile/GetMobileProduct.query';
import Page from '../../../components/Page/Page';

import styles from './styles.module.scss';

const MobileProduct = () => {
  const {
    data: mobileCommonData,
    loading: mobileCommonDataLoading,
  } = useGetMobileCommonQuery();
  const params = new URLSearchParams(window.location.search);
  const flow = params.get('flow') || 'mobile_onboarding';
  const step = 'pre_knowledge';
  const history = useHistory();
  const location = useLocation();
  const [product] = useState(() => {
    let path = location.pathname;
    let p = null;
    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1);
    }
    const split = path.split('/');
    if (split && split.length > 2) {
      p = split[2];
    }
    return p;
  });

  const {
    data: mobileProductData,
    loading: mobileProductDataLoading,
  } = useGetMobileProductQuery(product);

  const getGreenChecks = (position) => {
    let arr = [];
    if (position === 'left') {
      if (mobileProductData && mobileProductData.whyWeLeftList) {
        arr = _.uniqBy(
          [
            ...mobileCommonData.whyWeLeftList,
            ...mobileProductData.whyWeLeftList,
          ],
          'text',
        );
      } else {
        arr = mobileCommonData.whyWeLeftList;
      }
      if (mobileProductData.whyWeLeftListExcluded) {
        _.forEach(mobileProductData.whyWeLeftListExcluded, (value) => {
          arr = _.filter(arr, (o) => {
            return o.text !== value.text;
          });
        });
      }
    }
    if (position === 'right') {
      if (mobileProductData && mobileProductData.whyWeRightList) {
        arr = _.uniqBy(
          [
            ...mobileCommonData.whyWeRightList,
            ...mobileProductData.whyWeRightList,
          ],
          'text',
        );
      } else {
        arr = mobileCommonData.whyWeRightList;
      }
      if (mobileProductData.whyWeRightListExcluded) {
        _.forEach(mobileProductData.whyWeRightListExcluded, (value) => {
          arr = _.filter(arr, (o) => {
            return o.text !== value.text;
          });
        });
      }
    }

    return arr.map((a, index) => ({ text: a.text, id: index }));
  };

  return mobileCommonDataLoading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={mobileProductData.title} loading={mobileProductDataLoading}>
      <div className={styles.contentContainer}>
        <Container>
          <Grid textAlign="left">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={11} computer={11}>
                {mobileProductDataLoading ? null : (
                  <ChatContainer
                    flow={flow}
                    step={step}
                    chatH1={mobileProductData.title || ''}
                    firstMessage={mobileProductData.firstMessage || ''}
                    columns={16}
                    clientKnowledge={{
                      mobile: {
                        product: mobileProductData.subscriptionValue || '',
                      },
                    }}
                    disc={null}
                  />
                )}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={5} computer={5}>
                <div className={styles.sideContainer}>
                  <RatingStars count={5} rating={mobileCommonData.rating} />
                  <div className={styles.rating}>
                    <b>{mobileCommonData.rating}/5.0</b>
                  </div>
                  <div className={styles.ratingLogo}>
                    <img
                      src="/assets/landings/mobile/mobile_logo.png"
                      alt="logo"
                    />
                  </div>
                  <div className={styles.testimonials}>
                    {HtmlParse({
                      data: mobileCommonData.testimonials || '',
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container className={styles.whyWeContainer}>
          <div className={styles.whyWeTitle}>
            <h2>{mobileCommonData.whyWeTitle}</h2>
          </div>
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <div className={styles.whyWeList}>
                  {getGreenChecks('left').map((t) => (
                    <div key={t.id} className={styles.item}>
                      <img
                        src="/assets/landings/refinancing/check.svg"
                        alt="check"
                      />
                      <span>{t.text}</span>
                    </div>
                  ))}
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <div className={styles.whyWeList}>
                  {getGreenChecks('right').map((t) => (
                    <div key={t.id} className={styles.item}>
                      <img
                        src="/assets/landings/refinancing/check.svg"
                        alt="check"
                      />
                      <span>{t.text}</span>
                    </div>
                  ))}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div className={styles.whyWe}>
                {HtmlParse({
                  data: mobileCommonData.whyWeContent || '',
                })}
              </div>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <div className={styles.content}>
            {HtmlParse({
              data: mobileProductData.content || '',
            })}
          </div>
        </Container>

        <div>
          <FaqHelper type="mobil" />
        </div>
        <div className={productstyles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductCard
                  title="Forsikring"
                  text="De som velger å bytte til oss sparer i snitt over 10% på sin forsikringspremie"
                  buttonText="Bestill nå"
                  buttonUrl="/forsikring"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/forsikring');
                  }}
                  image="/assets/home/insurance.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title="Refinansiering"
                  text="Logg inn med BankID på mobil og se hva du kan spare på lån og kredittkort."
                  buttonText="Bestill nå"
                  buttonUrl="/refinansiering"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/refinansiering');
                  }}
                  image="/assets/home/bank.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
      </div>
    </Page>
  );
};

export default MobileProduct;
