import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from 'semantic-ui-react';

import AddressesEdit from './AddressesEdit';

// Hack to avoid eslint error:
const CardContent = Card.Content;
const CardHeader = Card.Header;
const CardMeta = Card.Meta;

export default class Addresses extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  };

  static propTypes = {
    account: PropTypes.object.isRequired,
    accountAPIState: PropTypes.object.isRequired,
    actions: PropTypes.shape({toggleAddressEditState: PropTypes.func}).isRequired,
    isEditMode: PropTypes.bool.isRequired
  };
  constructor(props) {
    super(props);
    this.toggleEditMode = this.props.actions.toggleAddressEditState.bind(this);
  }

  render() {
    const {gettext, xgettext} = this.context;
    const {account, isEditMode} = this.props;

    if (isEditMode) {
      return <AddressesEdit {...this.props} />;
    }
    const primaryAddress = account.contactInfo.addresses.find((address) => address.kind === 'primary') || {};
    const validAddress = primaryAddress.address1 && primaryAddress.zipCode && primaryAddress.city;

    return (
      <div>
        <Card fluid>
          <CardContent extra>
            <CardHeader>{gettext('Billing information')}</CardHeader>
            <CardMeta>
              {xgettext(
                'This is your main invoicing address. Make sure it matches your officially registered address.',
                'Text displayed underneath the card header on the Account page.'
              )}
            </CardMeta>
          </CardContent>
          <CardContent>
            <p hidden={!validAddress} style={{fontWeight: 'bold'}}>
              {gettext('Billing address')}
            </p>
            <p hidden={validAddress}>
              {xgettext(
                'No billing address found. Please add it by clicking on the button below.',
                'Fallback text displayed on the address card on Account page if we cannot detect a primary address for the user.'
              )}
            </p>
            <p>{primaryAddress.address1}</p>
            <p>{primaryAddress.address2}</p>

            <p>
              {primaryAddress.zipCode} {primaryAddress.city}
            </p>
          </CardContent>
          <CardContent extra>
            <div>
              <Button basic primary onClick={this.toggleEditMode}>
                {validAddress ? gettext('Update billing address') : gettext('Set billing address')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
