import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const PowerDetails = ({ data }) => {
  return (
    <div className={styles.powerDetails}>
      <div className={`content ${styles.cardContent}`}>
        <div className={styles.cardHeader}>{data.subscriptionName || '-'}</div>
        <div className={styles.cardDetails}>
          <div className={styles.contentSeparator} />
          <div className="ui grid">
            <div className="eight wide column">
              <div className={styles.label}>Dagens Spotpris</div>
              <div className={styles.value}>
                {data.rate || '-'}{' '}
                <span className={styles.valueCurrency}>{data.per || '-'}</span>
              </div>
            </div>
            <div className="eight wide column">
              <div className={styles.label}>
                Bruk {moment().subtract(1, 'months').format('MMMM')}
              </div>
              <div className={styles.value}>
                {data.balance || '-'}{' '}
                <span className={styles.valueCurrency}>
                  {data.currency || '-'}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.contentSeparator} />
          <div className="ui grid">
            <div className="ten wide column">
              <div className={styles.label}>Adresse</div>
              <div className={styles.value}>
                {data && data.address ? data.address.street : '-'}
              </div>
            </div>
            <div className="six wide column">
              <div className={styles.label}>Postnummer</div>
              <div className={styles.value}>
                {data && data.address ? data.address.postalCode : '-'}
              </div>
            </div>
          </div>
          <div className={styles.contentSeparator} />
          <div className="ui grid">
            <div className="one wide column">
              <div className={styles.label}>MalepunktID</div>
              <div className={styles.value}>{data.subscriptionId || '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PowerDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PowerDetails;
