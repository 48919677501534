import { combineReducers } from 'redux';

import account from './account';
import legalEntity from './legalEntity';
import subscriptions from './subscriptions';
import auth from './auth';
import bob from './bobBrain';
import vippsAuth from './auth/vippsLogin';
import core from './core';
import mobile from './mobile';
import power from './power';
import transifex from './transifex';
import invoice from './invoice';
import featuretoggles from './featuretoggles';
import cms from './cms';
import esn from './esn';
import eRate from './eRate';
import frende from './frende';

export default combineReducers({
  account,
  auth,
  bob,
  core,
  cms,
  legalEntity,
  featuretoggles,
  invoice,
  mobile,
  power,
  subscriptions,
  transifex,
  vippsAuth,
  esn,
  eRate,
  frende,
});
