import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import { authAPI } from '../../../store/webapi/auth';
import { esnAPI } from '../../../store/webapi/esn';
import { eRateAPI } from '../../../store/webapi/eRate';

import styles from './styles.module.scss';
import TitleSection from './components/TitleSection';
import ChartCard from './components/ChartCard/ChartCard';
import DetailsCard from './components/DetailsCard/DetailsCard';
import InvoiceCard from './components/InvoiceCard/InvoiceCard';
import FloatingMenu from './components/FloatingMenu/FloatingMenu';

class SubscriptionDetails extends React.Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }),
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    providers: PropTypes.object.isRequired,
    webapi: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  };

  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      config: match.params,
    };
    const { config } = this.state;
    if (!config.id) {
      props.history.push('/login');
    }
  }

  componentWillMount() {
    const { actions, account, providers, history } = this.props;
    const { config } = this.state;
    const { provider, id } = config;
    if (provider === 'esn') {
      if (!providers.esn.subscriptions.length) {
        history.push('/products');
      }
      actions.dispatch(esnAPI.actions.esnSubscription.get(account.id, id));
    }
    if (provider === 'eRate') {
      actions.dispatch(eRateAPI.actions.eRateSubscription.get(account.id, id));
    }
  }

  getData = () => {
    const { providers, webapi } = this.props;
    const { config } = this.state;
    const { provider } = config;
    if (provider === 'esn') {
      return {
        data: providers.esn.subscription,
        loading: webapi.esn.esnSubscription.loading,
      };
    }
    if (provider === 'eRate') {
      return {
        data: providers.eRate.subscription || {},
        loading: webapi.eRate.eRateSubscription.loading,
      };
    }
    return { data: [], loading: false };
  };

  render() {
    const { config } = this.state;
    const { id, type, provider } = config;
    const d = this.getData();
    return (
      <div className={`${styles.subscriptionDetails} ${styles[type]}`}>
        <FloatingMenu />
        <TitleSection type={type} />
        <Container>
          <div className="ui stackable doubling three column grid center aligned">
            <Grid.Row centered>
              <Grid.Column>
                <ChartCard data={d.data} loading={d.loading} type={type} />
              </Grid.Column>
              <Grid.Column>
                <DetailsCard data={d.data} loading={d.loading} type={type} />
              </Grid.Column>
              <Grid.Column>
                <InvoiceCard data={d.data} loading={d.loading} type={type} />
              </Grid.Column>
            </Grid.Row>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      webapi: state.webapi,
      bob: state.global.bob,
      config: state.global.config,
      chat: state.global.bob.chatElements,
      account: state.global.entities.account,
      providers: state.global.providers,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators(
          {
            ...authAPI.actions,
            ...esnAPI.actions,
          },
          dispatch,
        ),
      ),
    }),
  )(SubscriptionDetails),
);
