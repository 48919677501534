import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Dropdown, Icon, Menu } from 'semantic-ui-react';

import config from '../../config';
import { togglePushMenu } from '../../store/global/gui/navigation';

import styles from './topMenu.module.scss';

class TopMenu extends Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    actions: PropTypes.shape({ dispatch: PropTypes.func }).isRequired,
    credentials: PropTypes.object.isRequired,
    config: PropTypes.shape({
      defaultLocale: PropTypes.string.isRequired,
      activeProducts: PropTypes.object.isRequired,
    }).isRequired,
    // i18Trans: PropTypes.func.isRequired,
    // isToggled: PropTypes.bool.isRequired,
    // previewMode: PropTypes.bool.isRequired,
    // runtimeEnv: PropTypes.string.isRequired,
    toggleContentfulPreview: PropTypes.func.isRequired,
    toggleFunc: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.style = { contentFulRadio: { fontSize: 'x-small' } };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.togglePushMenu = this.togglePushMenu.bind(this);
    this.toggleContentfulPreview = this.props.toggleContentfulPreview.bind(
      this,
    );
    this.handleLogoutItemClick = this.handleLogoutItemClick.bind(this);
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  togglePushMenu(e) {
    const { toggleFunc } = this.props;
    e.preventDefault();
    e.stopPropagation();
    toggleFunc();
  }

  handleLogoutItemClick() {
    const logout = () => ({ type: 'LOGOUT' });

    this.props.dispatch(logout());
  }

  render() {
    const { config: configProps, hidden } = this.props;
    const { activeItem } = this.state;
    const { gettext, xgettext } = this.context;
    const { credentials } = this.props;

    const { defaultLocale, activeProducts, urlLocaleMap } = configProps;
    const wpSiteUrl = config.WP_SITE_URL[defaultLocale];

    if (hidden) return null;

    return (
      <Menu className={styles.container} borderless size="tiny" fixed="top">
        <Container>
          <Menu.Menu position="left">
            <a className="item" href="/">
              <img
                className={`ui image ${styles.logo}`}
                src="/assets/Logo_White_Blue.svg"
                width="130"
                alt="logo"
              />
            </a>
            {/* <p style={{ marginLeft: -10, marginTop: 17 }}>
              <i>{gettext('Life Simplified')}</i>
            </p> */}
          </Menu.Menu>

          <Menu.Menu
            className={`mobile hidden ${styles.rightMenu}`}
            position="right"
          >
            <Menu.Item
              active={activeItem === 'home'}
              className={styles.menuElement}
              href="/"
              name="home"
              onClick={this.handleItemClick}
            >
              {xgettext('Home', 'Text for the link to the main Hudya page.')}
            </Menu.Item>
            <Dropdown
              className={`${styles.menuElement} ${styles.dropdown}`}
              item
              simple
              text={gettext('Products')}
              closeOnBlur
              closeOnChange
            >
              <Dropdown.Menu>
                <Dropdown
                  disabled={!activeProducts.mobile[defaultLocale]}
                  item
                  simple
                  text={gettext('Mobil')}
                  closeOnBlur
                  className={styles.arrowContainer}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/mobilabonnement/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={gettext('Hudya Mobil')}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header content={gettext('Abonnementer')} />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      href="/mobilabonnement/flex/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Flex',
                        'Text for the link to Hudya Mobile Flex.',
                      )}
                    />

                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/0mb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '0GB', */}
                    {/*    'Text for the link to Hudya Mobile 0GB.' */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/300mb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '300MB', */}
                    {/*    'Text for the link to Hudya Mobile 300mb.', */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/500mb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '500MB', */}
                    {/*    'Text for the link to Hudya Mobile 500mb.', */}
                    {/*  )} */}
                    {/* /> */}
                    <Dropdown.Item
                      href="/mobilabonnement/barn-1gb/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Barn 1GB',
                        'Text for the link to Hudya Mobile Barn 1GB.',
                      )}
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/1gb/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        '1GB',
                        'Text for the link to Hudya Mobile 1GB.',
                      )}
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/2gb/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        '2GB',
                        'Text for the link to Hudya Mobile 2gb.',
                      )}
                    />
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/3gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '3GB', */}
                    {/*    'Text for the link to Hudya Mobile 3gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/5gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '5GB', */}
                    {/*    'Text for the link to Hudya Mobile 5gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/6gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '6GB', */}
                    {/*    'Text for the link to Hudya Mobile 6gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    <Dropdown.Item
                      href="/mobilabonnement/10gb/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        '10GB',
                        'Text for the link to Hudya Mobile 10gb.',
                      )}
                    />
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/12gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '12GB', */}
                    {/*    'Text for the link to Hudya Mobile 12gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/20gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '20GB', */}
                    {/*    'Text for the link to Hudya Mobile 20gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    {/* <Dropdown.Item */}
                    {/*  href="/mobilabonnement/30gb/" */}
                    {/*  disabled={!activeProducts.mobile[defaultLocale]} */}
                    {/*  text={xgettext( */}
                    {/*    '30GB', */}
                    {/*    'Text for the link to Hudya Mobile 30gb.', */}
                    {/*  )} */}
                    {/* /> */}
                    <Dropdown.Item
                      href="/mobilabonnement/6gb-voksen-nok/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text="6 GB Voksen Nok"
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/12gb-voksen-nok/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text="12 GB Voksen Nok"
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/30gb-voksen-nok/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text="30 GB Voksen Nok"
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/mobilt-bredband/"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Mobilt bredbånd',
                        'Text for the link to Hudya Mobile mobilt bredbånd.',
                      )}
                    />
                    <Dropdown.Item
                      href="/mobilabonnement/produkter"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Mobile - Products',
                        'Text for the link to Hudya Mobile.',
                      )}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header content={gettext('Andre sider')} />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      href="/mobilabonnement/ovrige-priser-alle-abonnement"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Øvrige priser',
                        'Text for the link to Hudya Mobile prices.',
                      )}
                    />
                    {/* <Dropdown.Item
                      href="http://shop.hudya.no"
                      disabled={!activeProducts.mobile[defaultLocale]}
                      text={xgettext(
                        'Shop',
                        'Text for the link to Hudya Mobile shop.'
                      )}
                    /> */}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <DropdownItem
                  as={Link}
                  to={"/mobil"}
                  disabled={!activeProducts.mobile[defaultLocale]}
                  text={xgettext(
                    "Mobile",
                    "Text for the link to Hudya Mobile."
                  )}
                />
                <DropdownItem
                  as={Link}
                  to={"/mobil/produkter"}
                  disabled={!activeProducts.mobile[defaultLocale]}
                  text={xgettext(
                    "Mobile - Products",
                    "Text for the link to Hudya Mobile."
                  )}
                /> */}

                <Dropdown.Item
                  href="/forsikring"
                  disabled={!activeProducts.insurance[defaultLocale]}
                  text={xgettext(
                    'Insurance',
                    'Text for the link to Hudya Insurance.',
                  )}
                />
                {/*
                {defaultLocale === 'sv_SE' && (
                  <Dropdown
                    disabled={!activeProducts.power[defaultLocale]}
                    item
                    simple
                    text={xgettext(
                      'Power',
                      'Text for the link to Hudya Power.'
                    )}
                    closeOnBlur
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href={urlLocaleMap.power[defaultLocale]}
                        disabled={!activeProducts.power[defaultLocale]}
                        text={gettext('Order')}
                      />
                      <Dropdown.Item
                        href="https://wp.hudya.se/sv/produkter/el/"
                        disabled={!activeProducts.power[defaultLocale]}
                        text={gettext('Prices')}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {defaultLocale !== 'sv_SE' && (
                  <Dropdown.Item
                    as="a"
                    href={urlLocaleMap.power[defaultLocale]}
                    disabled={!activeProducts.power[defaultLocale]}
                    text={xgettext(
                      'Power',
                      'Text for the link to Hudya Power.'
                    )}
                  />
                )} */}
                <Dropdown.Item
                  href="/refinansiering"
                  disabled={!activeProducts.refinancing[defaultLocale]}
                  text={xgettext(
                    'Refinance',
                    'Text for the link to Hudya Refinance.',
                  )}
                />
              </Dropdown.Menu>
            </Dropdown>
            {/* {activeProducts.corporate[defaultLocale] && ( */}
            {/*  <Menu.Item */}
            {/*    active={activeItem === 'corporate'} */}
            {/*    as="a" */}
            {/*    className={styles.menuElement} */}
            {/*    href={`${wpSiteUrl}/bedrift/`} */}
            {/*    name="corporate" */}
            {/*    onClick={this.handleItemClick} */}
            {/*  > */}
            {/*    {xgettext( */}
            {/*      'Corporate', */}
            {/*      'Text for the link to Hudya corporate page.', */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/* )} */}
            <Menu.Item
              active={activeItem === 'aboutScoopr'}
              className={styles.menuElement}
              href="/om-scoopr"
              name="aboutScoopr"
              onClick={this.handleItemClick}
            >
              {xgettext('Om Scoopr', 'Text for the link to the about us page.')}
            </Menu.Item>
            <Menu.Item
              active={activeItem === 'support'}
              as="a"
              className={styles.menuElement}
              // href={`${wpSiteUrl}${urlLocaleMap.customerService[defaultLocale]}`}
              href="/kundeservice"
              name="support"
              onClick={this.handleItemClick}
            >
              {xgettext(
                'Customer service',
                'Text for the link to Hudya customer service.',
              )}
            </Menu.Item>
            {activeProducts.blog[defaultLocale] && (
              <Menu.Item
                active={activeItem === 'upcomingEvents2'}
                as="a"
                href={`${wpSiteUrl}${urlLocaleMap.blog[defaultLocale]}`}
                name="upcomingEvents2"
                onClick={this.handleItemClick}
              >
                {xgettext('Blog', 'Text for the link to the Hudya blog.')}
              </Menu.Item>
            )}
            {!credentials.isAuthenticated && defaultLocale !== 'sv_SE' && (
              <Menu.Item
                active={activeItem === 'support'}
                as="a"
                className={styles.menuButton}
                // href={`${wpSiteUrl}${urlLocaleMap.customerService[defaultLocale]}`}
                href="https://minside.scoopr.no/"
                name="support"
                onClick={this.handleItemClick}
                id="b"
              >
                {xgettext('My Hudya', 'Text for the Button in main menu')}
              </Menu.Item>
            )}
            <Menu.Item></Menu.Item>
            {credentials.isAuthenticated && (
              <Dropdown
                item
                simple
                className={`mobile hidden ${styles.logged}`}
                trigger={
                  <span>
                    <Icon name="user" />
                    {gettext('Account')}
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as="a"
                    href="/products"
                    text={gettext('overview')}
                  />
                  {/* <DropdownItem
                    as="a"
                    href={'/account'}
                    text={gettext('Settings')}
                  /> */}
                  <Dropdown.Item
                    text={gettext('Logout')}
                    onClick={this.handleLogoutItemClick}
                  />
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Menu.Menu>

          <Menu.Menu className="mobile only" position="right">
            <Menu.Item
              active={activeItem === 'home'}
              onClick={this.togglePushMenu}
            >
              <Icon name="sidebar" size="large" inverted />
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    );
  }
}
export default withRouter(
  connect((dispatch) => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ togglePushMenu }, dispatch),
    ),
  }))(TopMenu),
);
