import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetMobileProductQuery = (product) => {
  let { loading, error, data } = useQuery(
    gql`
      query getMobileProduct($uri: String) {
        res: pageBy(uri: $uri) {
          status
          content
          productData: mobileProductDetails {
            title
            firstMessage: firstmessage
            subscriptionValue: subscriptionvalue
            whyWeLeftList: whyweleftlist {
              id
              text
            }
            whyWeRightList: whywerightlist {
              id
              text
            }
            whyWeLeftListExcluded: whyweleftlistexcluded {
              id
              text
            }
            whyWeRightListExcluded: whywerightlistexcluded {
              id
              text
            }
          }
        }
      }
    `,
    {
      variables: { uri: `mobile-product/mobile-product-${product}` },
    },
  );
  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.productData };
      return { loading, error, data };
    }
  }

  data = {
    title: 'Mobil',
    firstMessage: '',
    subscriptionValue: '',
    whyWeLeftList: [],
    whyWeRightList: [],
    whyWeLeftListExcluded: [],
    whyWeRightListExcluded: [],
    content: '',
  };

  return { loading, error, data };
};

export default useGetMobileProductQuery;
