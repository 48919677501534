import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import Cookies from 'js-cookie';

import useWPContent from '../../services/wpService/useWPContent';
import HtmlParse from '../HtmlParser/htmlParser';

import styles from './styles.module.scss';
// import PropTypes from 'prop-types';

const COOKIES_NAME = 'ScooprCookies';

const CookiesDisclaimer = () => {
  const [active, setActive] = useState(false);
  const { data, loading } = useWPContent('cookiesDisclaimer');

  const checkCookies = () => {
    const cookies = Cookies.get(COOKIES_NAME);
    if (!cookies) {
      setActive(true);
    }
  };

  const setCookies = () => {
    Cookies.set(COOKIES_NAME, true);
    setActive(false);
  };

  useEffect(() => {
    checkCookies();
  }, []);

  return active && !loading ? (
    <div className={styles.cookiesContainer}>
      <Container>
        <div className={styles.cookiesBox}>
          <span className={styles.text}>
            {HtmlParse({ data: data.text || '' })}{' '}
            <a href="/cookies">
              {HtmlParse({ data: data.moreButtonText || '' })}
            </a>
          </span>
          <button
            type="button"
            className={styles.accept}
            onClick={() => setCookies()}
          >
            {HtmlParse({ data: data.acceptButtonText || '' })}
          </button>
        </div>
      </Container>
    </div>
  ) : null;
};

CookiesDisclaimer.propTypes = {};

CookiesDisclaimer.defaultProps = {};

export default CookiesDisclaimer;
