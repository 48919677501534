import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import ChatContainer from '../../../components/ChatContainer';
import ProductCard from '../../../components/ProductCard/productCard';
import Page from '../../../components/Page/Page';
import useGetProductTypes from '../../../hooks/gql/GetProductTypes.query';
import PageLoader from '../../../components/PageLoader/pageLoader';
import useGetHomeQuery from '../../../hooks/gql/landings/home/GetHome.query';
import HtmlParse from '../../../components/HtmlParser/htmlParser';

import styles from './home.module.scss';

const Home = ({ flow }) => {
  const history = useHistory();
  const { data, loading } = useGetHomeQuery();
  const {
    data: productTypesData,
    loading: productTypesLoading,
  } = useGetProductTypes();

  return loading || productTypesLoading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title} loading={false}>
      <div className={styles.overview}>
        <div className={styles.description}>
          {HtmlParse({
            data: data.header || '',
          })}
        </div>
        <div className={styles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid centered aligned">
              <Grid.Column>
                <ProductCard
                  title={productTypesData.mobileProduct.title || ''}
                  text={productTypesData.mobileProduct.text || ''}
                  buttonText={productTypesData.mobileProduct.buttonText || ''}
                  buttonUrl={productTypesData.mobileProduct.buttonUrl || ''}
                  moreText={productTypesData.mobileProduct.moreText || ''}
                  moreAction={() => {
                    history.push(productTypesData.mobileProduct.moreAction);
                  }}
                  image="/assets/home/mobile.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title={productTypesData.insuranceProduct.title || ''}
                  text={productTypesData.insuranceProduct.text || ''}
                  buttonText={
                    productTypesData.insuranceProduct.buttonText || ''
                  }
                  buttonUrl={productTypesData.insuranceProduct.buttonUrl || ''}
                  moreText={productTypesData.insuranceProduct.moreText || ''}
                  moreAction={() => {
                    history.push(productTypesData.insuranceProduct.moreAction);
                  }}
                  image="assets/home/insurance.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title={productTypesData.refinancingProduct.title || ''}
                  text={productTypesData.refinancingProduct.text || ''}
                  buttonText={
                    productTypesData.refinancingProduct.buttonText || ''
                  }
                  buttonUrl={
                    productTypesData.refinancingProduct.buttonUrl || ''
                  }
                  moreText={productTypesData.refinancingProduct.moreText || ''}
                  moreAction={() => {
                    history.push(
                      productTypesData.refinancingProduct.moreAction,
                    );
                  }}
                  image="/assets/home/bank.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
        <div className={styles.chat}>
          <ChatContainer
            centered
            flow={flow}
            step={data.step || ''}
            chatH2={data.chatH1 || ''}
            columns={11}
          />
        </div>
      </div>
    </Page>
  );
};

Home.propTypes = {
  flow: PropTypes.string.isRequired,
};
Home.defaultProps = {};

export default Home;
