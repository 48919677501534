export const TOGGLE_PUSH_MENU = 'stuart:global:gui/navigation/TOGGLE_PUSH_MENU';
export const TOGGLE_FULLSCREEN = 'stuart:global:gui/navigation/TOGGLE_FULLSCREEN';

const inititalState = {
  pushMenu: {isToggled: false},
  isFullscreen: false
};

export default function reducer(state = inititalState, action) {
  switch (action.type) {
    case TOGGLE_PUSH_MENU:
      return Object.assign({}, state, {
        pushMenu: {
          ...state.pushMenu,
          isToggled: !state.pushMenu.isToggled
        }
      });
    case TOGGLE_FULLSCREEN:
      return Object.assign({}, state, {
        pushMenu: {...state.pushMenu},
        isFullscreen: !state.isFullscreen
      });
    default:
      return state;
  }
}

export const togglePushMenu = () => ({type: TOGGLE_PUSH_MENU});
export const toggleFullscreen = () => ({type: TOGGLE_FULLSCREEN});
