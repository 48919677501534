/**
 * WebAPI reducer for mobile.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import { combineReducers } from 'redux';

import config from '../../../config';
// import { discardPaginationTransformer } from '../transformers';

const webapi = reduxApi({
  eRateSubscriptions: {
    helpers: {
      get() {
        return [{}, {}];
      },
    },
    url: `${config.webapi.eRate.baseURI}/v2/consumer/subscriptions`,
  },
  eRateSubscription: {
    helpers: {
      get(accountId, subscriptionId) {
        return [{ accountId, subscriptionId }, {}];
      },
    },
    url: `${config.webapi.eRate.baseURI}/consumer/:accountId/subscription/:subscriptionId`,
  },
  eRateInvoices: {
    helpers: {
      get() {
        return [{}, {}];
      },
    },
    url: `${config.webapi.eRate.baseURI}/v2/consumer/invoices`,
  },
  eRateInvoice: {
    helpers: {
      get(invoiceId) {
        return [{ invoiceId }, {}];
      },
    },
    url: `${config.webapi.eRate.baseURI}/v1/consumer/invoice-file/:invoiceId`,
  },
})
  .use('options', (url, params, getState) => {
    const { accessToken } = getState().global.credentials;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      return {
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
      };
    }

    return headers;
  })
  .use('fetch', adapterFetch(fetch));

export { webapi as eRateAPI };
export default combineReducers(webapi.reducers, {});
