import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'
import { legalEntityAPI } from '../legalEntity'

import config from '../../../config'

const coreAuth = reduxApi({
  login: {
    url: `${config.coreApi.baseURI}${config.coreApi.loginURI}`,
    options: {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        if (getState().core.auth.login.data.token !== undefined) {
          const LEGAL_ENTITY_ID = getState().core.auth.login.data.user
            .legalEntityId

          dispatch(legalEntityAPI.actions.get.sync({ id: LEGAL_ENTITY_ID }))
        }
      }
    ]
  }
}).use('fetch', adapterFetch(fetch))

export { coreAuth as coreAuthAPI }

export function resetCoreAuthState() {
  return dispatch =>
    Promise.all([
      //  dispatch(webapi.actions.phoneLookup.reset()),
    ])
}

export default combineReducers(coreAuth.reducers, {})
