import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import PageLoader from '../../../components/PageLoader/pageLoader';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import styles from '../content.module.scss';
import ChatContainer from '../../../components/ChatContainer';
import useWPContent from '../../../services/wpService/useWPContent';
import Page from '../../../components/Page/Page';

const About = () => {
  const { data, loading } = useWPContent('about');

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title || null} loading={loading}>
      <div className={`${styles.contentContainer} ${styles.darkContainer}`}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>
                    {HtmlParse({
                      data: data.title || '',
                    })}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={`${styles.header} ${styles.centered}`}>
                  <div className={styles.title}>
                    {HtmlParse({
                      data: data.title || '',
                    })}
                  </div>
                  <div className={styles.subtitle}>
                    {HtmlParse({
                      data: data.subtitle || '',
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.text}>
                  {HtmlParse({
                    data: data.content || '',
                  })}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container className={styles.removeMargin}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <ChatContainer
                  flow="mobile_from_external"
                  step="init_with_fixed_start"
                  chatH2="chat2"
                  chatSideBody=""
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default About;
