import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const TYPES = {
  mobile: 'Mobil',
  mobil: 'Mobil',
  mobilabonnement: 'Mobil',
  refinancing: 'Refinansiering',
  refinansiering: 'Refinansiering',
  insurance: 'Forsikring',
  forsikring: 'Forsikring',
};
export const useGetPostByCategory = (category) => {
  let { loading, error, data } = useQuery(
    gql`
      query getPostByCategory($category: String) {
        posts(where: { categoryName: $category, status: PUBLISH }) {
          postsArray: nodes {
            id: postId
            slug
            title
          }
        }
      }
    `,
    {
      variables: { category: TYPES[category] || category },
    },
  );

  if (data && data.posts && data.posts.postsArray.length) {
    const { postsArray } = data.posts;
    data = postsArray.map((p) => ({
      id: p.id,
      slug: p.slug,
      title: p.title,
    }));
  }

  return { loading, error, data };
};

export default useGetPostByCategory;
