import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetRefinancingQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getRefinancing {
        res: pageBy(uri: "refinancing-landing-page") {
          status
          content
          contentData: refinancingLandingPage {
            chatText: chattext
            rating
            testimonials
            title
            trustTitle: trusttitle
            trustList: trustlist {
              id
              text
            }
            logoTitle: logotitle
            chatMessage: chatmessage
          }
        }
      }
    `,
    {
      variables: {},
    },
  );
  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    chatText: '',
    rating: 0,
    testimonials: '',
    title: 'Refinansiering',
    trustTitle: '',
    trustList: [],
    chatMessage: '',
    content: '',
  };

  return { loading, error, data };
};

export default useGetRefinancingQuery;
