import { mobileAPI } from '../../webapi/mobile'
import { subscriptionsAPI } from '../../webapi/subscriptions'
import { invoiceAPI } from '../../webapi/invoice'

const initialState = {
  mobile: {
    sync: false,
    subscriptionCount: 0,
    coreSync: false,
    sveaSync: false,
    invoiceStatus: true,
    subscriptions: [],
    invoices: []
  },
  power: { sync: false, invoiceStatus: true },
  insurance: { sync: true, invoiceStatus: true }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case mobileAPI.events.contracts.actionSuccess:
      let contracts = action.data.filter(e => e.phoneNumber !== null)

      return Object.assign({}, state, {
        mobile: Object.assign({}, state.mobile, {
          contracts,
          sync: true,
          sveaSync: true,
          subscriptionCount:
            state.mobile.subscriptionCount +
            (state.mobile.sveaSync ? 0 : contracts.length)
        })
      })
    case mobileAPI.events.invoices.actionSuccess:
      let sveaInvoices = action.data
      let invoiceStatus =
        sveaInvoices.filter(e => e.paymentDate === null).length === 0

      return Object.assign({}, state, {
        mobile: Object.assign({}, state.mobile, {
          sveaInvoices,
          invoiceStatus
        })
      })

    case invoiceAPI.events.invoice.actionSuccess:
      let mobileInvoices = action.data.filter(
        e => e.responsibleEntity === 'Hudya Tele & Data AS'
      )
      let powerInvoices = action.data.filter(
        e => e.responsibleEntity === 'Hudya Power AS'
      )

      let mobileInvoiceStatus =
        mobileInvoices.filter(e => e.paid === null).length === 0
      let powerInvoiceStatus =
        powerInvoices.filter(e => e.paid === null).length === 0

      return Object.assign({}, state, {
        power: Object.assign({}, state.power, {
          invoices: powerInvoices,
          invoiceStatus: powerInvoiceStatus
        }),
        mobile: Object.assign({}, state.mobile, {
          invoices: mobileInvoices,
          invoiceStatus: mobileInvoiceStatus
        })
      })
    case subscriptionsAPI.events.subscriptionsList.actionSuccess:
      let power = action.data.filter(e => e.product.kind === 'POWER')
      let mobile = action.data.filter(e => e.product.kind === 'MOBILE')

      // let contracts = action.data.filter(e => e.phoneNumber !== null)
      return Object.assign({}, state, {
        mobile: Object.assign({}, state.mobile, {
          sync: true,
          coreSync: true,
          subscriptionCount:
            state.mobile.subscriptionCount +
            (state.mobile.coreSync ? 0 : mobile.length),
          subscriptions: mobile
        }),
        power: Object.assign({}, state.power, {
          power,
          sync: true,
          subscriptionCount: power.length
        })
      })

    default:
      return state
  }
}
