import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { authAPI } from '../../store/webapi/auth'
import { mobileAPI } from '../../store/webapi/mobile'

import Conversation from '../../components/Conversation'
import {
  emailValidator,
  phoneNumberValidator,
  ssnValidator,
  nameValidator
} from '../../validators'

class Signup extends React.Component {
  constructor(props) {
    super(props)
    if (typeof this.props.match.params.id === 'undefined')
      this.props.history.push('/signup/1')

    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.updateErrorState = this.updateErrorState.bind(this)
    this.handleLogin = this.handleLogin.bind(this)

    this.state = {
      accountStatus: 'notCreated',
      error: false,
      fields: [
        {
          buttonText: 'Neste',
          errorMessage: 'Come on! Give us two proper names!',
          id: 1,
          name: 'signupStep1',
          question:
            '# Supert! Dette er starten på ditt nye liv med Hudya!!  \n## Vi skal nå gå igjennom noen enkle steg \n ## Hva heter du?',
          type: 'input',
          validator: nameValidator
        },
        {
          errorMessage: 'This does not seem to be a valid phone number',
          id: 2,
          name: 'signupStep2',
          type: 'input',
          placeholder: 'Mobilnummer',
          buttonText: 'Gå videre',
          question:
            '# Så, hva er mobilnummeret ditt?  \nDette er nummeret du mottar innloggingskode på',
          validator: phoneNumberValidator
        },

        {
          buttonText: 'Gå videre',
          errorMessage: 'This does not look like a valid e-mail.',
          id: 3,
          name: 'signupStep3',
          tips: 'Mer om Personvern',
          tipsText:
            '## Mer om... \n # Personvern\n Personopplysninger i form av navn, mobil/telefonnummer og e-post adresse samt andre personopplysninger som du oppgir i forbindelse med bruk av Hudyas tjenester for at vi skal gi deg mer skreddersydd opplevelse og bedre hjelp.\n',
          type: 'input',
          question:
            '# Hei! \n## __Og hva er din e-post?__ \n## Vi lover å ikke sende deg søppelpost eller dele den med tredjeparter.',
          validator: emailValidator
        },

        {
          alternatives: [
            { id: 1, text: 'Jeg godkjenner', value: 'HudyaTermsAccepted' }
          ],

          id: 4,
          name: 'signupStep4',
          question:
            '# Veldig bra! Da gjenstår kun det juridiske, så er vi ferdige. \n ## __Godkjenner du Hudyas generelle vilkår?__ \n ## Et lite utdrag: \n* ## Du får tilgang til alle våre produkter og tjenester. \n* ## Du tillater Hudya å bruke upersonlig data til forbedre tjenestene våre. \n* ## Du kan når som helst trekke tilbake samtykket ditt.',
          tips: 'Vis meg vilkårene',
          tipsText:
            '## Vilkår \n # Hudyas Generelle Vilkår \n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac sapien et purus consectetur condimentum. Vestibulum vulputate odio et turpis porta sagittis. Integer porta lobortis arcu, sit amet feugiat mauris rutrum a. Suspendisse mattis est dolor, faucibus egestas dolor tempus dignissim. \n Nunc metus mauris, bibendum eu laoreet sit amet, placerat sit amet sem. Proin massa orci, consectetur vehicula facilisis a, facilisis a augue. Maecenas blandit ante sed dolor condimentum, sed ornare tortor cursus. Pellentesque et iaculis neque, at tristique leo. Donec rhoncus ultrices nunc sit amet viverra. Etiam non iaculis felis. Aenean commodo nunc magna, eget suscipit massa aliquam nec. Aliquam ac leo et dolor blandit aliquet at sagittis erat.\n',

          type: 'choice'
        },
        {
          id: 5,
          name: 'signupStep5',
          type: 'input',
          placeholder: 'Code from SMS',
          buttonText: 'Send',
          buttonCallback: this.handleLogin,
          question:
            '# Velkommen!  \n ## Du vil nå motta en kode på SMS, vennligst fyll inn denne under så er vi ferdige :)'
        }
      ]
    }
  }
  updateErrorState(value) {
    this.setState({ error: value })
  }
  handleLogin(otp) {
    const number = this.state.fields.find(item => item.name === 'signupStep2')
      .value

    this.props.actions.login(
      {},
      {
        body: JSON.stringify({
          password: otp,
          phoneNumber: `+47${number}`
        })
      }
    )
  }
  handleFieldChange(value) {
    const field = this.state.fields.find(
      item => String(item.id) === String(this.props.match.params.id)
    )
    const { createAndSendtOTP } = this.props.actions

    field.value = value

    this.setState({ field })

    if (
      field.value === 'HudyaTermsAccepted' &&
      this.state.accountStatus === 'notCreated'
    ) {
      createAccount(createAndSendtOTP, this.state.fields)
      this.setState({ accountStatus: 'Created' })
    }
    if (this.state.fields.length === this.props.match.params.id) return
    this.props.history.push(`/signup/${Number(this.props.match.params.id) + 1}`)
  }
  render() {
    const fields = this.state.fields

    // if (Object.keys(this.props.auth.accountCreate.data).length > 0) {
    //   this.props.actions.sendOTP(
    //     {},
    //     {body: JSON.stringify({phoneNumber: `+47${fields.find((item) => item.name === 'signupStep2').value}`})}
    //   );
    // }

    if (this.props.credentials.isAuthenticated) {
      return <Redirect to="/products" />
    }

    return (
      <Form error={this.state.error}>
        <div
          style={{
            color: 'white',
            height: '1000px',
            alignContent: 'center',
            alignItems: 'center',
            minHeight: 200,
            minWidth: 300,
            margin: 20,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '40vw'
          }}
        >
          <Conversation
            fields={fields}
            handleUpdate={this.handleFieldChange}
            history={this.props.history}
            updateErrorState={this.updateErrorState}
          />
        </div>
      </Form>
    )
  }
}

Signup.propTypes = {
  actions: PropTypes.shape({ createAndSendtOTP: PropTypes.func.isRequired })
    .isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(
  connect(
    state => ({
      account: state.global.entities.account,
      auth: state.webapi.auth,
      credentials: state.global.credentials
    }),
    dispatch => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators(
          { ...authAPI.actions, ...mobileAPI.actions },
          dispatch
        )
      )
    })
  )(Signup)
)

function createAccount(accountCreate, fields) {
  const number = fields.find(item => item.name === 'signupStep2').value
  const names = fields
    .find(item => item.name === 'signupStep1')
    .value.split(' ')

  const email = fields.find(item => item.name === 'signupStep3').value
  const lname = names.pop()
  const fname = names.shift()
  const mname = names.join(' ')

  accountCreate(
    {},
    {
      body: JSON.stringify({
        country: 'NO',
        email,
        firstName: fname,
        lastName: lname,
        locale: 'nb_NO',
        middleName: mname,
        phoneNumber: `+47${number}`
      })
    }
  )
}
