import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

function isHTML(str) {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
}

const HtmlParse = ({ data }) => {
  if (data) {
    const html = isHTML(data);
    return html ? <div>{ReactHtmlParser(data)}</div> : data;
  }
  return '';
};

HtmlParse.propTypes = {
  data: PropTypes.string,
};

HtmlParse.defaultProps = {
  data: null,
};

export default HtmlParse;
