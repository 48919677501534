import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import adapter from 'redux-localstorage/lib/adapters/localStorage'
import filter from 'redux-localstorage-filter'
import persistState, { mergePersistedState } from 'redux-localstorage'
import merge from 'deepmerge'
import config from '../config'
import rootReducer from './rootReducer'
import Resocket, { createResocketMiddleware } from 'resocket'

const socket = Resocket.connect(config.WAIKIKI)
const resocketMiddleware = createResocketMiddleware(socket, [
  'init',
  ['message data'],
])
const reducer = compose(
  mergePersistedState((initialState, persitedState) =>
    merge(initialState, persitedState)
  )
)(rootReducer)

const storage = filter([
  'global.credentials',
  'global.config.defaultLocale',
  'global.contentful.config',
])(adapter(window.localStorage))

function configureStore(...enhancers) {
  switch (process.env.NODE_ENV) {
    case 'development':
      /* eslint-disable no-underscore-dangle */
      const composeEnhancer =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
          ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
          : compose
      /* eslint-enable */

      try {
        const Reactotron = require('reactotron-react-js').default
        const reactotronRedux = require('reactotron-redux').reactotronRedux

        Reactotron.configure({ name: 'Stuart' })
          .use(reactotronRedux())
          .connect()

        return createStore(
          reducer,
          compose(...enhancers, Reactotron.createEnhancer())
        )
      } catch (err) {
        console.log(err)

        if (err instanceof Error && err.code === 'MODULE_NOT_FOUND') {
          console.warn(
            `${err.message} \n- Falling back to redux devtools extension.`
          )
        } else {
          throw err
        }
      }

      return createStore(reducer, composeEnhancer(...enhancers))
    default:
      return createStore(reducer, compose(...enhancers))
  }
}

const store = configureStore(
  applyMiddleware(thunkMiddleware, resocketMiddleware),
  persistState(storage, 'state')
)

export default store
