import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import renderHTML from 'react-render-html'
import { Card, Container, Button, Grid } from 'semantic-ui-react'
import MarkdownRenderer from 'react-markdown-renderer'

import '../../utils/string' // eslint-disable-line import/no-unassigned-import
import { powerAPI } from '../../store/webapi/power'
import { invoiceAPI } from '../../store/webapi/invoice'
import { mobileAPI } from '../../store/webapi/mobile'
import { accountAPI } from '../../store/webapi/account'

class Consent extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired
  }

  static propTypes = {
    account: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    actions: PropTypes.shape({ dispatch: PropTypes.func.isRequired }).isRequired
  }

  constructor(props, context) {
    super(props, context)
    this.styles = {
      container: {
        marginLeft: 0,
        marginRight: 0
      }
    }
    this.handleApproveConsent = this.handleApproveConsent.bind(this)
  }
  handleApproveConsent() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      action: 'hd.accept_consent',
      category: 'hd.consent',
      event: 'hd.accept_consent_generic'
    })
    // should be refactored. duplicate of src/scenes/Onboarding/mobile.js line 368
    const COUNTRY_TERMSDATE_MAP = {
      NO: '2017-10-31',
      SE: '2017-10-31',
      DK: '2017-10-31'
    }

    const tags = [
      'account_admin_news',
      'account_dialog_consent',
      'account_dialog_news_email',
      'account_dialog_news_letter',
      'account_dialog_news_sms',
      'account_marketing_email',
      'account_marketing_phone',
      'account_marketing_sms',
      'account_marketing_letter',
      'data_tracking_storage',
      'data_personalisation_services_*',
      'data_personalisation_dialog_*',
      'data_analysis_*'
    ]

    const acceptanceTags = [
      ...tags.map(tag => `${tag}:${COUNTRY_TERMSDATE_MAP.NO}:`)
    ]

    Promise.resolve(
      this.props.actions.dispatch(
        accountAPI.actions.account.patch(this.props.account.id, {
          acceptanceTags
        })
      )
    ).then(() => {
      this.props.history.push('/products')
    })
  }

  render() {
    const { xgettext, gettext } = this.context
    const { account } = this.props

    // if (this.props.credentials.generalConsent) {
    //   return <Redirect to={{pathname: '/products'}} />;
    // }

    return (
      <Grid
        stackable
        style={{ justifyContent: 'center', paddingBottom: 100 }}
        verticalAlign="middle"
      >
        <Grid.Row textAlign="left">
          <Grid.Column width="twelve">
            <Card fluid>
              <Card.Content extra>
                <Card.Header>
                  {gettext('Approve our general terms of use')}
                </Card.Header>
                <Card.Description>
                  {xgettext(
                    'In order to use our services we need your consent',
                    'description for approval of Hudya general terms of use'
                  )}
                </Card.Description>
              </Card.Content>
              <Card.Content style={{ maxHeight: 500, overflowY: 'scroll' }}>
                <MarkdownRenderer
                  markdown={xgettext(
                    'Hudya general terms',
                    'This is the actual terms text , supports markup'
                  )}
                />
              </Card.Content>
              <Card.Content extra>
                <Button fluid primary onClick={this.handleApproveConsent}>
                  {gettext('Approve')}
                </Button>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      account: state.global.entities.account,
      config: state.global.config,
      credentials: state.global.credentials,
      webapi: state.webapi
    }),
    dispatch => ({
      actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch))
    })
  )(Consent)
)
