import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetCompetitionPageQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getCompetitionPage {
        res: pageBy(uri: "competition-page2020") {
          status
          content
          contentData: competitionPage2020 {
            title
            subtitle
            flow
            step
            description
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    title: '',
    subtitle: '',
    description: '',
    flow: '',
    step: '',
  };

  return { loading, error, data };
};

export default useGetCompetitionPageQuery;
