/**
 * WebAPI reducer for cms/articles.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'

const webapi = reduxApi({
  faq: {
    helpers: {
      get(id) {
        return [{ id }, {}]
      },
      patch(id, payload) {
        return [{ id }, { body: JSON.stringify(payload), method: 'PATCH' }]
      },
      post(id, payload) {
        return [{}, { body: JSON.stringify(payload), method: 'POST' }]
      }
    },

    //transformer: discardPaginationTransformer,
    url: `${config.webapi.v2.cms}/articles`
  }
})
  .use('options', (url, params, getState) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Encoding': 'br gzip'
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as cmsApi }
export default combineReducers(webapi.reducers, {})
