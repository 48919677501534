import React from 'react';
import { Container, Grid, Table } from 'semantic-ui-react';

import PageLoader from '../../../components/PageLoader/pageLoader';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import styles from '../content.module.scss';
import useWPContent from '../../../services/wpService/useWPContent';
import Page from '../../../components/Page/Page';

const Cookies = () => {
  const { data, loading } = useWPContent('cookies');

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.custom_page_title || null} loading={loading}>
      <div className={styles.contentContainer}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>
                    {HtmlParse({
                      data: data.custom_page_title || '',
                    })}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.text}>
                  {HtmlParse({
                    data: data.text || '',
                  })}
                </div>

                <Table
                  selectable
                  unstackable
                  basic="very"
                  className={styles.table}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className={styles.tableHeader}>
                        Navn
                      </Table.HeaderCell>
                      <Table.HeaderCell className={styles.tableHeader}>
                        Formål
                      </Table.HeaderCell>
                      <Table.HeaderCell className={styles.tableHeader}>
                        Valgfrihet
                      </Table.HeaderCell>
                      <Table.HeaderCell className={styles.tableHeader}>
                        Lagringstid*
                      </Table.HeaderCell>
                      <Table.HeaderCell className={styles.tableHeader}>
                        Mottaker
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {data.table.map((t) => (
                      <Table.Row
                        verticalAlign="top"
                        className={styles.rowBackgroundColor}
                        key={t.name}
                      >
                        <Table.Cell className={styles.tableContent}>
                          {HtmlParse({
                            data: t.name || '',
                          })}
                        </Table.Cell>
                        <Table.Cell className={styles.tableContent}>
                          {HtmlParse({
                            data: t.purpose || '',
                          })}
                        </Table.Cell>
                        <Table.Cell className={styles.tableContent}>
                          {HtmlParse({
                            data: t.freedomOfChoice || '',
                          })}
                        </Table.Cell>
                        <Table.Cell className={styles.tableContent}>
                          {HtmlParse({
                            data: t.storageTime || '',
                          })}
                        </Table.Cell>
                        <Table.Cell className={styles.tableContent}>
                          {HtmlParse({
                            data: t.recipient || '',
                          })}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default Cookies;
