import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import { checkSuccess } from '../../store/webapi/utils'
import { accountAPI } from '../../store/webapi/account'
import { legalEntityAPI } from '../../store/webapi/legalEntity'

import {
  setBirthDate,
  setUser,
  toggleEditState,
  toggleAddressEditState
} from './reducer'
import Addresses from './components/Addresses'
import PersonalInfo from './components/PersonalInfo'

class MyAccount extends React.PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    accountAPIState: PropTypes.object.isRequired,
    accountState: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired
  }
  componentWillMount() {
    if (!checkSuccess('webapi.account.account')) {
      const { dispatch } = this.props.actions

      dispatch(
        legalEntityAPI.actions.entity.sync({ id: this.props.credentials.id })
      )
    }
  }
  render() {
    const { accountState } = this.props

    return (
      <div>
        <Grid
          stackable
          style={{ justifyContent: 'center' }}
          verticalAlign="middle"
        >
          <Grid.Row>
            <Grid.Column width="twelve">
              <PersonalInfo
                account={this.props.account}
                accountAPIState={this.props.accountAPIState}
                accountState={this.props.accountState}
                actions={this.props.actions}
                isEditMode={accountState.editState}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="twelve">
              <Addresses
                account={this.props.account}
                accountAPIState={this.props.accountAPIState}
                actions={this.props.actions}
                isEditMode={accountState.editAddressState}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <br />
        <br />
      </div>
    )
  }
}

export default connect(
  state => ({
    account: state.global.entities.account,
    accountAPIState: state.webapi.account,
    accountState: state.account, // This seems a bit redundant?
    credentials: state.global.credentials
  }),
  dispatch => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators(
        {
          ...accountAPI.actions,
          setBirthDate,
          setUser,
          toggleAddressEditState,
          toggleEditState
        },
        dispatch
      )
    )
  })
)(MyAccount)
