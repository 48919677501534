import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const WPContext = React.createContext(undefined);
const { Consumer: WPConsumer } = WPContext;

const WP_API_URL = 'https://wp.hudya.no';

const pages = {
  apiUrl: 'wp-json/acf/v3/pages',
  customerService: 5674,
  dataPolicy: 5672,
  cookies: 5667,
  userAgreement: 5673,
  mobilUserAgreement: 5702,
  home: 5679,
  mobile: 5680,
  mobileSubscriptions: 5683,
  cookiesDisclaimer: 5706,
  refinancing: 5682,
  insurance: 5681,
  about: 5701,
  flex: 5685,
  '0mb': 5686,
  '300mb': 5699,
  '500mb': 5698,
  barn: 5697,
  '1gb': 5696,
  '2gb': 5695,
  '3gb': 5694,
  '5gb': 5693,
  '6gb': 5692,
  '10gb': 5691,
  '12gb': 5690,
  '20gb': 5689,
  '30gb': 5688,
  'mobilt-bredband': 5687,
  scooprModalTest: 5711,
};

function WPProvider({ children }) {
  const [language, setLanguage] = useState('nb');

  const apiCall = async (url) => {
    const data = {
      status: null,
      response: null,
    };
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          data.response = res.data.acf;
          data.status = res.status;
        }
      })
      .catch((err) => console.log(err));

    return data;
  };

  const getPage = async (name) => {
    const res = await apiCall(
      `${WP_API_URL}/${language}/${pages.apiUrl}/${pages[name]}/`,
    );
    return res;
  };

  const value = {
    getPage,
    setLanguage,
    language,
  };

  return <WPContext.Provider value={value}>{children}</WPContext.Provider>;
}

WPProvider.propTypes = {
  children: PropTypes.node,
};

WPProvider.defaultProps = {
  children: null,
};

export { WPContext, WPConsumer, WPProvider };
