import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { formatBytes } from '../../../../../../../utils/formatter';

import styles from './styles.module.scss';

const MobileDetails = ({ data, account }) => {
  const convertToPhoneNumber = (phone) => {
    if (phone) {
      return `${phone.substr(0, 3)} ${phone.substr(3, 2)} ${phone.substr(
        5,
        3,
      )}`;
    }
    return '';
  };

  return (
    <div className={styles.mobileDetails}>
      <div className={`content ${styles.cardContent}`}>
        <div className={styles.cardDetails}>
          <div className="ui grid">
            <div className="eight wide column">
              <div className={styles.label}>Abonnement</div>
              <div
                className={`${styles.value} ${styles.trim} ${styles.subscription}`}
              >
                {data.subscriptionName}
              </div>
            </div>
            <div className="eight wide column">
              <div className={styles.label}>Telefonnummer</div>
              <div className={styles.value}>
                {convertToPhoneNumber(data.phoneNumber)}
              </div>
            </div>
          </div>
          <div className={styles.contentSeparator} />
          <div className="ui grid">
            <div className="ten wide column">
              <div className={styles.label}>Eier</div>
              <div className={styles.value}>
                {account && account.fullName ? account.fullName : '-'}
              </div>
            </div>
          </div>
          <div className="ui grid">
            <div className="ten wide column">
              <div className={styles.label}>Adresse</div>
              <div className={styles.value}>
                {account && account.addresses && account.addresses.length
                  ? account.addresses[0].street
                  : '-'}
              </div>
            </div>
            <div className="six wide column">
              <div className={styles.label}>Postnummer</div>
              <div className={styles.value}>
                {account && account.addresses && account.addresses.length
                  ? account.addresses[0].postalCode
                  : '-'}
              </div>
            </div>
          </div>
          <div className={styles.contentSeparator} />
          <div className="ui grid">
            <div className="eight wide column">
              <div className={styles.label}>Data</div>
              <div className={styles.value}>
                {formatBytes(data.transferTotal * 1000000, 2, null, true)}
              </div>
            </div>
            <div className="eight wide column">
              <div className={styles.label}>Av data</div>
              <div className={styles.value}>
                {formatBytes(
                  data.transferTotal * 1000000 - data.transferUsed * 1000000,
                  2,
                  null,
                  true,
                )}
              </div>
            </div>
          </div>
          <div className={styles.contentSeparator} />
        </div>
      </div>
    </div>
  );
};

MobileDetails.propTypes = {
  data: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    account: state.global.entities.account,
  }),
  (dispatch) => ({
    actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch)),
  }),
)(MobileDetails);
