import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import styles from './noMatch.module.scss';

export default () => (
  <Container style={{ paddingTop: 190, paddingBottom: 190 }}>
    <Grid stackable style={{ justifyContent: 'center' }} verticalAlign="middle">
      <Grid.Row textAlign="center">
        <Grid.Column width="twelve">
          <img src="/assets/miss_logo.svg" alt="logo" />
        </Grid.Column>
        <Grid.Column width="twelve">
          <div className={styles.text}>
            Oi! Vi kunne ikke finne siden du leter etter
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
