import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Popup } from 'semantic-ui-react';
import 'react-circular-progressbar/dist/styles.css';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import { formatBytes } from '../../../../../utils/formatter';

import styles from './styles.module.scss';

const FAILSYNCVALUES = {
  used: 0,
  total: 100000 * 1000000,
};

class MobileDetailsCard extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  convertToPhoneNumber = (phone) => {
    return `${phone.substr(0, 3)} ${phone.substr(3, 2)} ${phone.substr(5, 3)}`;
  };

  getPercent = (used, total) => {
    return ((used * 100) / total).toFixed(0);
  };

  getDaysLeft = () => {
    const days = moment().daysInMonth();
    const day = +moment().format('DD');
    return days - day;
  };

  render() {
    const { data } = this.props;
    const { gettext } = this.context;
    return (
      <Card fluid className={styles.mobileCard}>
        <div className={`content ${styles.content}`}>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Telefonnummer')}</div>
                <div className={`${styles.value} ${styles.colored}`}>
                  {this.convertToPhoneNumber(data.phoneNumber)}
                </div>
              </div>
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>
                  {gettext('Forbruk i')}{' '}
                  <span className={styles.capitalize}>
                    {moment().format('MMM')}
                  </span>
                </div>
              </div>
              <div className={`${styles.valueContainer} ${styles.valueRight}`}>
                <div className={styles.label}>
                  {this.getDaysLeft()} {gettext('dager igjen')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.subscription}>
            {data.failSync ? (
              <div className={styles.offlineText}>
                <p>{gettext('Midlertidig utilgjengelig')}</p>
              </div>
            ) : null}
            <div
              className={`${styles.progress} ${
                data.failSync ? styles.blur : ''
              }`}
            >
              <div style={{ width: 120, height: 120 }}>
                <CircularProgressbarWithChildren
                  value={this.getPercent(
                    data.failSync ? FAILSYNCVALUES.used : data.transferUsed,
                    data.failSync ? FAILSYNCVALUES.total : data.transferTotal,
                  )}
                  styles={buildStyles({
                    pathColor: '#f75369',
                  })}
                >
                  <div className={styles.used}>
                    {formatBytes(
                      data.failSync
                        ? FAILSYNCVALUES.used
                        : data.transferUsed * 1000000,
                      2,
                      null,
                      true,
                    )}
                  </div>
                  <div className={styles.usedDesc}>{gettext('brukt')}</div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div
              className={`${styles.progressDetails} ${
                data.failSync ? styles.blur : ''
              }`}
            >
              <div className={styles.left}>
                {gettext('av')}{' '}
                {formatBytes(data.transferTotal * 1000000, 2, null, true)}
              </div>
            </div>
          </div>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Abonnement')}</div>
                <Popup
                  content={data.subscriptionName}
                  trigger={
                    <div className={`${styles.value} ${styles.trim}`}>
                      {data.subscriptionName}
                    </div>
                  }
                />
              </div>
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>{gettext('Data')}</div>
                <div className={styles.value}>
                  {formatBytes(data.transferTotal * 1000000, 2, null, true)}
                </div>
              </div>
              {data.failSync ? null : (
                <div className={styles.valueContainer}>
                  <div className={styles.label}>{gettext('Brukt')}</div>
                  <div className={styles.value}>
                    {formatBytes(data.transferUsed * 1000000, 2, null, true)}
                  </div>
                </div>
              )}
            </div>
            {/* <div className={styles.row}> */}
            {/*  <div className={styles.valueContainer}> */}
            {/*    <div className={styles.label}>Navn</div> */}
            {/*    <div className={styles.value}>{data.customer.name}</div> */}
            {/*  </div> */}
            {/*  <div className={styles.valueContainer}> */}
            {/*    <div className={styles.label}>Etternavn</div> */}
            {/*    <div className={styles.value}>{data.customer.surname}</div> */}
            {/*  </div> */}
            {/* </div> */}
            {/* <div className={styles.sep} /> */}
            {/* <div className={styles.row}> */}
            {/*  <div className={styles.valueContainer}> */}
            {/*    <div className={styles.label}>{gettext('Tale')}</div> */}
            {/*    <div className={styles.value}>{gettext('Fri Bruk')}</div> */}
            {/*  </div> */}
            {/*  <div className={styles.valueContainer}> */}
            {/*    <div className={styles.label}>{gettext('SMS/MMS')}</div> */}
            {/*    <div className={styles.value}>{gettext('Fri Bruk')}</div> */}
            {/*  </div> */}
            {/* </div> */}
          </div>
        </div>
      </Card>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      config: state.global.config,
    }),
    (dispatch) => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators({}, dispatch),
      ),
    }),
  )(MobileDetailsCard),
);

MobileDetailsCard.propTypes = {};
MobileDetailsCard.defaultProps = {};
