const data = {
  medium: {
    brebrand: [
      { price: '0,66', mb: '100' },
      { price: '0,24', mb: '200' },
      { price: '0,075', mb: '2 000' },
      { price: '0,015', mb: '10 000' },
      { price: '0,008', mb: '>10 000' },
    ],
    tilleggstjenester: [
      { name: 'Endring av abonnement', prisPrMnd: '-', engangspris: '0,-' },
      {
        name: 'Mobilsvar',
        prisPrMnd: 'Helt gratis!',
        engangspris: 'Helt gratis!',
      },
      {
        name: 'BankID på mobil',
        prisPrMnd: 'Helt gratis!',
        engangspris: 'Helt gratis!',
      },
      {
        name: 'AvtaleGiro',
        prisPrMnd: 'Helt gratis!',
        engangspris: 'Helt gratis!',
      },
      {
        name: 'Faktura per e-post',
        prisPrMnd: 'Helt gratis!',
        engangspris: 'Helt gratis!',
      },
      {
        name: 'Tvilling- og Datatvillingkort',
        prisPrMnd: '29.-',
        engangspris: '199,-',
      },
      { name: 'Faktura per post', prisPrMnd: '65,-', engangspris: '-' },
      {
        name: 'Etablering ved nytt nummer',
        prisPrMnd: '-',
        engangspris: '199,-',
      },
      {
        name: 'Data-SIM etablering',
        prisPrMnd: '-',
        engangspris: '199,-',
      },
      { name: 'Hemmelig nummer', prisPrMnd: '-', engangspris: '249,-' },
      { name: 'Erstatnings SIM-kort', prisPrMnd: '-', engangspris: '199,-' },
      {
        name: 'Gebyr ved sperret abonnement',
        prisPrMnd: '-',
        engangspris: '299,-',
      },
    ],
    etterpakke: [
      {
        name: 'Minuttpris etter oppbrukt pakke',
        price: '1,49,-(0,99 for Flex)',
      },
      {
        name: 'Startpris pr samtale - gjelder kun etter oppbrukt pakke',
        price: '1,99,- (0,99 for Flex)',
      },
      {
        name:
          'Minuttpris og startpris til familiemedlemmer med Scoopr abonnement',
        price: '0,- (0,99 for Flex)',
      },
      {
        name: 'Minuttpris og startpris til andre Scoopr kunder',
        price: '0,- (0,99 for Flex)',
      },
      { name: 'SMS etter oppbrukt pakke', price: '1,99,- (0,99 for Flex)' },
      { name: 'MMS etter oppbrukt pakke', price: '3,99,- (0,99 for Flex)' },
      {
        name: 'Data per megabyte etter oppbrukt pakke*',
        price: '2,49,-(Variabel pris med Flex)',
      },
      { name: 'Videosamtaler', price: '2,99,- (oppstart 0,99 per samtale)' },
      {
        name: 'Mobilsvar - Sett over fra mobilsvar',
        price: '3,74,- (Minuttpris samme som på ditt abonnement)',
      },
    ],
    threeSiffer: [
      {
        name: 'Alarmnummer (Brann 110, Politi 112, Ambulanse 113)',
        oppkobling: '0,00',
        price: '0,00',
      },
      {
        name: '175, 177	',
        oppkobling: '0,00 - Prises som normale norske nummer',
        price: '0,00 - Prises som normale norske nummer',
      },
      {
        name: 'Andre 3-sifrede nummer',
        oppkobling: '0,00 - Prises som normale norske nummer',
        price: '0,00 - Prises som normale norske nummer',
      },
    ],
    fourSiffer: [
      {
        name: '1412 - Nødnummer for døve',
        oppkobling: '0,00',
        price: '0,00',
      },
      {
        name: '1860 - Nummeropplysning',
        oppkobling: '8,30',
        price: '27,00',
      },
      {
        name: '1880 - Nummeropplysning',
        oppkobling: '24,60',
        price: '43,38',
      },
      {
        name: '1881 - Nummeropplysning',
        oppkobling: '22,30',
        price: '43,25',
      },
      {
        name: '1882 - Nummeropplysning',
        oppkobling: '22,30',
        price: '43,25',
      },
      {
        name: '1885 - Nummeropplysning	',
        oppkobling: '19,20',
        price: '32,88',
      },
      {
        name: '1888 - Nummeropplysning	',
        oppkobling: '31,80',
        price: '19,75',
      },
      {
        name: '1890 - Nummeropplysning',
        oppkobling: '22,30',
        price: '31,00',
      },
      {
        name: '"Sett over" fra nummeropplysning',
        oppkobling: '19,80',
        price: '',
      },
      {
        name: 'Nummeropplysning IN 1945 (Telenor operatøropplysning)',
        oppkobling: '1,99',
        price: '1.24',
      },
      {
        name: '116 xxx (Harmonzied European Short Codes)',
        oppkobling: '1,99',
        price: '1.24',
      },
    ],
    eightHundred: [
      {
        name: '800 xx xxx',
        oppkobling: '0,00',
        price: '0,00',
      },
      {
        name: '810 xx xxx',
        oppkobling: '0,00',
        price: '0,00',
      },
      {
        name: '811 xx xxx – 814 xx xxx',
        oppkobling: '1,99',
        price: '1.24',
      },

      {
        name: '815 xx xxx',
        oppkobling: '0,00 - Prises som normale norske nummer',
        price: '0,00 - Prises som normale norske nummer',
      },
      {
        name: '819 xx xxx',
        oppkobling: '1,99',
        price: '1.24',
      },
      {
        name: '820/829 0x xxx',
        oppkobling: '0,99',
        price: '10,99',
      },
      {
        name: '820/829 1/2x xxx',
        oppkobling: '0,99',
        price: '15,99',
      },
      {
        name: '820/829 3x xxx',
        oppkobling: '0,99',
        price: '7,99',
      },
      {
        name: '820 40/45 xxx, untatt 820 45 5xx**',
        oppkobling: 25,
        price: '4,99',
      },
      {
        name: '820 41/46 xxx, untatt 820 46 5xx**',
        oppkobling: 50,
        price: '4,99',
      },
      {
        name: '820 45 5xx**',
        oppkobling: 30,
        price: '4,99',
      },
      {
        name: '820 46 5xx**',
        oppkobling: 60,
        price: '4,99',
      },
      {
        name: '820 42/47 xxx**',
        oppkobling: 75,
        price: '4,99',
      },
      {
        name: '820 43/48 xxx**',
        oppkobling: 100,
        price: '4,99',
      },
      {
        name: '820 44/49 xxx**',
        oppkobling: 200,
        price: '4,99',
      },

      {
        name: '822/00-01',
        oppkobling: 125,
        price: '0,00',
      },

      {
        name: '822/02-03',
        oppkobling: 187.5,
        price: '0,00',
      },
      {
        name: '822/04-05',
        oppkobling: 250,
        price: '0,00',
      },
      {
        name: '822/06-07',
        oppkobling: 312.5,
        price: '0,00',
      },
      {
        name: '822/08-09',
        oppkobling: 375,
        price: '0,00',
      },

      {
        name: '829 40-46 xxx',
        oppkobling: '1,49',
        price: '3,99',
      },
      {
        name: '829 47-48 xxx***',
        oppkobling: '2,99',
        price: '3,99',
      },
      {
        name: '829 49 xxx***',
        oppkobling: '5,99',
        price: '3,99',
      },
      {
        name: '820/829 5x xxx',
        oppkobling: '1,49',
        price: '9,49',
      },
      {
        name: '820/829 6x xxx',
        oppkobling: '1,49',
        price: '11,49',
      },
      {
        name: '820/829 7x xxx',
        oppkobling: '1,49',
        price: '16,99',
      },
      {
        name: '820/829 8x xxx',
        oppkobling: '1,49',
        price: '23,99',
      },
      {
        name: '820/829 9x xxx',
        oppkobling: '1,49',
        price: '27,99',
      },
      {
        name: '85x xx xxx',
        oppkobling: '0,00 - Prises som normale norske nummer',
        price: '0,00 - Prises som normale norske nummer',
      },
      {
        name: '878 xx xxx',
        oppkobling: '1,49',
        price: '2,99',
      },
      {
        name: '880 xx xxx',
        oppkobling: '1,49',
        price: '2,99',
      },
    ],
    satelite: [
      {
        name: 'Aeromobile',
        oppkobling: '1,99',
        price: '10,60',
        pricein: '-',
      },
      {
        name: 'Globalstar',
        oppkobling: '1,99',
        price: '29,60',
        pricein: '-',
      },
      {
        name: 'Inmarsat Aero',
        oppkobling: '1,99',
        price: '59.8',
        pricein: '-',
      },
      {
        name: 'Inmarsat B',
        oppkobling: '1,99',
        price: '25.9',
        pricein: '-',
      },
      {
        name: 'Inmarsat B GAN',
        oppkobling: '1,99',
        price: '46.6',
        pricein: '-',
      },
      {
        name: 'Inmarsat B HSD',
        oppkobling: '1,99',
        price: '78.4',
        pricein: '-',
      },
      {
        name: 'Immarsat B GAN HSD',
        oppkobling: '1,99',
        price: '61.1',
        pricein: '-',
      },
      {
        name: 'Inmarsat GAN/Fleet/swift',
        oppkobling: '1,99',
        price: '74.4',
        pricein: '-',
      },
      {
        name: 'Inmarsat M',
        oppkobling: '1,99',
        price: '25.6',
        pricein: '-',
      },
      {
        name: 'Inmarsat Mini-M',
        oppkobling: '1,99',
        price: '25.6',
        pricein: '-',
      },
      {
        name: 'Iridium 8816',
        oppkobling: '1,99',
        price: '38.4',
        pricein: '-',
      },
      {
        name: 'Irridium 8817',
        oppkobling: '1,99',
        price: '40.2',
        pricein: '-',
      },
      {
        name: 'MCP',
        oppkobling: '1,99',
        price: '14.5',
        pricein: '-',
      },
      {
        name: 'Onair',
        oppkobling: '1,99',
        price: '19.9',
        pricein: '-',
      },
      {
        name: 'Oration',
        oppkobling: '1,99',
        price: '29.5',
        pricein: '-',
      },
      {
        name: 'Satellite Services COM4 M2M Non-Geografic',
        oppkobling: '1,99',
        price: '0.99',
        pricein: '-',
      },
      {
        name: 'Satellite Services Germany  Non-Geografic',
        oppkobling: '1,99',
        price: '0.99',
        pricein: '-',
      },
      {
        name: 'Satellite Services Orange Non-Geografic',
        oppkobling: '1,99',
        price: '0.99',
        pricein: '-',
      },
      {
        name: 'Telenor Connexion (M2M)',
        oppkobling: '1,99',
        price: '1.99',
        pricein: '-',
      },
      {
        name: 'Thuraya UAE',
        oppkobling: '1,99',
        price: '14.4',
        pricein: '-',
      },
      {
        name: 'United Nations',
        oppkobling: '1,99',
        price: '0.49',
        pricein: '-',
      },
      {
        name: 'Vodafone M2M',
        oppkobling: '1,99',
        price: '2.99',
        pricein: '-',
      },
    ],
    mcp: [
      {
        name: 'MCP Offshore',
      },
      {
        name: 'Oppkobling',
        price: '0,99',
      },
      {
        name: 'Ringe til Norge/EU',
        price: '1,29',
      },
      {
        name: 'Ringe til øvrige land i Europa',
        price: '19,99',
      },
      {
        name: 'Ringe til resten av verden',
        price: '29,99',
      },
      {
        name: 'Mottak av samtaler',
        price: '1,29',
      },
      {
        name: 'Sende SMS',
        price: 5,
      },
      {
        name: 'Motta SMS',
        price: '-',
      },
      {
        name: 'Sende MMS',
        price: 10,
      },
      {
        name: 'Motta MMS',
        price: 5,
      },
      {
        name: 'Datatrafikk (per MB)',
        price: '2,49',
      },
      {
        name: 'MCP skip, Norden',
      },
      {
        name: 'Oppkobling',
        price: 0,
      },
      {
        name: 'Ringe til Norge/EU',
        price: '13,99',
      },
      {
        name: 'Ringe til øvrige land i Europa',
        price: '19,99',
      },
      {
        name: 'Ringe til resten av verden',
        price: '29,99',
      },
      {
        name: 'Mottak av samtaler',
        price: '12,99',
      },
      {
        name: 'Sende SMS',
        price: 5,
      },
      {
        name: 'Motta SMS',
        price: 0,
      },
      {
        name: 'Sende MMS',
        price: 10,
      },
      {
        name: 'Motta MMS',
        price: 5,
      },
      {
        name: 'Datatrafikk (per MB)',
        price: 19,
      },
      {
        name: 'MCP skip, resten av verden',
      },
      {
        name: 'Oppkobling',
        price: 0,
      },
      {
        name: 'Ringe til Norge/EU',
        price: 55,
      },
      {
        name: 'Ringe til øvrige land i Europa',
        price: 55,
      },
      {
        name: 'Ringe til resten av verden',
        price: 55,
      },
      {
        name: 'Mottak av samtaler',
        price: 55,
      },
      {
        name: 'Sende SMS',
        price: 5,
      },
      {
        name: 'Motta SMS',
        price: 0,
      },
      {
        name: 'Sende MMS',
        price: 10,
      },
      {
        name: 'Motta MMS',
        price: 5,
      },
      {
        name: 'Datatrafikk (per MB)',
        price: 55,
      },
      {
        name: 'MCP Fly',
      },
      {
        name: 'Oppkobling',
        price: 0,
      },
      {
        name: 'Ringe til Norge/EU',
        price: '11,99',
      },
      {
        name: 'Ringe til øvrige land i Europa',
        price: '11,99',
      },
      {
        name: 'Ringe til resten av verden',
        price: '19,99',
      },
      {
        name: 'Mottak av samtaler',
        price: '11,99',
      },
      {
        name: 'Sende SMS',
        price: 5,
      },
      {
        name: 'Motta SMS',
        price: 0,
      },
      {
        name: 'Sende MMS',
        price: 10,
      },
      {
        name: 'Motta MMS',
        price: 10,
      },
      {
        name: 'Datatrafikk (per MB)',
        price: 4.99,
      },
    ],
    sone: [
      {
        sone: 'Sone 1 - Norden',
        price: '2,29',
      },
      {
        sone: 'Sone 2 - Europa',
        price: '2,29',
      },
      {
        sone: 'Sone 3 - Verden 1',
        price: '3,99',
      },
      {
        sone: 'Sone 4 - Verden 2',
        price: '6,99',
      },
      {
        sone: 'Sone 5 - Verden 3',
        price: '9,99',
      },
      {
        sone: 'Sone 6 - Verden 4',
        price: '13,99',
      },
      {
        sone: 'SMS til EU/EØS land',
        price: '0,73',
      },
      {
        sone: 'SMS til resten av verden',
        price: '3,99',
      },
      {
        sone: 'Pr MMS til alle land',
        price: '5,99',
      },
    ],
    utland: [
      {
        sone: 'Sone 1 (inkl. EU/EØS)',
        prMinutt: '0,00',
        prMinuttMottak: '0,00',
        dataPrMb: '0,00',
        prSms: '0,00',
        prSmsMottak: '0,00',
        prMms: '0,00',
        prMmsMottak: '0,00',
      },
      {
        sone: 'Sone 2',
        prMinutt: '2,49',
        prMinuttMottak: '2,49',
        dataPrMb: '0,99 (Datapakker fra 49,-)',
        prSms: '2,49',
        prSmsMottak: '0,00',
        prMms: '4,99',
        prMmsMottak: '2,49',
      },
      {
        sone: 'Sone 3',
        prMinutt: '5,99',
        prMinuttMottak: '4,99',
        dataPrMb: '1,99',
        prSms: '2,49',
        prSmsMottak: '0,00',
        prMms: '4,99',
        prMmsMottak: '2,49',
      },
      {
        sone: 'Sone 4',
        prMinutt: '14,99',
        prMinuttMottak: '4,99',
        dataPrMb: '14,99',
        prSms: '3,99',
        prSmsMottak: '0,00',
        prMms: '4,99',
        prMmsMottak: '2,49',
      },
    ],
    priceInNorway: [
      {
        priserINorge: 'Månedspris',
        flexZero: '0,-',
        flexFree: '99,-',
      },
      {
        priserINorge: 'Etablering',
        flexZero: '149,-',
        flexFree: '0,-',
      },
      {
        priserINorge: 'Startpris per samtale',
        flexZero: '0,99,-',
        flexFree: '0*',
      },
      {
        priserINorge: 'Minuttpris',
        flexZero: '0,99,-',
        flexFree: '0*',
      },
      {
        priserINorge: 'Pris per SMS',
        flexZero: '0,99,-',
        flexFree: '0*',
      },
      {
        priserINorge: 'Pris per MMS',
        flexZero: '0,99,-',
        flexFree: '0*',
      },
    ],
    specialNumbers: [
      { name: 'SMS til 3-sifrede nummer', price: '1' },
      { name: 'SMS til 4-sifrede nummer', price: '1' },
      { name: 'SMS til 5-sifrede nummer', price: '1' },
    ],
    data: [
      {
        prisDatatrafikkIntervallprisPerMb: '- 100 MB',
        perMb: '0,70 kr',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '100 – 200 MB',
        perMb: '0,26 kr',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '200 MB – 2 GB',
        perMb: '0,075 kr',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '2 GB – 10 GB',
        perMb: '0,015 kr',
      },
      {
        prisDatatrafikkIntervallprisPerMb: 'Over 10 GB',
        perMb: '0,008 kr',
      },
      {
        prisDatatrafikkIntervallprisPerMb: 'Priseks. ved ulike volum',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '0 MB',
        flexZero: '0,-',
        flexFreeInkl99IMndavgift: '99,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '100 MB',
        flexZero: '70,-',
        flexFreeInkl99IMndavgift: '169,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '200 MB',
        flexZero: '96,-',
        flexFreeInkl99IMndavgift: '195,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '500 MB',
        flexZero: '118,50',
        flexFreeInkl99IMndavgift: '217,50',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '750 MB',
        flexZero: '137,25',
        flexFreeInkl99IMndavgift: '236,25',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '1 GB',
        flexZero: '156,-',
        flexFreeInkl99IMndavgift: '255,00',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '1,25 GB',
        flexZero: '174,75',
        flexFreeInkl99IMndavgift: '273,75',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '1,5 GB',
        flexZero: '193,50',
        flexFreeInkl99IMndavgift: '292,50',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '1,75 GB',
        flexZero: '212,25',
        flexFreeInkl99IMndavgift: '311,25',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '2 GB',
        flexZero: '231,-',
        flexFreeInkl99IMndavgift: '330,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '2,5 GB',
        flexZero: '238,50',
        flexFreeInkl99IMndavgift: '337,50',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '3 GB',
        flexZero: '246,00',
        flexFreeInkl99IMndavgift: '345,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '3,5 GB',
        flexZero: '253,50',
        flexFreeInkl99IMndavgift: '352,50',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '4 GB',
        flexZero: '261,-',
        flexFreeInkl99IMndavgift: '360,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '4,5 GB',
        flexZero: '268,50',
        flexFreeInkl99IMndavgift: '367,50',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '5 GB',
        flexZero: '276,-',
        flexFreeInkl99IMndavgift: '375,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '6 GB',
        flexZero: '291,-',
        flexFreeInkl99IMndavgift: '390,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '7 GB',
        flexZero: '306,-',
        flexFreeInkl99IMndavgift: '405,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '8 GB',
        flexZero: '321,-',
        flexFreeInkl99IMndavgift: '420,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '9 GB',
        flexZero: '336,-',
        flexFreeInkl99IMndavgift: '435,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '10 GB',
        flexZero: '351,-',
        flexFreeInkl99IMndavgift: '450,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '11 GB',
        flexZero: '359,-',
        flexFreeInkl99IMndavgift: '458,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '12 GB',
        flexZero: '367,-',
        flexFreeInkl99IMndavgift: '466,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '13 GB',
        flexZero: '375,-',
        flexFreeInkl99IMndavgift: '474,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '14 GB',
        flexZero: '383,-',
        flexFreeInkl99IMndavgift: '482,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '15 GB',
        flexZero: '391,-',
        flexFreeInkl99IMndavgift: '490,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '16 GB',
        flexZero: '399,-',
        flexFreeInkl99IMndavgift: '498,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '17 GB',
        flexZero: '407,-',
        flexFreeInkl99IMndavgift: '506,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '18 GB',
        flexZero: '415,-',
        flexFreeInkl99IMndavgift: '514,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '19 GB',
        flexZero: '423,-',
        flexFreeInkl99IMndavgift: '522,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '20 GB',
        flexZero: '431,-',
        flexFreeInkl99IMndavgift: '530,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '30 GB',
        flexZero: '511,-',
        flexFreeInkl99IMndavgift: '610,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '40 GB',
        flexZero: '591,-',
        flexFreeInkl99IMndavgift: '690,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '50 GB',
        flexZero: '671,-',
        flexFreeInkl99IMndavgift: '770,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '60 GB',
        flexZero: '751,-',
        flexFreeInkl99IMndavgift: '850,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '70 GB',
        flexZero: '831,-',
        flexFreeInkl99IMndavgift: '930,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '80 GB',
        flexZero: '911,-',
        flexFreeInkl99IMndavgift: '1010,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '90 GB',
        flexZero: '991,-',
        flexFreeInkl99IMndavgift: '1090,-',
      },
      {
        prisDatatrafikkIntervallprisPerMb: '100 GB',
        flexZero: '1071,-',
        flexFreeInkl99IMndavgift: '1170,-',
      },
    ],
  },
};
export default data;
