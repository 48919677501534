import React, { useEffect, useState } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageLoader from '../../components/PageLoader/pageLoader';
import HtmlParse from '../../components/HtmlParser/htmlParser';
import styles from '../Content/content.module.scss';
import ChatContainer from '../../components/ChatContainer';
import useGetPostBySlug from '../../hooks/gql/GetPostBySlug.query';
import { pushEventToTagManager } from '../../utils/analytics';
import Page from '../../components/Page/Page';

const TYPES = {
  mobile: {
    flow: 'mobile_onboarding',
    step: 'init_with_fixed_start',
    redirect: '/mobilabonnement',
    name: 'Mobilabonnement',
  },
  mobilabonnement: {
    flow: 'mobile_onboarding',
    step: 'init_with_fixed_start',
    redirect: '/mobilabonnement',
    name: 'Mobilabonnement',
  },
  mobil: {
    flow: 'mobile_onboarding',
    step: 'init_with_fixed_start',
    redirect: '/mobilabonnement',
    name: 'Mobilabonnement',
  },
  forsikring: {
    flow: 'insurance_leads',
    step: 'init_with_fixed_start',
    redirect: '/forsikring',
    name: 'Forsikring',
  },
  insurance: {
    flow: 'insurance_leads',
    step: 'init_with_fixed_start',
    redirect: '/forsikring',
    name: 'Forsikring',
  },
  refinansiering: {
    flow: 'debt_check',
    step: 'skip_start',
    redirect: '/refinansiering',
    name: 'Refinansiering',
  },
  refinancing: {
    flow: 'debt_check',
    step: 'skip_start',
    redirect: '/refinansiering',
    name: 'Refinansiering',
  },
};

const Faq = () => {
  const location = useLocation();
  const history = useHistory();
  const { articleName } = useParams();
  const split =
    location && location.pathname ? location.pathname.split('/') : null;
  const type = split && split.length ? TYPES[split[1]] : null;
  const { data, loading, error } = useGetPostBySlug(articleName);

  useEffect(() => {
    if (error) {
      history.push(type.redirect);
    }
  }, error);

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page loading={loading} title={data.title || null}>
      <div className={`${styles.contentContainer} ${styles.darkContainer}`}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className={styles.breadcrumbs}>
                  <a href="/" className={styles.link}>
                    Hjem
                  </a>
                  <span className={styles.slash}>/</span>
                  <a href={type.redirect || '/'} className={styles.link}>
                    {type.name || ''}
                  </a>
                  <span className={styles.slash}>/</span>
                  <span className={styles.active}>
                    {HtmlParse({
                      data: data.title || '',
                    })}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={`${styles.header} ${styles.centered}`}>
                  <h1 className={styles.title}>
                    {HtmlParse({
                      data: data.title || '',
                    })}
                  </h1>
                  <div className={styles.subtitle}>
                    {HtmlParse({
                      data: data.subtitle || '',
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <div className={styles.text}>
                  {HtmlParse({
                    data: data.content || '',
                  })}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container className={styles.removeMargin}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                style={{ padding: '0px' }}
              >
                <ChatContainer
                  flow={type.flow}
                  step={type.step}
                  chatH2="chat2"
                  chatSideBody=""
                  firstMessage={
                    type.name === 'Refinansiering'
                      ? 'Sjekk dine gjeldsopplysninger i Gjeldsregisteret med BankID her, del disse med oss for å se hvor mye du kan spare på dine lån og kredittkort'
                      : null
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </Page>
  );
};

export default Faq;
