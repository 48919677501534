import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';

import SplashScreen from './components/SplashScreen';

class Splash extends React.Component {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displaySplash: false,
    };
  }

  render() {
    const { gettext } = this.context;

    return (
      <Container>
        <CSSTransition
          in={this.props.config.displaySplash}
          classNames="splash"
          timeout={{ enter: 1, exit: 1000 }}
          // timeout={1}
          unmountOnExit
        >
          <SplashScreen splashMessages={gettext('Welcome to Scoopr')} />
        </CSSTransition>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    config: state.global.config,
  }),
  (dispatch) => ({
    actions: Object.assign({}, { dispatch }, bindActionCreators({}, dispatch)),
  }),
)(Splash);
