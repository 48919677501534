import React from 'react'

const TopSaleSection = {
  'Flex Free': (
    <div>
      <h2>
        Med våre Flex-abonnement betaler du kun for data du faktisk bruker. Du
        trenger ikke å gjette hvor mye data du trenger. Flex-abonnementene er
        like fleksible som deg, og det beste er at dataprisene synker jo mer du
        bruker.
      </h2>
    </div>
  ),
  'Flex Zero': (
    <div>
      <h2>
        Med våre Flex-abonnement betaler du kun for data du faktisk bruker. Du
        trenger ikke å gjette hvor mye data du trenger. Flex-abonnementene er
        like fleksible som deg, og det beste er at dataprisene synker jo mer du
        bruker.
      </h2>
    </div>
  ),
  '0 MB': (
    <div>
      <h2>
        Sjekk prisen! Perfekt for deg som vil være tilgjengelig, ringe og tekste
        litt, og ikke trenger data inkludert. Gratis å ringe andre Hudya-kunder.
        Passer også til alarmer, hytteovner m.m.
      </h2>
      <p>
        50 min tale, 50 SMS, 50 MMS og 0 MB data. Surfer du, så er makspris på
        data 399,- per mnd. Se alle priser inklusiv utlandspriser og
        spesialnummer her.
      </p>
      <FeatureTableBasisMedium />
    </div>
  ),
  '300 MB': (
    <div>
      <h2>
        Førstevalget for deg som vil ha litt av alt! Du kan ringe, sende SMS og
        MMS, og surfe litt av og til
      </h2>
      <p>
        Gratis å ringe andre Hudya-kunder. 150 min tale, 50 SMS, 50 MMS og 300
        MB data. Surfer du, så er makspris på data 399,- per mnd. Se alle priser
        inklusiv utlandspriser og spesialnummer her
      </p>
      <FeatureTableBasisMedium />
    </div>
  ),
  '500 MB': (
    <div>
      <h2>
        Et av våre mest solgte produkter. Passer for deg som bruker telefonen
        mye men surfer lite
      </h2>
      <p>
        Fri bruk av tale/SMS/MMS og 500MB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  'Barn 1 GB': (
    <div>
      <h2>Trygt mobilabonnement for barn under 13 år</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 1GB data i Norge og i EU med Datakontroll
      </p>
      <FeatureTableChild />
    </div>
  ),
  '1 GB': (
    <div>
      <h2>Et av våre mest populære og prisgunstige produkter</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 1GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  '2 GB': (
    <div>
      <h2>For deg som surfer av og til!</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 3GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  '3 GB': (
    <div>
      <h2>For deg som surfer av og til!</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 2GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  '6 GB': (
    <div>
      <h2>For deg som surfer og streamer litt hver dag!</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 6 GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  '12 GB': (
    <div>
      <h2>For deg som liker å surfe på nett og streame video og musikk!</h2>
      <p>
        Fri bruk av tale/SMS/MMS og 12 GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  ),
  '30 GB': (
    <div>
      <h2>
        Perfekt for deg som er storforbruker av data. Her får du hele 30 GB!
      </h2>
      <p>
        Fri bruk av tale/SMS/MMS og 30 GB data i Norge og i EU. Makspris data
        399,- per mnd. Se alle priser inklusiv utlandspriser og spesialnummer
        her.
      </p>
      <FeatureTable />
    </div>
  )
}
export default TopSaleSection
function FeatureTable() {
  return (
    <ul>
      <li>Fri bruk av Tale/SMS/MMS i Norge og i EU</li>
      <li>Data Rollover</li>
      <li>Gratis BankID, Ingen bindingstid eller etablering</li>
      <li>
        Full Telenor-dekning med HD-Voice - 4G tale kan bestilles uten kostnad.
        Fri hastighet
      </li>
      <li>Mulighet for å legge på Datakontroll</li>
      <li>Mulighet for å kjøpe ekstra datapakker via SMS</li>
      <li>
        Makspris data 399,-. Du blir ikke fakturert over 399,- totalt for
        dataforbruk på en måned
      </li>
      <li>Hyggelig og effektiv kundeservice</li>
      <li>Utlandspriser og spesialnummer kommer i tillegg - se alle priser</li>
      <li>
        Min side - administrer ditt abonnement og få oversikt over forbruk og
        faktura
      </li>
    </ul>
  )
}
function FeatureTableBasisMedium() {
  return (
    <ul>
      <li>Lav månedskostnad!</li>
      <li>Gratis å ringe til familiemedlemmer som har Hudya</li>
      <li>Gratis å ringe til andre med Hudya-abonnement</li>
      <li>Gratis BankID - ingen bindingstid</li>
      <li>Telenor-dekning med HD-Voice - 4G-Tale kan bestilles uten kostnad</li>
      <li>Mulighet for å kjøpe ekstra datapakker via SMS</li>
      <li>4G+ fri hastighet - hastigheten senkes når du har brukt 1 GB</li>
      <li>
        Makspris data 399,-. Du blir ikke fakturert over 399,- totalt for
        dataforbruk på en måned
      </li>
      <li>Hyggelig og effektiv kundeservice</li>
      <li>Utlandspriser og spesialnummer kommer i tillegg - se alle priser</li>
      <li>
        Min side - administrer ditt abonnement og få oversikt over forbruk og
        faktura
      </li>
    </ul>
  )
}
function FeatureTableChild() {
  return (
    <ul>
      <li>Fri bruk av Tale/SMS/MMS i Norge og i EU/EØS</li>
      <li>Sperre for alle dyre mobiltjenester</li>
      <li>Gratis BankID - ingen bindingstid</li>
      <li>
        Telenor-dekning med HD-Voice - 4G-Tale kan bestilles uten kostnad. fri
        hastighet
      </li>
      <li>Mulighet for å kjøpe ekstra datapakker via SMS</li>
      <li>
        Automatisk aktivert Datakontroll forhindrer uforventet overforbruk av
        data
      </li>
      <li>Mulighet for å kjøpe ekstra datapakker via SMS</li>
      <li>Hyggelig og effektiv kundeservice</li>
      <li>Utlandspriser og spesialnummer kommer i tillegg - se alle priser</li>
      <li>
        Min side - administrer ditt abonnement og få oversikt over forbruk og
        faktura
      </li>
      <li>
        Dette abonnementet har ikke Data Rollover inkludert. Velg eventuelt Fri
        1GB for å få Data Rollover
      </li>
    </ul>
  )
}
