import React from 'react'
import PropTypes from 'prop-types'

import PageLoader from '../PageLoader/pageLoader'

import styles from './styles.module.scss'

const ProductCard = ({
  loading,
  title,
  text,
  buttonText,
  buttonUrl,
  moreText,
  moreAction,
  image,
}) => {
  return (
    <div className={styles.cardContainer}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <PageLoader fill loading />
        </div>
      ) : (
        <div className={styles.content}>
          <h1>{title}</h1>
          <p className={styles.text}>{text}</p>
          <div className={styles.image}>
            <img src={image} alt="icon" />
          </div>
          <a href={buttonUrl} className={styles.button}>
            {buttonText}
          </a>
          <button
            type="button"
            className={styles.moreButton}
            onClick={() => moreAction()}
          >
            {moreText}
          </button>
        </div>
      )}
    </div>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  moreText: PropTypes.string.isRequired,
  moreAction: PropTypes.func.isRequired,
  image: PropTypes.node.isRequired,
  loading: PropTypes.bool,
}
ProductCard.defaultProps = {
  loading: false,
}

export default ProductCard
