import formatMessage from 'format-message'

/**
 * Truncates a string to given length, adding ellipsis.
 * @param n: Maximum length of output string, including ellipsis.
 * @param wordBoundary: Boolean indicating if we should split
 *                      at nearest word boundary. Defaults to `true`.
 */
String.prototype.trunc = function trunc(n, wordBoundary = true) {
  if (this.length <= n) {
    return this
  }
  wordBoundary = this.lastIndexOf(' ') === -1 ? false : wordBoundary

  const subString = this.substr(0, wordBoundary ? n - 1 : n - 3)

  return wordBoundary
    ? `${subString.substr(0, subString.lastIndexOf(' '))}...`
    : `${subString}...`
}

/**
 * Capitalize the first letter of each word in the string.
 */
String.prototype.capitalize = function capitalize() {
  return this.toLowerCase()
    .split(' ')
    .map(s => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    })
    .join(' ')
}

/**
 * Helper function used to format the string with a context object.
 *
 * @param str: String which will be formatted.
 * @param context: Object containing the context for formatting.
 * @returns: Formatted string.
 */
export const fm = (str, context) => {
  let translatable = str

  if (translatable instanceof Array) {
    translatable = translatable.find(item => typeof item === 'string')
  }

  return formatMessage(translatable, context)
}
