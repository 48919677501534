import { useEffect, useState } from 'react';

import useWPService from './useWPService';

const useWPContent = (page) => {
  const wpService = useWPService();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const result = await wpService.getPage(page);
      if (result.status) {
        setData(result.response);
      }
      setLoading(false);
    };

    fetchData();
  }, []);
  return { loading, data };
};

export default useWPContent;
