import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetPostBySlug = (slug) => {
  let { loading, error, data } = useQuery(
    gql`
      query getPostBySlug($slug: String) {
        post: postBy(slug: $slug) {
          id
          content
          categories {
            category: nodes {
              id: categoryId
              name
            }
          }
          title
          status
          uri
          slug
        }
      }
    `,
    {
      variables: { slug },
    },
  );

  if (data && data.post && data.post.id) {
    data = data.post;
  }

  return { loading, error, data };
};

export default useGetPostBySlug;
