import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

const Utterance = (props) => {
  const [open, setOpen] = React.useState(props.visible)
  if (open != props.visible) setOpen(props.visible)
  if (props.inputType === 'xredirect') {
    window.location.href = props.utterance
  }
  return (
    <CSSTransition
      appear
      classNames="utterance"
      in={true}
      timeout={{
        enter: 500,
        exit: 500,
      }}
      unmountOnExit
    >
      <div
        className={
          props.inputType === 'iframe'
            ? '' //'chat-bubble-iframe'
            : props.userResponse
            ? 'chat-bubble-user'
            : 'chat-bubble'
        }
      >
        <div className="loading">
          <div className="dot dot--one" />
          <div className="dot dot--two" />
          <div className="dot dot--three" />
        </div>
        {props.inputType === 'text' && (
          <ReactMarkdown
            source={props.utterance}
            escapeHtml={false}
            linkTarget="_blank"
          />
        )}

        {props.inputType === 'iframe' && (
          <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
            long
          >
            <Modal.Content>
              <div style={{ minHeight: 5000 }}>
                <iframe
                  src={props.utterance + '&localv=1'}
                  title="Gjeldsregisteret"
                  width="100%"
                  height="600px"
                ></iframe>
              </div>
            </Modal.Content>
          </Modal>
        )}
      </div>
    </CSSTransition>
  )
}

Utterance.propTypes = {
  userResponse: PropTypes.bool,
  utterance: PropTypes.string.isRequired,
}
Utterance.defaltProps = {
  userResponse: false,
}
export default Utterance
