import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetMobileSubscriptionsQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getMobileSubscriptions {
        res: pageBy(uri: "mobile-subscriptions") {
          status
          content
          title
          resContent: mobileSubscriptions {
            title
            subscriptions {
              goalFlow: goalflow
              leftBoxLabel: leftboxlabel
              leftBoxValue: leftboxvalue
              moreButtonText: morebuttontext
              moreLink: morelink
              orderButtonText: orderbuttontext
              price
              priceText: pricetext
              rightBoxLabel: rightboxlabel
              rightBoxValue: rightboxvalue
              step
              subtitle
              title
              productName: productname
            }
          }
        }
      }
    `,
    {
      variables: {},
    },
  );
  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.resContent };
      return { loading, error, data };
    }
  }

  data = {
    title: 'Mobil',
    content: '',
    subscriptions: [],
  };

  return { loading, error, data };
};

export default useGetMobileSubscriptionsQuery;
