import jwtDecode from 'jwt-decode'

import { authAPI } from '../webapi/auth'
import { vippsAuthAPI } from '../webapi/auth/vippsLogin'
import { accountAPI } from '../webapi/account'
import config from '../../config'

function evaluateGeneralConsent(acceptanceTags) {
  return acceptanceTags.includes('account_dialog_consent:2017-10-31:')
}
function evaluateteTelipolConsent(acceptanceTags) {
  return acceptanceTags.includes('telipol_t_c:2018-07-20')
}
function evaluatJwtFromLocal(jwt) {
  const decoded = jwtDecode(jwt)

  return true
}
export const RESET_CREDENTIALS = 'stuart:global:credentials/RESET_CREDENTIALS'
export const SET_CREDENTIALS = 'stuart:global:credentials/SET_CREDENTIALS'
const initialState = {
  // jwtFromLocalStore: localStorage.getItem('brainSessionJwt'),

  accessToken: '',
  legionId: `LEGION-${guid()}`, //deprecated
  expires: null,
  generalConsent: true,
  id: '',
  isAuthenticated: false,
  tokenType: ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case authAPI.events.login.actionSuccess:
      const decoded3 = jwtDecode(action.data.accessToken)

      return Object.assign({}, state, {
        accessToken: action.data.accessToken,
        expires: decoded3.exp,
        id: decoded3.sub,
        isAuthenticated: true,

        tokenType: action.data.tokenType
      })
    case vippsAuthAPI.events.login.actionSuccess:
      const decoded = jwtDecode(action.data.accessToken)

      return Object.assign({}, state, {
        accessToken: action.data.accessToken,
        expires: decoded.exp,
        id: decoded.sub,
        isAuthenticated: true,

        tokenType: action.data.tokenType
      })
    case accountAPI.events.account.actionSuccess:
      return Object.assign({}, state, {
        generalConsent: evaluateGeneralConsent(action.data.acceptanceTags),
        telipolConsent: evaluateteTelipolConsent(action.data.acceptanceTags)
      })

    case authAPI.events.verifyToken.actionSuccess:
      return Object.assign({}, state, { isAuthenticated: true })
    case authAPI.events.verifyToken.actionFail:
      localStorage.removeItem('brainSessionJwt')

      return Object.assign({}, initialState)
    case RESET_CREDENTIALS:
      localStorage.removeItem('brainSessionJwt')
      return Object.assign({}, initialState)
    case SET_CREDENTIALS:
      const decodedJWT = jwtDecode(action.payload)

      return Object.assign({}, state, {
        accessToken: action.payload,
        expires: decodedJWT.exp,
        id: decodedJWT.sub,
        isAuthenticated: true,

        tokenType: 'Bearer'
      })
    default:
      return state
  }
}
function guid() {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const resetCredentials = () => ({ type: RESET_CREDENTIALS })
export const setCredentials = payload => ({ type: SET_CREDENTIALS, payload })
