/**
 * WebAPI reducer for power services.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'
import {
  discardPaginationTransformer,
  responseDataTransformer
} from '../transformers'

const webapi = reduxApi({
  meteringPoints: {
    helpers: {
      get() {
        return [{}, {}]
      },
      post(id, payload) {
        return [{}, { body: JSON.stringify(payload), method: 'POST' }]
      }
    },
    transformer: discardPaginationTransformer,
    url: `${config.webapi.power.baseURI}/customer-info/`
  },
  status: {
    helpers: {
      get() {
        return [{}, {}]
      }
    },
    transformer: responseDataTransformer,
    url: `${config.webapi.power.baseURI}/status/`,
    postfetch: [
      ({ dispatch, actions, getState }) => {
        const {
          meteringPointId,
          serviceStatus
        } = getState().webapi.power.status.data

        if (serviceStatus === 'active') {
          //  dispatch(actions.statusActivity.sync({id: meteringPointId}));
        }
      }
    ]
  },
  statusActivity: {
    transformer: responseDataTransformer,
    url: `${config.webapi.power.baseURI}/status-activity/:id/`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as powerAPI }
export default combineReducers(webapi.reducers, {})
