/**
 * WebAPI reducer for billing/invoice.
 */

import 'isomorphic-fetch'; // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';
import { combineReducers } from 'redux';

import config from '../../../config';
import {
  discardPaginationTransformer,
  responseDataTransformer,
} from '../transformers';
// import { invoiceAPI } from '../invoice';
import { esnAPI } from '../esn';
import { eRateAPI } from '../eRate';
import { frendeAPI } from '../frende';

const webapi = reduxApi({
  subscriptionsList: {
    helpers: {
      get(id) {
        return [{ id }, {}];
      },
    },
    postfetch: [
      ({ dispatch, actions, getState }) => {
        const accountId = getState().global.credentials.id;

        // dispatch(esnAPI.actions.esnSubscriptions.get(accountId));
        dispatch(eRateAPI.actions.eRateSubscriptions.get(accountId));
        dispatch(eRateAPI.actions.eRateInvoices.get());
        // dispatch(esnAPI.actions.esnInvoices.get());
        // dispatch(frendeAPI.actions.frendeSubscriptions.get());
      },
    ],
    transformer: discardPaginationTransformer,
    url: `${config.webapi.v2.subscriptions}/`,
  },
  subscription: {
    helpers: {
      get(id) {
        return [{ id }, {}];
      },
      patch(id, payload) {
        return [{ id }, { body: JSON.stringify(payload), method: 'PATCH' }];
      },
    },

    transformer: responseDataTransformer,
    url: `${config.webapi.v2.subscriptions}/:id/`,
  },
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`,
        },
      };
    }

    return headers;
  })
  .use('fetch', adapterFetch(fetch));

export { webapi as subscriptionsAPI };
export default combineReducers(webapi.reducers, {});
