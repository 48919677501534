import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toggleDisplaySplash } from '../../store/global/config'
import { Redirect, withRouter } from 'react-router-dom'

class Welcome extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.state = { splashMessages: 'Hola, Pablito!' }
  }
  componentDidMount() {
    if (!this.props.config.displaySplash) {
      this.props.actions.toggleDisplaySplash()
    }
  }
  render() {
    let { from } = this.props.location.state
      ? this.props.location.state
      : { from: { pathname: '/products' } }
    if (from === '/') from = { pathname: '/products' }
    if (this.props.config.splashDone) {
      return <Redirect to={from.pathname} />
    }

    return <div style={{ height: '80vh' }} />
  }
}
export default withRouter(
  connect(
    state => ({
      config: state.global.config
    }),
    dispatch => ({
      actions: Object.assign(
        {},
        { dispatch },
        bindActionCreators(
          {
            toggleDisplaySplash
          },
          dispatch
        )
      )
    })
  )(Welcome)
)
Welcome.propTypes = {}
