import React from 'react'
import renderHTML from 'react-render-html'
import PropTypes from 'prop-types'
import { Button, Message } from 'semantic-ui-react'

import { fm as _ } from '../../utils/string'
import { Link } from 'react-router-dom'

export default class WelcomeBanner extends React.Component {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  }

  static propTypes = {
    account: PropTypes.shape({ firstName: PropTypes.string }).isRequired,
    email: PropTypes.string.isRequired,
  }
  constructor(props, context) {
    super(props, context)
    this.state = { visible: true }
    this.handleDismiss = this.handleDismiss.bind(this)
  }

  handleDismiss = () => {
    this.setState({ visible: false })
  }
  render() {
    const { gettext, xgettext } = this.context
    const { account, email } = this.props
    if (!this.props.credentials.generalConsent) {
      return (
        <div style={{ marginTop: '80px' }}>
          <Message
            content={
              <div style={{ marginBottom: 20, display: 'flex' }}>
                <span style={{}}>
                  {renderHTML(
                    _(
                      gettext(
                        'Turn your account into a full Hudya account and get access to all our products and services.s'
                      ),
                      { email }
                    )
                  )}
                </span>
                <span style={{ marginRight: 'auto', marginLeft: 20 }}>
                  <Button basic circular inverted as={Link} to="/consent">
                    {gettext('Upgrade', '')}
                  </Button>
                </span>
              </div>
            }
            header={renderHTML(
              _(
                xgettext(
                  'Hey {name}! Unlock the full power of Hudya!',
                  'Heading of welcome message for users with limited consent'
                ),
                { name: account.firstName }
              )
            )}
            hidden={!this.state.visible}
            style={{
              backgroundColor: 'rgba(255,255,255,0.2)',
              border: '1px solid rgba(255,255,255,0.3)',
              color: 'white',
              fontWeight: 400,

              textAlign: 'left',
            }}
            onDismiss={this.handleDismiss}
          />
        </div>
      )
    }
    return <div />
    return (
      <div style={{ marginTop: '80px' }}>
        <Message
          content={renderHTML(
            _(
              xgettext(
                'If you experience anything strange or think something is missing, please let us know through the chat or by e-mail at  <a style="color:white;" href="mailto:{email}">{email}</a>',
                'Body of welcomemessage'
              ),
              { email }
            )
          )}
          header={renderHTML(
            _(
              xgettext(
                'Hey {name}! This is our first public release of My Hudya',
                'Heading of welcome message'
              ),
              { name: account.firstName }
            )
          )}
          hidden={!this.state.visible}
          style={{
            backgroundColor: 'rgba(255,255,255,0.2)',
            border: '1px solid rgba(255,255,255,0.3)',
            color: 'white',
            fontWeight: 400,

            textAlign: 'left',
          }}
          onDismiss={this.handleDismiss}
        />
      </div>

      //   <div className="ui segment orange big">
      //     <div className="ui container">
      //       <div className="ui center aligned two column doubling stackable grid">
      //         <div className="column">
      //           {renderHTML(
      //             _(
      //               xgettext(
      //                 'Hey {name}, this is the first version of our My Hudya app. <br />If your see anything weird happening or need any kind of assistance, please contact us at <a href="mailto:{email}">{email}</a>.',
      //                 'Message displayed at top of each tab when logged into the app.'
      //               ),
      //               {name: account.firstName, email}
      //             )
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //     <div
      //       className="svg-container"
      //       style={{height: '30px', position: 'absolute', bottom: '-1px', left: 0, right: 0, zIndex: 1}}>
      //       <svg
      //         fill="#FFFFF7"
      //         preserveAspectRatio="none"
      //         style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}
      //         version="1.1"
      //         viewBox="0 0 1080 22"
      //         xmlns="http://www.w3.org/2000/svg"
      //         xmlnsXlink="http://www.w3.org/1999/xlink">
      //         <path d="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
      //       </svg>
      //     </div>
      //   </div>
    )
  }
}
