import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useGetInsurancePageQuery = () => {
  let { loading, error, data } = useQuery(
    gql`
      query getCompetitionPage {
        res: pageBy(uri: "insurance-landing-page") {
          status
          content
          contentData: insuranceLandingPage {
            title
            firstMessage: firstmessage
            flow
            step
          }
        }
      }
    `,
    {
      variables: {},
    },
  );

  if (data && data.res) {
    const { res } = data;
    if (res && res.status === 'publish') {
      data = { content: res.content, ...res.contentData };
      return { loading, error, data };
    }
  }

  data = {
    content: null,
    title: 'Forsikring',
    firstMessage: '',
    flow: '',
    step: '',
  };

  return { loading, error, data };
};

export default useGetInsurancePageQuery;
