import config from '../../../config';
import {configureClient} from './client';

export const TOGGLE_CONTENTFUL_PREVIEW = 'stuart:global:contentful:config/TOGGLE_CONTENTFUL_PREVIEW';
const initialState = {
  accessToken: config.CONTENTFUL_DELIVERY_KEY,
  previewMode: false,
  spaceId: config.CONTENTFUL_SPACE_ID
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CONTENTFUL_PREVIEW:
      if (config.RUNTIME_ENV === 'prod') {
        return state;
      }

      return Object.assign({}, state, {
        accessToken: state.previewMode
          ? config.CONTENTFUL_DELIVERY_KEY
          : config.CONTENTFUL_PREVIEW_KEY,
        previewMode: !state.previewMode
      });
    default:
      return state;
  }
}

export const toggleContentfulPreview = () => {

  const toggleContentfulPreviewAction = () => ({type: TOGGLE_CONTENTFUL_PREVIEW});

  return (dispatch) => {
    return Promise.resolve(
      dispatch(toggleContentfulPreviewAction())
    ).then(dispatch(configureClient()));
  };
};
