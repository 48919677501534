import React from 'react';
import PropTypes from 'prop-types';
import {Input, Label} from 'semantic-ui-react';

export default class TextInput extends React.Component {
  render() {
    if (this.props.values.type !== 'input') {
      return null;
    }
    if (this.props.disabled) {
      return (
        <div style={{textAlign: 'right', opacity: 0.3}}>
          <Label basic circular size="huge">
            {this.props.values.value}
          </Label>
        </div>
      );
    }

    return (
      <Input
        disabled={this.props.disabled}
        autoFocus
        fluid
        onChange={this.props.onChange}
        placeholder={this.props.values.placeholder}
        size="huge"
        value={this.props.fuzzyValue} />
    );
  }
}

TextInput.propTypes = {values: PropTypes.object.isRequired};

TextInput.defaultProps = {timeout: 6000};
