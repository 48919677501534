import React, { useMemo } from 'react';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import PropTypes from 'prop-types';

import {
  makeErrorHandlingLink,
  makeRequestLink,
  makeTransportLinks,
  makeCache,
} from './handlers';

function AuthApolloProvider({ children }) {
  const client = useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.from([
        makeErrorHandlingLink(),
        makeRequestLink(),
        makeTransportLinks(),
      ]),
      cache: makeCache(),
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <ApolloProviderHooks client={client}>{children}</ApolloProviderHooks>
    </ApolloProvider>
  );
}

AuthApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthApolloProvider;
