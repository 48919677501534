import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'semantic-ui-react';

import HtmlParse from '../../../../../components/HtmlParser/htmlParser';

import styles from './topSection.module.scss';

const TopSection = ({ title, subtitle }) => {
  return (
    <header className={styles.header}>
      <Container>
        <Grid stackable>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <h1>{HtmlParse({ data: title || '' })}</h1>
              <h2>{HtmlParse({ data: subtitle || '' })}</h2>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </header>
  );
};
TopSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
TopSection.defaultProps = {
  title: '',
  subtitle: '',
};
export default TopSection;
