/**
 * WebAPI reducer for billing/invoice.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'

const webapi = reduxApi({
  register: {
    helpers: {
      post(id, payload) {
        return [{}, { body: JSON.stringify(payload), method: 'POST' }]
      }
    },

    //  transformer: discardPaginationTransformer,
    url: `${config.webapi.v2.source}`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const jwtFromBrain = localStorage.getItem('brainSessionJwt')

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    if (jwtFromBrain) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${jwtFromBrain}`
        }
      }
    }
    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return {
      headers: {
        ...headers
      }
    }
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as sourceApi }
export default combineReducers(webapi.reducers, {})
