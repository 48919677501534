import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

import { formatBytes, formatBytesMode } from '../../../../utils/formatter';

import styles from './styles.module.scss';

const mockedData = [
  { day: '1 mai', usage: 1720558, index: 1 },
  { day: '2 mai', usage: 1514847, index: 2 },
  { day: '3 mai', usage: 1565212, index: 3 },
  { day: '4 mai', usage: 384870, index: 4 },
  { day: '5 mai', usage: 936109, index: 5 },
  { day: '6 mai', usage: 208867, index: 6 },
  { day: '7 mai', usage: 1289043, index: 7 },
  { day: '8 mai', usage: 1844664, index: 8 },
  { day: '9 mai', usage: 34526, index: 9 },
  { day: '10 mai', usage: 1337997, index: 10 },
  { day: '11 mai', usage: 410293, index: 11 },
  { day: '12 mai', usage: 887661, index: 12 },
  { day: '13 mai', usage: 1210185, index: 13 },
  { day: '14 mai', usage: 1358797, index: 14 },
  { day: '15 mai', usage: 1196169, index: 15 },
  { day: '16 mai', usage: 541088, index: 16 },
  { day: '17 mai', usage: 297051, index: 17 },
  { day: '18 mai', usage: 1230770, index: 18 },
  { day: '19 mai', usage: 1035373, index: 19 },
  { day: '20 mai', usage: 1227364, index: 20 },
  { day: '21 mai', usage: 512860, index: 21 },
  { day: '22 mai', usage: 495432, index: 22 },
  { day: '23 mai', usage: 141629, index: 23 },
  { day: '24 mai', usage: 1635320, index: 24 },
  { day: '25 mai', usage: 1432820, index: 25 },
  { day: '26 mai', usage: 325283, index: 26 },
  { day: '27 mai', usage: 1836266, index: 27 },
  { day: '28 mai', usage: 1605263, index: 28 },
  { day: '29 mai', usage: 1668702, index: 29 },
  { day: '30 mai', usage: 1003875, index: 30 },
];

const MobileChart = ({ data, isMain, transferTotal }) => {
  const prepareData = () => {
    let d = [];
    const days = moment().daysInMonth();
    const month = moment().format('MMM');
    if (data && data.length) {
      for (let i = 1; i < days; i++) {
        d.push({
          day: `${i} ${month}`,
          usage: Math.floor(Math.random() * Math.floor(20000)),
          index: i,
        });
      }
      d = _.orderBy(d, ['index'], ['asc']);
      return d;
    }
    for (let i = 1; i < days; i++) {
      d.push({
        day: `${i} ${month}`,
        usage: Math.floor(Math.random() * Math.floor(2000000)),
        index: i,
      });
    }
    d = _.orderBy(d, ['index'], ['asc']);
    return d;
  };

  const getDaysLeft = () => {
    const days = moment().daysInMonth();
    const day = +moment().format('DD');
    return days - day;
  };

  return (
    <div className={`${styles.chartContainer} ${isMain ? null : styles.details}`}>
      <div className={styles.chart}>
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            margin={{
              top: 0,
              right: 15,
              left: 15,
              bottom: 10,
            }}
            data={mockedData}
          >
            <CartesianGrid
              horizontal={false}
              vertical={false}
              strokeDasharray="3 3"
            />
            <XAxis dataKey="day" axisLine={false} tickLine={false} hide />
            <YAxis axisLine={false} tickLine={false} hide />
            <Tooltip
              formatter={(value) => formatBytes(value)}
              itemStyle={{ fill: '#000', color: '#000' }}
            />
            <Bar
              dataKey="usage"
              fill={isMain ? 'rgba(255,255,255,0.5)' : 'rgba(182,32,224,0.5)'}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.usageDetails}>
        <div className={styles.progress}>
          <div
            className="ui white progress"
            style={{
              borderRadius: 4,
              background: isMain
                ? 'rgba(255,255,255,0.5)'
                : 'rgba(182,32,224,0.5)',
            }}
            data-percent="59"
          >
            <div
              className="bar"
              style={{
                width: '33%',
                borderRadius: 4,
                background: isMain ? '#fff' : 'rgba(182,32,224,0.5)',
              }}
            />
          </div>
        </div>
        <div className={styles.usageContainer} style={{color: isMain ? '#fff' : '#000'}}>
          <span className={styles.usage}>
            <b className={styles.usageValue}>
              {formatBytes(30888894, 2, formatBytesMode.VALUE, true)}
            </b>{' '}
            <b>{formatBytes(30888894, 2, formatBytesMode.TYPE, true)}</b> av{' '}
            {formatBytes(transferTotal, 2, null, true)}
          </span>
          <span className={styles.till}>{getDaysLeft()} dager igjen</span>
        </div>
      </div>
    </div>
  );
};

MobileChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  transferTotal: PropTypes.number.isRequired,
  isMain: PropTypes.bool,
};

MobileChart.defaultProps = {
  isMain: false,
};

export default MobileChart;
