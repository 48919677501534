import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Icon } from 'semantic-ui-react';

import '../../../utils/string'; // eslint-disable-line import/no-unassigned-import

import ProductCard from '../components/ProductCard';

import InvoicesTable from './components/InvoicesTable/InvoicesTable';
import MobileDetailsCard from './components/MobileDetailsCard/MobileDetailsCard';
import styles from './styles.module.scss';
import FloatingMenu from './components/FloatingMenu/FloatingMenu';
import PowerDetailsCard from './components/PowerDetailsCard/PowerDetailsCard';

const PROVIDERS = {
  mobile: 'eRate',
  power: 'esn',
};

class OverviewDetails extends React.PureComponent {
  static contextTypes = {
    gettext: PropTypes.func.isRequired,
    xgettext: PropTypes.func.isRequired,
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    providers: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { match, history } = props;
    const { type } = match.params;
    let data = [];
    let invoices = [];
    if (!type) {
      history.push('/products');
    }

    const { providers } = props;
    if (providers[PROVIDERS[type]].sync) {
      data = providers[PROVIDERS[type]].subscriptions;
      invoices = providers[PROVIDERS[type]].invoices;
    } else {
      history.push('/products');
    }

    this.state = {
      type,
      data,
      invoices,
    };
  }

  render() {
    const { gettext } = this.context;
    const { type, data, invoices } = this.state;
    const { config } = this.props;
    const { defaultLocale, urlLocaleMap } = config;
    let powerGoalflow = '';
    if (defaultLocale === 'sv_SE') {
      powerGoalflow = 'se_power_onboarding';
    }
    if (defaultLocale === 'nb_NO') {
      powerGoalflow = 'power_onboarding';
    }

    return (
      <div className={styles.overviewContainer}>
        <FloatingMenu />
        <Container textAlign="center">
          <div className="ui stackable doubling four column grid aligned">
            {data.map((c) => (
              <Grid.Column key={c.id}>
                {type === 'mobile' ? <MobileDetailsCard data={c} /> : null}
                {type === 'power' ? <PowerDetailsCard data={c} /> : null}
                {type === 'insurance' ? <MobileDetailsCard data={c} /> : null}
              </Grid.Column>
            ))}

            <Grid.Column>
              {type === 'mobile' ? (
                <ProductCard
                  header={gettext('Mobil')}
                  desc={gettext(
                    'Full Telenor-dekning, Data Rollover, gratis BankID og ingen bindingstid',
                  )}
                  flow="mobile_onboarding"
                  flowStep="init_with_fixed_start"
                  price={gettext('mobile price')}
                  priceTopText={gettext('Fra kr')}
                  priceBottomText={gettext('per month')}
                  readMore={gettext('Alle abonement')}
                  readMoreUri="/mobil/produkter"
                  orderButtonText={gettext('Bestill nå')}
                />
              ) : null}
              {type === 'power' ? (
                <ProductCard
                  header={gettext('Strøm')}
                  desc={gettext('Ingen fastavgift eller bindingstid')}
                  flow={powerGoalflow}
                  flowStep="init_with_fixed_start"
                  price={gettext('Power price')}
                  priceTopText={gettext('Fast påslag')}
                  readMore={gettext('Les mer')}
                  readMoreUri={urlLocaleMap.power[defaultLocale]}
                  priceBottomText={gettext('øre/KWh')}
                  orderButtonText={gettext('Bestill nå')}
                />
              ) : null}
              {type === 'insurance' ? (
                <ProductCard
                  header={gettext('Forsikring')}
                  desc={gettext(
                    'Vår bil-, barne- og innboforsikring er kåret til best i test',
                  )}
                  flow="insurance_mobile_discount"
                  flowStep="init"
                  price={<Icon name="handshake outline" />}
                  priceTopText={gettext('Vi er her')}
                  orderButtonText={gettext('Få tilbud')}
                  readMore={gettext('Les mer')}
                  readMoreUri="/forsikring"
                  priceBottomText={gettext('for deg')}
                />
              ) : null}
            </Grid.Column>
          </div>
          <div className={styles.invoices}>
            <InvoicesTable invoices={invoices} type={type} />
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      webapi: state.webapi,
      account: state.global.entities.account,
      config: state.global.config,
      credentials: state.global.credentials,
      invoices: state.global.entities.products.power.invoices,
      providers: state.global.providers,
      subscriptions: state.global.entities.products.power.power,
    }),
    (dispatch) => ({
      actions: bindActionCreators({}, dispatch),
      apiActions: bindActionCreators({}, dispatch),
      dispatch,
    }),
  )(OverviewDetails),
);
