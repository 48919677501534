import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import ChatContainer from '../../../components/ChatContainer';

import styles from './styles.module.scss';

const TilbakemeldingMobil = () => {
  const flow = 'csat_mobile';
  const step = 'csat_mobile_intro';
  return (
    <div className={styles.contentContainer}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Grid textAlign="left">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={11} computer={11}>
              <ChatContainer
                flow={flow}
                step={step}
                chatH1=""
                columns={16}
                disc={null}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5}>
              <div className={styles.sideContainer}>
                <div className={styles.rating} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default TilbakemeldingMobil;
