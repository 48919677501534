import React from 'react';
import { Icon, Grid, Container } from 'semantic-ui-react';
import Carousel from '@brainhubeu/react-carousel';

import SubscriptionCard from '../SubscriptionCard';
import '@brainhubeu/react-carousel/lib/style.css';
import PageLoader from '../../../../../components/PageLoader/pageLoader';
import TopSection from '../TopSection';
import useGetMobileSubscriptionsQuery from '../../../../../hooks/gql/mobile/GetMobileSubscriptions.query';

import styles from './subscriptionSection.module.scss';

const SubscriptionSection = () => {
  const { loading, data } = useGetMobileSubscriptionsQuery();

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <div className={`mobile-plans ${styles.mobileSubscriptionsContainer}`}>
      <TopSection title={data.title || ''} subtitle={data.subtitle || ''} />
      <Container className="mobile hidden">
        <div className="ui stackable doubling three column grid center aligned">
          {data && data.subscriptions && data.subscriptions.length
            ? data.subscriptions.map((s) => (
                <Grid.Column aligment="center" key={s.title}>
                  <SubscriptionCard {...s} />
                </Grid.Column>
              ))
            : null}
        </div>
      </Container>

      <Container className="mobile only center aligned">
        <Carousel
          addArrowClickHandler
          arrowLeft={
            <Icon className="icon-example" name="angle left" size="large" />
          }
          arrowRight={
            <Icon className="icon-example" name="angle right" size="large" />
          }
          infinite
        >
          {data && data.subscriptions && data.subscriptions.length
            ? data.subscriptions.map((s) => (
                <SubscriptionCard {...s} key={s.title} />
              ))
            : null}
        </Carousel>
      </Container>
    </div>
  );
};

export default SubscriptionSection;
