/**
 * WebAPI reducer for billing/invoice.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'
import { powerAPI } from '../power'
import { subscriptionsAPI } from '../subscriptions'
import { invoiceAPI } from '../invoice'

import config from '../../../config'
import { responseDataTransformer } from '../transformers'

const webapi = reduxApi({
  entity: {
    helpers: {
      get(id) {
        return [{ id }, {}]
      },
      patch(id, payload) {
        return [{ id }, { body: JSON.stringify(payload), method: 'PATCH' }]
      }
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        const accountId = getState().global.credentials.id

        // dispatch(powerAPI.actions.meteringPoints.get())
        dispatch(subscriptionsAPI.actions.subscriptionsList.get())
        dispatch(invoiceAPI.actions.invoice.get())
      }
    ],
    transformer: responseDataTransformer,
    url: `${config.webapi.v2.legalEntities}/:id/`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as legalEntityAPI }
export default combineReducers(webapi.reducers, {})
