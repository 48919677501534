import React from 'react';
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import styles from './styles.module.scss';

const FloatingMenu = () => {
  const history = useHistory();

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <div className={styles.floatingContainer}>
      <Button className={styles.button} onClick={() => navigate('/products')}>
        <img
          src="/assets/icons/floatingMenu/home.svg"
          className={styles.icon}
          alt="home icon"
        />
      </Button>
    </div>
  );
};

FloatingMenu.propTypes = {};

FloatingMenu.defaultProps = {};

export default FloatingMenu;
