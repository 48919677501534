import { legalEntityAPI } from '../../webapi/legalEntity'
export const UPDATE_ACCOUNT = 'stuart:global:entities/account/UPDATE_ACCOUNT'

const initialState = {
  addresses: { legal: {} },
  birthDate: '',
  contactInfo: {
    addresses: [],
    emails: [],
    phoneNumbers: []
  },
  emails: {},
  firstName: '',
  id: '',
  initialLoad: false,
  lastName: '',
  locale: '',
  middleName: '',
  modified: ''
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case accountAPI.events.account.actionSuccess:
    //   const fullName = []

    //   fullName.push(action.data.firstName)
    //   // action.data.middleName === ''
    //   //   ? null
    //   //   : fullName.push(action.data.middleName)
    //   if (action.data.middleName !== '') {
    //     fullName.push(action.data.middleName)
    //   }
    //   return Object.assign({}, initialState, {
    //     acceptanceTags: action.data.acceptanceTags,
    //     birthDate: action.data.birthDate,
    //     contactInfo: {
    //       //    ...state.contactInfo,
    //       addresses: [
    //         //  ...state.contactInfo.addresses,
    //         {
    //           address1: [
    //             action.data.streetName,
    //             action.data.streetNumber,
    //             action.data.streetLetter
    //           ].join(' '),
    //           address2: [
    //             action.data.entrance ? `Entrance ${action.data.entrance}` : '',
    //             action.data.entrance && action.data.floor ? ',' : '',
    //             action.data.floor
    //               ? ` ${converter.toOrdinal(action.data.floor)} floor`
    //               : ''
    //           ].join(' '),
    //           city: action.data.city,
    //           country: action.data.country,
    //           kind: 'primary',
    //           postFormat: {
    //             apartment: action.data.apartment,
    //             apartmentIsValid: true,
    //             city: action.data.city,
    //             cityIsValid: isValid('required', action.data.city),
    //             entrance: action.data.entrance,
    //             floor: action.data.floor,
    //             postalCode: action.data.postalCode,
    //             streetLetter: action.data.streetLetter,
    //             streetName: action.data.streetName,
    //             streetNumber: action.data.streetNumber
    //           },
    //           zipCode: action.data.postalCode
    //         }
    //       ],
    //       emails: [
    //         // why this spread? it makes duplicates when editing....
    //         // ...state.contactInfo.emails,
    //         {
    //           email: action.data.emailPrimary,
    //           kind: 'primary'
    //         }
    //       ],
    //       phoneNumbers: [
    //         //  ...state.contactInfo.phoneNumbers,
    //         {
    //           kind: 'primary',
    //           number: action.data.phoneNumber.number.replace(
    //             /^(\+[\d]{2})/,
    //             ''
    //           ),
    //           prefix: action.data.phoneNumber.number.match(/^(\+[\d]{2})/g)[0],
    //           supportSMS: action.data.phoneNumber.sms
    //         }
    //       ]
    //     },
    //     firstName: action.data.firstName,
    //     fullName:
    //       action.data.middleName === ''
    //         ? `${action.data.firstName} ${action.data.lastName}`
    //         : `${action.data.firstName} ${action.data.middleName} ${
    //             action.data.lastName
    //           }`,
    //     id: action.data.accountId,
    //     initialLoad: true,
    //     lastName: action.data.lastName,
    //     locale: action.data.locale,
    //     middleName: action.data.middleName,
    //     modified: action.data.lastUpdated
    //   })

    case legalEntityAPI.events.entity.actionSuccess:
      const user = action.data
      return Object.assign({}, state, {
        addresses: user.addresses,
        contactPoints: user.contactPoints,
        fullName: user.name,
        id: user.id,
        initialLoad: true
      })
    case UPDATE_ACCOUNT:
      return Object.assign({}, state, {
        ...action.payload
      })

    default:
      return state
  }
}
export const updateAccount = payload => ({ type: UPDATE_ACCOUNT, payload })
