import React, { useRef, useEffect } from 'react';

const TrustPilot = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="nb-NO"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5f589d575c8bbc00013d591b"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://no.trustpilot.com/review/scoopr.no"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustPilot;
