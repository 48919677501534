import Account from './scenes/Account';
import KitchenSink from './components/KitchenSink';
import Signup from './scenes/Signup';
import Consent from './scenes/Consent';
import Overview from './scenes/Overview';
import SubscriptionDetails from './scenes/Subscriptions/SubscriptionDetails';
// import Subscriptions from './scenes/Subscriptions';
import Welcome from './scenes/Welcome';
import MobileLandingPage from './scenes/LandingPages/Mobile';
import MobileOverview from './scenes/LandingPages/Mobile/Overview';
import Intro from './scenes/LandingPages/Intro';
import PublicInsurance from './scenes/LandingPages/Insurance';
import HomeContainer from './scenes/LandingPages/Home/home.container';
import MobilePrices from './scenes/LandingPages/Mobile/producPrices';
import Article from './scenes/LandingPages/Article';
import OverviewDetails from './scenes/Overview/OverviewDetails/OverviewDetails';
import CustomerService from './scenes/CustomerService/customerService';
import Cookies from './scenes/Content/Cookies/cookies';
import UserAgreement from './scenes/Content/UserAgreement/userAgreement';
import DataPolicy from './scenes/Content/DataPolicy/dataPolicy';
import About from './scenes/Content/About/about';
import Faq from './scenes/Faq/faq';
import MobilUserAgreement from './scenes/Content/MobilUserAgreement/mobilUserAgreement';
import Refinancing from './scenes/LandingPages/Refinancing';
import MobileProduct from './scenes/LandingPages/MobileProduct';
import FlowSandBox from './scenes/FlowSandBox';
import TilbakemeldingMobil from './scenes/LandingPages/TilbakemeldingMobil';
import DynamicRefinancing from './scenes/LandingPages/DynamicRefinancing';
import CompetitionPage from './scenes/LandingPages/CompetitionPage/competitionPage';
import InsuranceDataPolicy from './scenes/Content/InsuranceDataPolicy/insuranceDataPolicy';
import HiddenInsurance from './scenes/LandingPages/Insurance/hiddenIndex';

const createRoutes = () => [
  {
    component: Article,
    exact: true,
    path:
      '/mobilabonnement/hudya-lanserer-hudya-barn-1gb-til-kun-kr-99-per-mnd-i-telenor-nettet/',
    protected: false,
  },

  {
    component: About,
    exact: true,
    path: '/om-scoopr',
    protected: false,
  },

  {
    component: CustomerService,
    exact: true,
    path: '/kundeservice',
    protected: false,
  },
  {
    component: DataPolicy,
    exact: true,
    path: '/personvernerklaering',
    protected: false,
  },
  {
    component: MobilUserAgreement,
    exact: true,
    path: '/mobil-brukervilkar',
    protected: false,
  },
  {
    component: UserAgreement,
    exact: true,
    path: '/brukervilkar',
    protected: false,
  },
  {
    component: Cookies,
    exact: true,
    path: '/cookies',
    protected: false,
  },
  {
    component: TilbakemeldingMobil,
    exact: true,
    path: '/tilbakemelding-mobil',
    protected: false,
  },
  {
    component: Refinancing,
    exact: true,
    path: '/refinansiering',
    protected: false,
  },
  {
    component: DynamicRefinancing,
    exact: true,
    path: '/refinansiering-lp/:dynamic',
    protected: false,
  },
  {
    component: FlowSandBox,
    exact: true,
    path: '/flowsandbox/:flow/:step/:product?/:productValue?',
    protected: true,
  },
  {
    component: Faq,
    exact: true,
    path: '/(refinansiering|refinancing)/:articleName',
    protected: false,
  },
  {
    component: Account,
    exact: true,
    path: '/account',
    protected: true,
  },
  {
    component: Consent,
    exact: true,
    path: '/consent',
    protected: true,
  },
  {
    component: Intro,
    exact: true,
    path: '/intro',
    protected: false,
  },
  {
    component: HomeContainer,
    exact: true,
    path: '/',
    protected: false,
  },
  {
    component: HomeContainer,
    exact: true,
    path: '/forside',
    protected: false,
  },
  {
    component: Overview,
    exact: true,
    path: '/products',
    protected: true,
    routes: [],
  },
  {
    component: OverviewDetails,
    exact: true,
    path: '/products/details/:type',
    protected: true,
    routes: [],
  },
  {
    component: SubscriptionDetails,
    exact: true,
    path: '/products/:type/:provider/:id',
    protected: true,
  },
  {
    component: PublicInsurance,
    exact: true,
    path: '/(forsikring|insurance)/:flow?/:step?/:sessionId?',
    protected: false,
  },
  {
    component: HiddenInsurance,
    exact: true,
    path: '/hiddenInsurance/:flow?/:step?/:sessionId?',
    protected: false,
  },
  {
    component: InsuranceDataPolicy,
    exact: true,
    path: '/brukervilkar-forsikring',
    protected: false,
  },
  {
    component: Faq,
    exact: true,
    path: '/(forsikring|insurance)/:articleName',
    protected: false,
  },
  {
    component: HomeContainer,
    exact: true,
    path: '/login:key?',
  },
  {
    component: KitchenSink,
    exact: true,
    path: '/kitchensink',
  },

  // {
  //   component: PublicPower,
  //   exact: true,
  //   path: store.getState().global.config.urlLocaleMap.power[
  //     store.getState().global.config.defaultLocale
  //   ]
  // },
  {
    component: CompetitionPage,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/konkurranse',
  },
  {
    component: MobileOverview,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/(overview|produkter)',
  },
  {
    component: MobileProduct,
    exact: true,
    fullscreen: true,
    path:
      '/(mobil|mobilabonnement)/(flex|0mb|300mb|500mb|barn-1gb|1gb|2gb|3gb|5gb|6gb|10gb|12gb|20gb|30gb|mobilt-bredband|6gb-voksen-nok|12gb-voksen-nok|30gb-voksen-nok)',
  },
  {
    component: MobilePrices,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/ovrige-priser-alle-abonnement',
  },
  {
    component: Faq,
    exact: true,
    fullscreen: true,
    path: '/mobilabonnement/:articleName',
  },
  {
    component: MobileLandingPage,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobilabonnement)/',
  },
  {
    component: Faq,
    exact: true,
    fullscreen: true,
    path: '/(mobil|mobile)/:articleName',
  },
  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telipol/',
    protected: true,
  },
  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telibob/:phone?',
  },
  {
    component: Overview,
    exact: true,
    fullscreen: true,
    path: '/telipol/:phone?',
  },

  {
    component: Signup,
    exact: true,
    fullscreen: true,
    path: '/signup/:id?',
  },
  {
    component: Welcome,
    exact: true,
    fullscreen: false,
    path: '/welcome',
  },
];
export default createRoutes;
