import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { pushEventToTagManager } from '../../utils/analytics';

const Page = ({ children, title, loading }) => {
  const [pushed, setPushed] = useState(false);
  const [newTitle, setNewTitle] = useState(null);
  const location = useLocation();

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const splitLocation = () => {
    let t = '';
    let split = location.pathname.slice(0, -1).split('/');
    split.pop();
    split.push(title);
    split = split.reverse();
    if (split && split.length) {
      split.forEach((r) => {
        if (r && r !== '') {
          t += `${capitalize(r)} - `;
        }
      });
      t += 'Scoopr';
    }
    setNewTitle(t);
    return t;
  };

  useEffect(() => {
    if (!pushed && !loading && title && title !== '') {
      document.title = splitLocation();
      pushEventToTagManager('pagetitle_set', 'tracking', 'hd.pagetitle_set');
      setPushed(true);
    }
  }, [title, loading]);
  return <div>{children}</div>;
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

Page.defaultProps = {
  children: null,
  title: '',
  loading: true,
};

export default Page;
