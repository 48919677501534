const variables = {
  BASE_URI: 'https://q76xsabtptlmsdiop-mock.stoplight-proxy.io',
  CONTENTFUL_DELIVERY_KEY:
    'fd94d1ae2bf8d67a0a174e8e04b7d8d76e736708699a7fbe8f9b4a893040da0b', // stuart-contentful-local-key
  CONTENTFUL_PREVIEW_KEY:
    '3d44ef2721baea6475b56010cf527aca0c1eedfa689660e2e257660582862610', // stuart-contentful-local-key
  CONTENTFUL_SPACE_ID: 'nxxv49nfhrql',
  LEGEAL_ENTITY_URI: '/ownership/legal-entities/',
  LOGIN_URI: '/v1.0/identity/login/',
  RUNTIME_ENV: process.env.REACT_APP_RUNTIME_ENV || 'prod',
  TARGET_DOMAIN: process.env.REACT_APP_TARGET_DOMAIN || 'dev.hudya.io',
  TRANSIFEX_API_KEY: '1/eae09cc0a143238c29265ead59d76e1c814e3c20',
  INTERCOM_ID: '',
  FB_PIXEL_ID: '',
  ACAISOFT_URL:
    process.env.REACT_APP_RUNTIME_ENV == 'prod'
      ? 'https://openresty-gateway.payr-prod.acaisoft.dev'
      : 'https://openresty-gateway.payr.acaisoft.dev',
}
const config = {
  ...variables,
  ...(window.CONFIG || {}),
}
const scheme = config.TARGET_DOMAIN === 'local.hudya.io' ? 'http' : 'https'

export default {
  BASE_URI: `${scheme}://${config.TARGET_DOMAIN}`,
  CONTENTFUL_DELIVERY_KEY: config.CONTENTFUL_DELIVERY_KEY,
  CONTENTFUL_PREVIEW_KEY: config.CONTENTFUL_PREVIEW_KEY,
  CONTENTFUL_SPACE_ID: config.CONTENTFUL_SPACE_ID,
  HOTJARID: 1854012,
  HOTJARSNIPPET: 6,

  WAIKIKI: `wss://ws.${config.TARGET_DOMAIN}`,
  coreApi: {
    baseURI: config.BASE_URI,
    legalEntityURI: config.LEGEAL_ENTITY_URI,
    loginURI: config.LOGIN_URI,
  },
  invoice: `${scheme}://billing.${config.TARGET_DOMAIN}/billing/invoice/`,
  RUNTIME_ENV: config.RUNTIME_ENV,

  webapi: {
    account: '',
    auth: { baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/auth` },
    esn: {
      baseURI: `${config.ACAISOFT_URL}/esn/api`,
    },
    eRate: {
      baseURI: `${config.ACAISOFT_URL}/erate/api`,
    },
    frende: {
      baseURI: `${config.ACAISOFT_URL}/insurance/api/v1`,
    },

    v2: {
      // cms: `${scheme}://api.${config.TARGET_DOMAIN}/v2.0/cms`,
      cms: `${scheme}://api.hudya.io/v2.0/cms`,
      legalEntities: `${scheme}://api.${config.TARGET_DOMAIN}/v2.0/legalentities`,
      subscriptions: `${scheme}://api.${config.TARGET_DOMAIN}/v2.0/subscriptions`,
      source: `${scheme}://api.${config.TARGET_DOMAIN}/v2.0/bi/registersource/`,
    },
    bobBrain: {
      baseURI: `${scheme}://labs.${config.TARGET_DOMAIN}/brain/channel/legion/`,
    },
    // bobBrain: {
    //   baseURI: `${scheme}://tommy.eu.ngrok.io/brain/channel/legion/`
    // },
    core: { baseURI: `${scheme}://billing.${config.TARGET_DOMAIN}/v1.0` },
    directory: {
      baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/directory`,
    },
    featuretoggles: {
      baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/featuretoggles`,
    },
    mobile: { baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/mobile` },
    power: { baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/power` },
    signal: { baseURI: `${scheme}://api.${config.TARGET_DOMAIN}/v1/signals` },
    transifex: {
      accessToken: config.TRANSIFEX_API_KEY,
      baseURI: 'https://www.transifex.com/api/2',
      mode: 'sourceastranslation', // https://docs.transifex.com/formats/introduction#section-modes
      projectSlug: 'stuart',
      resourceSlug: 'messagespo',
    },
  },
  WP_SITE_URL:
    config.RUNTIME_ENV === 'prod'
      ? {
          nb_NO: 'https://wp.hudya.no/nb',
          sv_SE: 'https://wp.hudya.se/sv',
          da_DK: 'https://hudya.dk',
        }
      : {
          nb_NO: 'https://hudya.staging.wpengine.com',
          sv_SE: 'https://hudya-se.staging.wpengine.com',
          da_DK: 'https://hudya-dk.staging.wpengine.com',
        },
  INTERCOM_ID:
    (window.CONFIG && window.CONFIG.INTERCOM_ID) || config.INTERCOM_ID,

  FB_PIXEL_ID: config.RUNTIME_ENV === 'prod' ? '2005014659633956' : '',
}
