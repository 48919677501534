import React from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, Container, Grid, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import SvgDivider from '../../../components/SvgDivider'
import styles from './article.module.css'
import { legalEntityAPI } from '../../../store/webapi/legalEntity'
import { setCredentials } from '../../../store/global/credentials'
import { accountAPI } from '../../../store/webapi/account'
import renderHTML from 'react-render-html'
import Chat from '../../../components/Chat'
import ReactMarkdown from 'react-markdown/with-html'

class Article extends React.PureComponent {
  static contextTypes = {
    xgettext: PropTypes.func.isRequired,
    gettext: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    })
  }
  constructor(props, context) {
    super(props, context)
    // this.logUserIn = this.logUserIn.bind(this)
  }
  //   logUserIn(jwt) {
  //     if (!jwt) {
  //       return
  //     }
  //     this.props.actions.setCredentials(jwt)

  //     const decodedJWT = jwtDecode(jwt)

  //     this.props.actions.dispatch(
  //       //accountAPI.actions.account.sync({ id: decodedJWT.sub })
  //       legalEntityAPI.actions.entity.sync({ id: decodedJWT.sub })
  //     )
  //   }
  render() {
    const { gettext, xgettext } = this.context
    const jwt = localStorage.getItem('brainSessionJwt')
    const flow = 'mobile_onboarding'

    const { defaultLocale, activeProducts, urlLocaleMap } = this.props.config

    if (jwt) {
      this.logUserIn(jwt)
    }
    if (this.props.credentials.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { from: '/' }
          }}
        />
      )
    }
    return (
      <div className={styles.overview}>
        <div className={styles.productSection}>
          <Container>
            <Card fluid className={styles.article}>
              <div className="content">
                <div className="ten wide tablet seven wide computer column">
                  <h1>
                    Hudya lanserer Hudya Barn 1GB til kun kr 99,- per mnd. i
                    Telenor-nettet
                  </h1>
                  <p>
                    Hudya får mange henvendelser fra foreldre om barnas
                    mobilbruk, og foreldrene ønsker først og fremst et trygt
                    abonnement for barna sine, og visshet om at kostnadene ikke
                    løper løpsk. Hudya har løst dette med abonnementet Hudya
                    Barn 1GB til kun kr 99, per mnd. i Telenor-nettet.
                  </p>
                  <h2>Foreldre har høye nok utgifter</h2>
                  <p>
                    De fleste vet at foreldres faste utgifter er høye nok som de
                    er. Derfor lanserte Hudya nylig et nytt abonnement for barn
                    som ivaretar både barnets behov som bruker og foreldrenes
                    behov som pårørende, juridisk eier og betaler. Hudyas
                    Product Director Telecom Pål Eivind Vegard påpeker at det er
                    viktig for Hudya å ivareta både barnas sikkerhet og
                    foreldrenes trygghet.
                  </p>{' '}
                  <h2>Vi har lyttet til foreldrene</h2>
                  <p>
                    Vi i Hudya har lyttet til kundene våre når vi har utviklet
                    vårt nye barneabonnement Hudya Barn 1 GB som passer for barn
                    under 13 år. Før vi landet på det konkrete innholdet i
                    abonnementet, fanget vi opp at foreldre flest er opptatt av
                    at mobilbruken til barn skal være både ansvarlig og
                    kontrollert, men uten store bruksbegrensninger. Flere
                    tilbydere har laget barneabonnement, men de fleste av disse
                    har enten store ulemper eller begrensninger for barna. Det
                    har vi ønsket å gjøre noe med, sier Vegard.
                  </p>
                </div>
              </div>
            </Card>
          </Container>
        </div>
        <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />

        <header className={styles.header}>
          <Container>
            <Grid stackable textAlign="left" style={{ paddingBottom: 100 }}>
              <Grid.Row>
                <Grid.Column width="11"></Grid.Column>
                {/* <Grid.Column
              width="6"
              style={{ alignContent: 'center' }}
              className="mobile hidden"
            >
              <div info style={{ marginTop: 60, marginLeft: 40 }}>
                <h2>{gettext('Over 15 000 mobil-kunder')}</h2>
                <p>
                  {gettext(
                    ' Hudya Mobil har full Telenor-dekning og jobber kontinuerlig med å konkurere blant Norges billigste mobilopperatører'
                  )}
                </p>
              </div>
            </Grid.Column> */}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="11" textAlign="left">
                  <h1>Bestill her</h1>
                  <Chat goalFlow={flow} step="init_with_fixed_start" />
                </Grid.Column>
                <Grid.Column width="5">
                  <h2>{gettext('Mobile sales heading')}</h2>
                  <div className={styles['mobile-disclaimer']}>
                    <ReactMarkdown
                      source={gettext('Mobile sales text')}
                      escapeHtml={false}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </header>
        <SvgDivider path="M0,22h1080V8.067c0,0-539.279-19.53-1080,2.186V22z" />
        <div className={styles.productSection}>
          <Container>
            <Card fluid className={styles.article}>
              <div className="content">
                <div className="ten wide tablet seven wide computer column">
                  <h2>Hudyas barneabonnement</h2>
                  <p>
                    Han forteller at Hudyas abonnement Barn 1 GB er
                    brukervennlig med fri bruk av minutter, SMS og MMS samt mer
                    enn nok data for de fleste barn til en utrolig billig penge.
                  </p>
                  <p>
                    Vi har sørget for barnas sikkerhet ved at de bare kan ringe
                    vanlige numre i Norge. I tillegg er det umulig å bruke mer
                    data enn det som er inkludert i pakken. På denne måten
                    sikrer vi at kostnadene aldri blir større enn
                    abonnementsprisen, avslutter Vegard.
                  </p>
                  <h2> Andre abonnement fra Hudya </h2>
                  <p>
                    Vi har mobilabonnement fra kr 0,- med full Telenor-dekning!
                    Vi er norges billigste på 6 GB (kr 269,-), 12 GB (kr 349,-)
                    og 20 GB (kr 399,-) samt at 1 GB, 3 GB og 6 GB-pakkene våre
                    er kåret til beste mobilabonnement for henholdsvis lite,
                    medium og mye data av Bytt.no i oktober 2019, forteller
                    Johansen.
                  </p>
                  <h2>Utviklingen av nytt barne abonnement</h2>
                  <p>
                    Kundeansvarlig i hudya mobil Thomas Daykin Johansen har vært
                    med på å utvikle det nye barneabonnementet, og han har
                    støttet seg til tilbakemeldinger og ofte stilte spørsmål som
                    foreldre har stilt til selskapets kundeservice. – Hudya Barn
                    1 GB er sperret for alle dyre innholdstjenester og
                    teletorgtjenester, og det er ikke mulig å ringe ut til
                    internasjonale numre. Datakontroll er automatisk aktivert,
                    og hastigheten strupes når 1GB er nådd. Foreldre unngår
                    dermed dyre overraskelser på mobilregningen. Vi har også
                    gjort det enkelt for foreldre å følge med via
                    MinSide-portalen og ordnet en egen datavarsling på SMS for
                    foreldre, sier Johansen.
                  </p>
                  <h2> Hvorfor trengs et eget barneabonnement?</h2>
                  <p>
                    De to siste årene har bruken av mobildata økt voldsomt blant
                    alle kategorier mobilbrukere i Norge, og dermed har også
                    barns bruk av data økt i samme raske takt.
                  </p>
                  <p>
                    Stadig flere barn får nemlig sin første mobil i tidlig
                    alder, gjerne forbundet med skolestart, så de har mulighet
                    til å kontakte foreldrene sine når skoledagen er ferdig.
                    Stadig flere familier lever i konstellasjoner som gjør at
                    barna i større grad enn før trenger tilgang på egen mobil
                    for å kunne kontakte foreldre og pårørende, og mange barn
                    pendler mellom to hjem og trenger et fast kontaktpunkt til
                    foreldrene sine.
                  </p>
                  <p>
                    Det er derfor ikke overraskende at foreldre ønsker et
                    abonnement som gir barna det de trenger for å være
                    tilgjengelige og ringe, tekste og surfe. Samtidig skal
                    foreldrene betale for barnas abonnement, og helst til et
                    lavt og forutsigbart kostnadsnivå uten overraskelser.
                  </p>
                </div>
              </div>
            </Card>
          </Container>
        </div>
        <div className={styles.information}>
          <Container>
            <Card fluid className="help-card big-help">
              <div className="content">
                <Grid stackable doubling>
                  <div className="ten wide tablet seven wide computer column">
                    <h4 className="help-title">
                      {gettext('Do you')}{' '}
                      <strong>{gettext('need help?')}</strong>
                    </h4>
                    <p>
                      {gettext(
                        'Vår kundesupport er åpen hverdager fra 9-16 og står klare til å bistå deg! ring oss på'
                      )}
                      <a
                        type="tel"
                        href="222222222"
                        style={{
                          color: '#3f2a56',
                          fontSize: 22,
                          marginLeft: 5,
                          fontWeight: 'bold'
                        }}
                      >
                        21 41 56 00
                      </a>
                    </p>
                  </div>
                  <div className="six wide tablet eight wide computer column right aligned">
                    <img
                      src={
                        process.env.PUBLIC_URL + '/media/section-help-table.png'
                      }
                    />
                  </div>
                </Grid>
              </div>
            </Card>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    login: state.core.auth.login,
    credentials: state.global.credentials,
    chat: state.global.bob.chatElements,
    config: state.global.config
  }),
  dispatch => ({
    actions: Object.assign(
      {},
      { dispatch },
      bindActionCreators({ setCredentials }, dispatch)
    )
  })
)(Article)
