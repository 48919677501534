import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import PageLoader from '../../../components/PageLoader/pageLoader';
import ChatContainer from '../../../components/ChatContainer';
import FaqHelper from '../../../components/FaqHelper/faqHelper';
import productstyles from '../Home/home.module.scss';
import ProductCard from '../../../components/ProductCard/productCard';
import HtmlParse from '../../../components/HtmlParser/htmlParser';
import useGetMobileQuery from '../../../hooks/gql/landings/mobile/GetMobile.query';
import RatingStars from '../../../components/RatingStars';
import Page from '../../../components/Page/Page';

import styles from './styles.module.scss';

const Mobile = () => {
  const { data, loading } = useGetMobileQuery();
  const params = new URLSearchParams(window.location.search);
  const flow = params.get('flow') || 'mobile_onboarding';
  const step = 'init_with_fixed_start';
  const history = useHistory();

  return loading ? (
    <div className={styles.loadingContainer}>
      <PageLoader fill loading />
    </div>
  ) : (
    <Page title={data.title || null} loading={loading}>
      <div className={styles.contentContainer}>
        <Container>
          <Grid textAlign="left">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={11} computer={11}>
                <ChatContainer
                  flow={flow}
                  step={step}
                  chatH1={data.title}
                  chatH2={data.whyWeH2}
                  firstMessage={data.chatMessage}
                  columns={16}
                  disc={null}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={5} computer={5}>
                <div className={styles.sideContainer}>
                  <RatingStars count={5} rating={data.rating} />
                  <div className={styles.rating}>
                    <b>{data.rating}/5.0</b>
                  </div>
                  <div className={styles.ratingLogo}>
                    <img
                      src="/assets/landings/mobile/mobile_logo.png"
                      alt="logo"
                    />
                  </div>
                  <div className={styles.testimonials}>
                    {HtmlParse({
                      data: data.testimonials || '',
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container className={styles.whyWeContainer}>
          <div className={styles.whyWeTitle}>
            <h3>{data.whyWeTitle}</h3>
          </div>
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <div className={styles.whyWeList}>
                  {data.whyWeLeftList.map((t) => (
                    <div key={t.id} className={styles.item}>
                      <img
                        src="/assets/landings/refinancing/check.svg"
                        alt="check"
                      />
                      <span>{t.text}</span>
                    </div>
                  ))}
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <div className={styles.whyWeList}>
                  {data.whyWeRightList.map((t) => (
                    <div key={t.id} className={styles.item}>
                      <img
                        src="/assets/landings/refinancing/check.svg"
                        alt="check"
                      />
                      <span>{t.text}</span>
                    </div>
                  ))}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div className={styles.whyWe}>
                {HtmlParse({
                  data: data.whyWeContent || '',
                })}
              </div>
            </Grid.Row>
          </Grid>
        </Container>

        <div>
          <FaqHelper type="mobil" />
        </div>
        <div className={productstyles.productSection}>
          <Container>
            <div className="ui stackable doubling four column grid center aligned">
              <Grid.Column>
                <ProductCard
                  title="Forsikring"
                  text="De som velger å bytte til oss sparer i snitt over 10% på sin forsikringspremie"
                  buttonText="Bestill nå"
                  buttonUrl="/forsikring"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/forsikring');
                  }}
                  image="/assets/home/insurance.svg"
                />
              </Grid.Column>
              <Grid.Column>
                <ProductCard
                  title="Refinansiering"
                  text="Logg inn med BankID på mobil og se hva du kan spare på lån og kredittkort."
                  buttonText="Bestill nå"
                  buttonUrl="/refinansiering"
                  moreText="Les mer"
                  moreAction={() => {
                    history.push('/refinansiering');
                  }}
                  image="/assets/home/bank.svg"
                />
              </Grid.Column>
            </div>
          </Container>
        </div>
      </div>
    </Page>
  );
};

export default Mobile;
