/**
 * WebAPI reducer for accounts.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'
import { responseDataTransformer } from '../transformers'
import { featuretogglesAPI } from '../featuretoggles'
import { mobileAPI } from '../mobile'

import { subscriptionsAPI } from '../subscriptions'
import { invoiceAPI } from '../invoice'
import { legalEntityAPI } from '../legalEntity'

const webapi = reduxApi({
  account: {
    helpers: {
      get(id) {
        return [{ id }, {}]
      },
      patch(id, payload) {
        return [{ id }, { body: JSON.stringify(payload), method: 'PATCH' }]
      }
    },
    postfetch: [
      function({ dispatch, actions, getState }) {
        const accountId = getState().global.credentials.id

        dispatch(featuretogglesAPI.actions.featuretoggles.get())
        dispatch(mobileAPI.actions.contracts.get(accountId))
        dispatch(mobileAPI.actions.invoices.get(accountId))
        dispatch(mobileAPI.actions.portationStatus.list())
        // dispatch(powerAPI.actions.meteringPoints.get())
        dispatch(subscriptionsAPI.actions.subscriptionsList.get())
        dispatch(invoiceAPI.actions.invoice.get())

        dispatch(legalEntityAPI.actions.entity.get(accountId))
        // dispatch(
        //   BobApi.actions.initBob.post('', {
        //     vertex: {},

        //     content_type: 'init'
        //   })
        // )
      }
    ],
    transformer: responseDataTransformer,
    url: `${config.webapi.account.baseURI}/account/:id/`
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as accountAPI }
export default combineReducers(webapi.reducers, {})
