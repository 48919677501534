/**
 * WebAPI reducer for billing/invoice.
 */

import 'isomorphic-fetch' // eslint-disable-line import/no-unassigned-import
import reduxApi from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import { combineReducers } from 'redux'

import config from '../../../config'
import { sourceApi } from '../registersource'
function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
const webapi = reduxApi({
  talkToBob: {
    helpers: {
      post(id, payload) {
        payload.vertex['cidb'] = getCookie('_ga')
        return [{}, { body: JSON.stringify(payload), method: 'POST' }]
      }
    },

    //  transformer: discardPaginationTransformer,
    url: `${config.webapi.bobBrain.baseURI}`
  },
  initBob: {
    helpers: {
      post(id, payload) {
        payload.vertex['cidb'] = getCookie('_ga')
        return [{}, { body: JSON.stringify(payload), method: 'POST' }]
      }
    },

    //  transformer: discardPaginationTransformer,
    url: `${config.webapi.bobBrain.baseURI}`,
    postfetch: [
      function({ dispatch, actions, getState }) {
        //log source:
        const regex = /([^\?]+)(\?.*)?/

        let sourceName = ''
        const params = new URLSearchParams(window.location.search)
        if (params.get('sourceid')) {
          sourceName = params.get('sourceid')
        } else if (params.get('utm_source')) {
          sourceName = params.get('utm_source')
        } else if (document.referrer !== '') {
          // sourceName = 'linked'
          sourceName = document.referrer.match(regex)[1]
          //  console.log(document.referrer.match(regex))
          // if (!document.referrer.match(regex)[0].includes('my.hudya')) {
          //   sourceName = 'linked'
          // }
        } else {
          sourceName = 'direct'
        }

        let customData = { params: {}, httpReferrer: '', gaCookie: '' }
        for (var pair of params.entries()) {
          customData.params[pair[0]] = pair[1]
        }
        customData.httpReferrer = document.referrer
        customData.gaCookie = getCookie('_ga') || ''
        dispatch(
          sourceApi.actions.register.post('', {
            entity_id: getState().global.bob.uid,
            slug: sourceName,
            custom_data: customData
          })
        )
      }
    ]
  }
})
  .use('options', (url, params, getState) => {
    const { tokenType, accessToken } = getState().global.credentials
    const jwtFromBrain = localStorage.getItem('brainSessionJwt')

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Encoding': 'br',
      'Accept-Encoding': 'gzip'
    }

    if (jwtFromBrain) {
      return {
        headers: {
          ...headers,

          Authorization: `${tokenType || 'Bearer'} ${jwtFromBrain}`
        }
      }
    }
    if (accessToken) {
      return {
        headers: {
          ...headers,

          Authorization: `${tokenType || 'Bearer'} ${accessToken}`
        }
      }
    }

    return headers
  })
  .use('fetch', adapterFetch(fetch))

export { webapi as BobApi }
export default combineReducers(webapi.reducers, {})
