import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

const PowerChart = ({ data, isMain }) => {
  const prepareData = () => {
    let d = [];
    if (data && data.length) {
      d = data.map((e) => ({
        index: moment(new Date(`${e.month}/01/${e.year}`)),
        month: moment(new Date(`${e.month}/01/${e.year}`)).format('MMM'),
        usage: e.usage,
      }));
      d = _.orderBy(d, ['index'], ['asc']);
      return d;
    }
    for (let i = 0; i < 6; i++) {
      d.push({
        month: `${moment()
          .subtract(6 - i, 'months')
          .format('MMM')}`,
        usage: 0,
      });
    }
    return d;
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <LineChart
        data={prepareData()}
        margin={{
          top: 10,
          right: 10,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={false}
          vertical={false}
        />
        <XAxis
          dataKey="month"
          tick={{ fontSize: 10, fill: isMain ? '#e4e4e4' : '#FF6900' }}
          interval={0}
          tickLine={false}
          axisLine={{ stroke: isMain ? '#e4e4e4' : '#FF6900' }}
        />
        <YAxis
          axisLine={false}
          width={40}
          tickLine={{ stroke: isMain ? '#e4e4e4' : '#FF6900' }}
          tick={{ fontSize: 10, fill: isMain ? '#e4e4e4' : '#FF6900' }}
          label={{
            value: 'kWh',
            angle: -90,
            position: 'insideBottom',
            fill: isMain ? '#fff' : '#FF6900',
            fontSize: '10',
          }}
        />
        <Tooltip
          formatter={(value) => `${value} kWh`}
          itemStyle={{ fill: '#000', color: '#000' }}
        />
        <Line
          type="monotone"
          dataKey="usage"
          stroke={isMain ? '#f4f4f4' : '#FF6900'}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

PowerChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMain: PropTypes.bool,
};

PowerChart.defaultProps = {
  isMain: false,
};

export default PowerChart;
